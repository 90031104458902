import {
  ChartRepo,
  IApiChart,
  IApiCustomValue,
  IChart,
  ICustomValue,
} from '../types';
import { IApiNamespace, INamespace } from '../../namespaces/types';
import { IMoney } from './payTypes';
import { PaymentMethod } from '../../billing/const';
import BigNumber from 'bignumber.js';
import { PaymentWay } from '../../billing/apiMapping/appPaymentData';
import { RequestStatus } from '../../../utils/requestStatus';
import { ICreateAppPayload } from '../actions/AppsActions';
import { FormApi } from 'final-form';

export interface IClusterPrice {
  id: string;
  hourlyPrice: string;
  monthlyPrice: string;
  dailyPrice: string;
}

export enum AppStatus {
  APP_ACTION_REQUIRED = 'ACTION_REQUIRED',
  APP_PAUSED = 'PAUSED',

  APP_RUNNING = 'RUNNING',
  APP_DEPLOYING = 'DEPLOYING',
  APP_DELETED = 'DELETED',
  APP_PENDING = 'PENDING',
  APP_FAILED = 'FAILED',
  App_Stopping = 'STOPPING',
  App_Stopped = 'STOPPED',
  App_Resetting = 'RESETTING',
  App_Resetted = 'RESETTED',
  APP_UNKNOWN = 'UNKNOWN',
  APP_ACTIVE = 'ACTIVE',
  // eslint-disable-next-line @typescript-eslint/camelcase
  App_Transaction_In_Progress = 'App_Transaction_In_Progress',
  // eslint-disable-next-line @typescript-eslint/camelcase
  App_Insufficient_Funds = 'App_Insufficient_Funds',
}

/**
 * API response types
 */

export interface IApiFetchAppsResponse {
  apps: IApiApp[];
  total_count: number;
}

export interface IApiLaunchAppResponse {
  app_id: string;
  status: string;
}

export interface IApiResetAppResponse {
  app_id: string;
  status: string;
}

export interface IApiCreateAppResponse {
  id: string;
}

/**
 * Core app types
 */

export interface IApp {
  id: string;
  name: string;
  creator: string;
  teamId: string;
  namespace: INamespace;
  chart: IChart;
  status: AppStatus;
  statusName: string;
  resource: string;
  realtimeOverview: IRealtimeOverview;
  note: string;
  endpoint: string;
  nodePorts?: string;
  appHooks?: IAppHook[];
  createdTime: Date;
  updatedTime: Date;
  hourlyRate?: BigNumber;
  payWay: PaymentWay;
  customValue?: IApiCustomValue;
  replicas: number;
}

export interface IApiApp {
  id: string;
  name: string;
  creator: string;
  team_id: string;
  namespace: IApiNamespace;
  chart: IApiChart;
  status: AppStatus | string;
  resource: string;
  realtimeOverview: IRealtimeOverview;
  note: string;
  endpoint: string;
  node_ports?: string;
  app_hooks?: IApiAppHook[];
  created_time: string;
  updated_time: string;
  hourly_rate?: string;
  payWay: PaymentWay;
  replicas_number: number;
  custom_value?: IApiCustomValue;
}

export interface IApiAppHook<T = object> {
  label: string;
  container: string;
  action: string;
  description: string;
  command: string;
  args?: T;
}

export type IAppHook<T = object> = IApiAppHook<T>;

export interface IAppHookError {
  code: number;
  message: string;
  data: string;
}

export interface IApiCheckPrice {
  team_id: string;
  cluster_id: string;
  cpu: number;
  memory: number;
  storage: number;
  chart_name: string;
}

export interface IApiAppsOverview {
  cluster_count: number;
  namespace_count: number;
  network_count: number;
  app_count: number;
  cpu_total: number;
  cpu_usage: number;
  mem_total: number;
  mem_usage: number;
  storage_total: number;
  storage_usage: number;
}

export interface IAppsOverview {
  clusterCount: number;
  namespaceCount: number;
  networkCount: number;
  appCount: number;
  cpuTotal: number;
  cpuUsage: number;
  memoryTotal: number;
  memoryUsage: number;
  storageTotal: number;
  storageUsage: number;
}

export interface IApiRealtimeOverview {
  cpu: IApiCpu;
  mem: IApimem;
  storage: IApistorage;
}

export interface IApiCpu {
  cur: string;
  cap: string;
  unit: string;
}

export interface IApimem {
  cur: string;
  cap: string;
  unit: string;
}

export interface IApistorage {
  cur: string;
  cap: string;
  unit: string;
}

export interface IRealtimeOverview {
  cpuUsed: number;
  cpuLimit: number;
  cpuUnit: string;
  memUsed: number;
  memLimit: number;
  memUnit: string;
  storageUsed: number;
  storageLimit: number;
  storageUnit: string;
}

/***
 _Action invocation types and request types bellow_
 */

/***
 * Type for creating an app
 */
export interface IApiCreateApp {
  name: string; // the app name
  cluster_id: string; // the cluster id
  // namespace_id?: string; // the namespace id
  namespace_name: string; //the namespace name
  cpu_limit: number; // the cpu hard limit
  mem_limit: number; //the memory hard limit
  storage_limit: number; //the storage hard limit
  team_id: string; //the team id
  chart_name: string; // the chart name
  chart_repo: string; // the chart repository, for example: user/stable
  chart_ver: string; // the chart version
  chart_desc: string; //the chart description
  chart_icon_url: string; // the chart icon url
  app_version: string; // the app version
  custom_values?: IApiCustomValue[]; // the array of CustomValue
  instance_type: string; //  the type of the node on which the app is to be deployed
}

export interface IAppListParams {
  teamId: string;
}

export interface IApiPricePrameter {
  'resource.cpu': string;
  'resource.memory': string;
  'resource.storage': string;
  node_kind: string;
}

export interface ICreateAppParams {
  app: IApiCreateApp;
  redirectOnSuccess?: string;
}

export type IUpdateAppParams = {
  appId: string;
  chartVer: string;
  teamId: string;
  redirectOnSuccess?: string;
};

export interface IFetchAppParams {
  appId: string;
  teamId: string;
}

export interface ICancelAppParams {
  appId: string;
  teamId: string;
  redirectOnSuccess?: string;
}

export interface IChangeAppNameParams {
  appId: string;
  teamId: string;
  name: string;
}

export interface IMetricsRealtimeParams {
  appId: string;
  clusterId: string;
  teamId: string;
  namespaceId: string;
}

export interface IPurgeAppParams {
  appId: string;
  teamId: string;
  redirectOnSuccess?: string;
}

export interface IDeleteAppParams {
  appId: string;
  teamId: string;
  redirectOnSuccess?: string;
}

export interface IOverviewAppParams {
  teamId: string;
}

export enum CheckFailPhase {
  NONE = 'NONE',
  SETTING = 'SETTING',
  BALANCE = 'BALANCE',
}

export interface IPriceResult {
  ok: boolean;
  demand: IMoney;
  total: IMoney;
  balance: IMoney;
  payMethodName: PaymentMethod;
  failPhase: CheckFailPhase;
}

export interface IAppEnteriseHook {
  [index: string]: string;
}
export interface IAPIAlertResponse {
  data: IAlert[];
}

export interface IAlert {
  level: string;
  threshold: number;
  text: string;
  create_time: string;
}

export interface IAPIUptimeResponse {
  start_time: string;
  success_rate: number;
  uptime_blocks: IRPCUptime[];
}
export interface IUptimeResponse {
  startTime: string;
  successRate: number;
  uptimeBlocks: IRPCUptime[];
}
export interface IRPCUptime {
  create_time: string;
  result: boolean;
}

/**
 * App hooks types
 */

export interface IHookConfigComponent<T = string> {
  label?: string;
  description?: React.ReactNode;
  hook: IAppHook;
  result: T;
  resultMeta?: any;
  active?: boolean;
  onChangeAction?: (action: string) => void;
  onAppHook: (hook: IAppHook) => void;
  initialOpen?: boolean;
  status: RequestStatus;
  appId: string;
  teamId?: string;
}

export interface ICustomInfoComponent {
  appId: string;
  hooks: IAppHook[];
  note?: string;
}

export interface ICustomConfigProps {
  appId: string;
  hooks: IAppHook[];
}

/**
 Custom values types
 */

export interface ICustomNodeProps {
  customValues: ICustomValue[];
  fetchChartDetailsStatus: RequestStatus;
}

export interface ILaunchNodeProps {
  appDetails: IApp;
}

export interface ICustomValueComponentProps {
  chartName?: string;
  customValues: ICustomValue[];
  values?: ICreateAppPayload;
  disableReplicas?: boolean;
  form?: FormApi;
}

export interface IChartLocationProps {
  name: string;
  version: string;
  repository: ChartRepo;
}

export interface IAppAddressInfoParams {
  appId: string;
  teamId: string;
}

export interface IAppAddressInfo {
  code: string;
  message: string;
  app_addr: string;
  already_deposit: string;
  renewal_info: {
    code: number;
    amount: string;
    renewal_already_deposit: string;
    pay_method: string;
  };
  meta: {
    appId: string;
  };
}

export interface IWalletInfo {
  accessToken: string;
  address: string;
  networkId: string;
  network: string;
  provider: string;
  sidecars: { id: string; name: string; status: string }[];
  insuranceBalanceETH: string;
  insuranceBalanceANKR: string;
}
