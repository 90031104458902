import React from 'react';
import { createReducer } from 'common/utils/reduxUtils';
import { SnackbarProps } from '@material-ui/core/Snackbar';
import { SnackbarsActionsTypes } from 'common/store/actions/SnackbarsActions';

export type SnackbarVariantType =
  | 'default'
  | 'error'
  | 'success'
  | 'warning'
  | 'info';

export type SnackbarMessage = SnackbarProps['message'];
export type SnackbarAnchorOrigin = SnackbarProps['anchorOrigin'];
export type SnackbarKey = string;
export type SnackbarAction = 'close' | 'ok' | 'cancel';

export interface ISnackbarOptions {
  key?: SnackbarKey;
  intlMessage?: string; // Will skip message content if exists
  variant?: SnackbarVariantType;
  actions?: SnackbarAction[];
  persist?: boolean;
  onActionClick?: (key: SnackbarKey, action: SnackbarAction) => void;
  onClose?: (key: SnackbarKey) => void;
  preventDuplicate?: boolean;
  autoHideDuration?: number;
  anchorOrigin?: SnackbarAnchorOrigin;
}

export interface ISnackbar extends ISnackbarOptions {
  message: React.ReactNode;
  key: SnackbarKey;
  anchorOrigin?: SnackbarAnchorOrigin;
}

export interface ISnackbarsState {
  queue: ISnackbar[];
}

const initialState: ISnackbarsState = {
  queue: [],
};

const snackbarsReducer = createReducer(initialState, {
  [SnackbarsActionsTypes.SHOW_SNACKBAR_INT]: (
    state: ISnackbarsState,
    action: {
      payload: ISnackbar;
    },
  ): ISnackbarsState => {
    const queue = state.queue.filter(item => item.key !== action.payload.key);
    return {
      ...state,
      queue: [...queue, action.payload],
    };
  },
  [SnackbarsActionsTypes.REMOVE_SNACKBAR_INT]: (
    state: ISnackbarsState,
    action: { payload: SnackbarKey },
  ): ISnackbarsState => ({
    ...state,
    queue: state.queue.filter(snackbar => snackbar.key !== action.payload),
  }),
  [SnackbarsActionsTypes.CLEAR_SNACKBAR_INT]: (
    state: ISnackbarsState,
  ): ISnackbarsState => {
    return {
      ...state,
      queue: [],
    };
  },
});

export { snackbarsReducer };
