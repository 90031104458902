import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

import icon from './assets/icon.svg';
import google from './assets/google.svg';

export const useIntroductionStyles = makeStyles<Theme>(theme => ({
  component: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    padding: theme.spacing(5, 10),
    boxSizing: 'border-box',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(3.5, 4.5),
    },
  },

  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    textAlign: 'center',

    [theme.breakpoints.down('xs')]: {
      marginTop: 'auto',
    },

    '&::before': {
      position: 'relative',
      content: '""',

      display: 'block',
      width: 121,
      height: 132,
      marginBottom: theme.spacing(3),

      backgroundImage: `url("${icon}")`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
  },

  note: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    marginTop: theme.spacing(3),

    '&::before': {
      position: 'relative',
      content: '""',

      flexShrink: 0,

      display: 'block',
      width: 31,
      height: 31,
      marginRight: theme.spacing(1),

      backgroundImage: `url("${google}")`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
  },

  next: {
    minWidth: 202,
    marginTop: 'auto',

    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },

  skip: {
    marginTop: theme.spacing(3),
  },
}));
