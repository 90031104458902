import { AnyAction } from 'redux';
import { fork, put, takeEvery } from 'redux-saga/effects';
import { SnackbarsActions } from 'common/store/actions/SnackbarsActions';
import { OfflineActionTypes } from 'common/store/actions/OfflineActions';
import { offlineDetectSaga } from './offlineDetectSaga';

const OFFLINE_MESSAGE_KEY = '@OFFLINE_MESSAGE_KEY';

export function* setOfflineNotification(action: AnyAction) {
  if (action.payload) {
    yield put(
      SnackbarsActions.showSnackbar('You are offline.', {
        intlMessage: 'notification-offline',
        key: OFFLINE_MESSAGE_KEY,
        variant: 'error',
        persist: true,
      }),
    );
  } else {
    yield put(SnackbarsActions.removeSnackbar(OFFLINE_MESSAGE_KEY));
  }
}

export function* startOfflineNotifications() {
  yield fork(offlineDetectSaga);
  yield takeEvery(OfflineActionTypes.SET_OFFLINE, setOfflineNotification);
}
