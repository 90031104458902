import React from 'react';
import * as Sentry from '@sentry/browser';
import { AppError } from 'common-web/components/AppError';
import { isProd } from 'common/utils/environment';

interface IWithErrorHandlerState {
  hasError: boolean;
  error: Error | null;
  errorInfo: object | null;
}

function withErrorHandler(): <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => React.ComponentClass<P> {
  return <P extends object>(WrappedComponent: any) => {
    class WithErrorHandler extends React.Component<P, IWithErrorHandlerState> {
      public static displayName = `withErrorHandler(${WrappedComponent.displayName})`;

      public constructor(props: P) {
        super(props);

        this.state = {
          hasError: false,
          error: null,
          errorInfo: null,
        };
      }

      public componentDidCatch(error: Error, errorInfo: object) {
        this.setState({ hasError: true, error, errorInfo });
        if (isProd()) {
          Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
              // @ts-ignore
              scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
          });
        }
      }

      public render() {
        if (this.state.hasError) {
          return <AppError />;
        }

        return <WrappedComponent {...this.props} />;
      }
    }

    return WithErrorHandler;
  };
}

export { withErrorHandler };
