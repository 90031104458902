import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from 'common-web/nav/PrivateRoute';

import { LoadableNotFoundPaper } from './CommonLoadables';
import { t } from 'common/utils/intl';
import { API_MARKET_LIST_PATH } from '../modules/APIMarketUI/APIMarketConsts';
import { APIMarketRoutes } from '../modules/APIMarketUI/APIMarketRoutes';
import {
  ROOT_PATH,
  AUTH_PATH,
  CHANGE_EMAIL_PATH,
  RESET_PASSWORD_PATH,
  USER_CONFIRM_REGISTRATION_PATH,
  USER_REGISTRATION_SUCCESS_PATH,
} from 'auth-web/core/const';
import { ApiAuthRoutes } from './ApiAuthRoutes';
import { SETTINGS_PREFIX } from 'common/core/webPaths';
import { SettingsRoutes } from './ApiSettingsRoutes';

interface IMainNavigationProps {
  isSignedIn: boolean;
}

function ApiMainRoutes(props: IMainNavigationProps) {
  const { isSignedIn } = props;

  return (
    <>
      <Switch>
        <Route
          exact={true}
          path={ROOT_PATH}
          render={() => <ApiAuthRoutes isSignedIn={!isSignedIn} />}
        />

        <Route
          path={AUTH_PATH}
          render={() => <ApiAuthRoutes isSignedIn={isSignedIn} />}
        />

        <Route
          exact={true}
          path={RESET_PASSWORD_PATH}
          render={() => <ApiAuthRoutes isSignedIn={isSignedIn} />}
        />

        <Route
          exact={true}
          path={USER_CONFIRM_REGISTRATION_PATH}
          render={() => <ApiAuthRoutes isSignedIn={isSignedIn} />}
        />

        <Route
          exact={true}
          path={USER_REGISTRATION_SUCCESS_PATH}
          render={() => <ApiAuthRoutes isSignedIn={isSignedIn} />}
        />

        <Route
          path={API_MARKET_LIST_PATH}
          render={() => <APIMarketRoutes isSignedIn={isSignedIn} />}
        />

        <Route
          isSignedIn={isSignedIn}
          path={[SETTINGS_PREFIX, CHANGE_EMAIL_PATH]}
          render={() => <SettingsRoutes isSignedIn={isSignedIn} />}
        />

        <PrivateRoute
          isSignedIn={isSignedIn}
          component={LoadableNotFoundPaper}
          breadcrumbs={[t('navigation.404')]}
        />
      </Switch>
    </>
  );
}

export { ApiMainRoutes };
