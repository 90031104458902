import React, { useCallback } from 'react';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { IBreadcrumb } from 'common/types/commonTypes';
import { PageContext } from './PrivateRoute';
interface IPrivateRouteProps extends RouteProps {
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  render?: (props: RouteComponentProps<any>) => React.ReactNode;
  title?: string;
  breadcrumbs?: (IBreadcrumb | string)[];
}

const PublicRouteWithBreadcrumbs = (props: IPrivateRouteProps) => {
  const {
    component: Component,
    render,
    title = '',
    breadcrumbs = [],
    location,
    ...rest
  } = props;

  const [breadcrumbsState, setBreadcrumbs] = React.useState(breadcrumbs);

  const handleSetBreadcrumbs = useCallback(
    (newBreadcrumbs: (IBreadcrumb | string)[]) => {
      setBreadcrumbs(newBreadcrumbs);
    },
    [setBreadcrumbs],
  );

  const renderComponent = (routeProps: RouteComponentProps) => (
    <PageContext.Provider
      value={{
        title,
        breadcrumbsState,
        setBreadcrumbs: handleSetBreadcrumbs,
      }}
    >
      {Component && <Component {...routeProps} />}
      {render && render(routeProps)}
    </PageContext.Provider>
  );

  return <Route {...rest} render={renderComponent} />;
};

export { PublicRouteWithBreadcrumbs };
