import React from 'react';
import {
  Box,
  Button,
  StyledComponentProps,
  Typography,
  withStyles,
} from '@material-ui/core';
import { t } from 'common/utils/intl';
import { UnauthenticatedButtonStyles } from './UnauthenticatedButtonStyles';
import { useHistory } from 'react-router';
import { ROOT_PATH, LOGIN_PATH } from 'auth-web/core/const';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isUserAuthenticated } from 'auth/store/reducers/tokensReducer';
import { IApiStoreState } from 'store/reducers';

const KEY_RELOADED = 'reloaded';

function reloadApp() {
  localStorage.removeItem('persist:tokens');
  localStorage.removeItem('persist:team');
  localStorage.removeItem('persist:user');

  const reloaded = localStorage.getItem(KEY_RELOADED) === 'true';
  if (!reloaded) {
    window.location.reload();
    localStorage.setItem(KEY_RELOADED, 'true');
  }
}

interface IUnauthenticatedButton extends StyledComponentProps {}

export const UnauthenticatedButton = withStyles(UnauthenticatedButtonStyles)(
  ({ classes = {} }: IUnauthenticatedButton) => {
    const {
      push,
      location: { pathname },
    } = useHistory();

    const isAuthenticated = useSelector((state: IApiStoreState) =>
      isUserAuthenticated(state.tokens),
    );

    const isLogin = useMemo(() => pathname === LOGIN_PATH, [pathname]);

    if (![ROOT_PATH, LOGIN_PATH].includes(pathname) || isAuthenticated)
      return null;

    return (
      <Box mr="20px">
        <Button
          variant="text"
          color="inherit"
          className={classes.button}
          onClick={() => {
            if (isLogin) {
              push(ROOT_PATH);
              reloadApp();
            } else {
              push(LOGIN_PATH);
            }
          }}
        >
          <Typography variant="body1" className={classes.text}>
            {t(isLogin ? 'layout.signUp' : 'layout.login')}
          </Typography>
        </Button>
      </Box>
    );
  },
);
