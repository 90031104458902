import { put, delay } from 'redux-saga/effects';
import { UtilityActions } from '../actions/UtilityActions';

const REDIRECT_DELAY = 0; // ms. Just minimum timeout for redirect

function* delayedRedirect(to: string, redirectDelay: number = REDIRECT_DELAY) {
  yield delay(redirectDelay);
  yield put(UtilityActions.redirect(to));
}

export { REDIRECT_DELAY, delayedRedirect };
