import React, { useCallback, useState } from 'react';
import { Button, IconButton } from '@material-ui/core';
import { useEnableTwoFactorAuthStyles } from './EnableTwoFactorAuthStyles';
import { useIsXSDown } from 'common-web/utils/hooks/themeHooks';
import { t } from 'common/utils/intl';
import { ProgressBar } from './ProgressBar';
import { Introduction } from './Introduction';
import { Success } from './Success';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { defineFlow } from '../../components/Flow/definition';
import { Flow } from '../../components/Flow';
import { XSStep2 } from './XSStep2';
import { ChevronLeft } from '@material-ui/icons';
import { uid } from 'react-uid';
import { TwoFactorActions } from 'auth/store/actions/TwoFactorActions';
import { connect } from 'react-redux';
import { CustomDialog } from 'common-web/components/CustomDialog';
import { DEFAULT_HEIGHT } from '../../core/const';
import { useInitEffect } from 'common/utils/hooks';

interface ITwoFactorAuthProps {
  open: boolean;
  onClose: () => void;
  activateTwoFactorAuthReset: () => void;
  reconfigure?: boolean;
}

const STEPS = defineFlow(Introduction, Step1, Step2, Step3, Step4, Success);
const MOBILE_STEPS = defineFlow(
  Introduction,
  Step1,
  XSStep2,
  Step3,
  Step4,
  Success,
);

const RECONFIGURE_STEPS = defineFlow(Step1, Step2, Step3, Step4, Success);
const RECONFIGURE_MOBILE_STEPS = defineFlow(
  Step1,
  XSStep2,
  Step3,
  Step4,
  Success,
);

export const EnableTwoFactorAuthComponent = ({
  onClose,
  open,
  reconfigure,
  activateTwoFactorAuthReset,
}: ITwoFactorAuthProps) => {
  const classes = useEnableTwoFactorAuthStyles();

  const isXSDown = useIsXSDown();

  const [steps] = useState(
    reconfigure
      ? isXSDown
        ? RECONFIGURE_MOBILE_STEPS
        : RECONFIGURE_STEPS
      : isXSDown
      ? MOBILE_STEPS
      : STEPS,
  );

  useInitEffect(() => {
    activateTwoFactorAuthReset();
    return () => {
      activateTwoFactorAuthReset();
    };
  });
  const handleComplete = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <CustomDialog open={open} onClose={onClose} minHeight={DEFAULT_HEIGHT}>
      <Flow
        key={uid(steps)}
        steps={steps}
        onClose={onClose}
        onComplete={handleComplete}
        className={classes.step}
        innerClassName={classes.innerStep}
      >
        {(
          body,
          {
            currentStep,
            currentStepRef,
            isLastStep,
            steps,
            moveBack,
            moveForward,
          },
        ) => {
          const visibleNavigation = !(
            (currentStep === 0 && !reconfigure) ||
            isLastStep
          );

          const visiblePrevButton =
            currentStep > 1 && currentStep <= steps.length - 2;

          return (
            <>
              {visibleNavigation && (
                <div className={classes.topPanel}>
                  {isXSDown ? (
                    <ProgressBar
                      className={classes.bar}
                      current={currentStep}
                      count={steps.length - 2}
                    />
                  ) : (
                    visiblePrevButton && (
                      <IconButton
                        className={classes.iconPrev}
                        onClick={moveBack}
                      >
                        <ChevronLeft />
                      </IconButton>
                    )
                  )}
                </div>
              )}
              {body}
              {visibleNavigation && (
                <div className={classes.bottomPanel}>
                  {!isXSDown && (
                    <ProgressBar
                      className={classes.bar}
                      current={currentStep + (reconfigure ? 1 : 0)}
                      count={steps.length - (reconfigure ? 1 : 2)}
                    />
                  )}
                  <Button className={classes.next} onClick={moveForward}>
                    {t('two-factor-auth.nav.next-step')}
                  </Button>
                  {isXSDown && visiblePrevButton && (
                    <Button
                      className={classes.prev}
                      onClick={moveBack}
                      variant="text"
                      color="secondary"
                    >
                      {t('two-factor-auth.nav.prev-step')}
                    </Button>
                  )}
                </div>
              )}
            </>
          );
        }}
      </Flow>
    </CustomDialog>
  );
};

export const EnableTwoFactorAuth = connect(null, {
  updateTwoFactorStatus: TwoFactorActions.getStatus,
  activateTwoFactorAuthReset: TwoFactorActions.activateTwoFactorAuthReset,
})(EnableTwoFactorAuthComponent);
