import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Classes } from 'jss';
import { fade, useTheme, withStyles } from '@material-ui/core';
import { avatarImageStyles } from './AvatarImageStyles';
import { stringToColor } from 'common/utils/stringToColor';
import { IUserAvatar } from '../../../store/mappings/userMappings';

export type AvatarSize =
  | 'xxsmall'
  | 'tiny'
  | 'xsmall'
  | 'small'
  | 'big'
  | 'medium';

export interface IAvatarImageProps {
  className?: string;
  classes: Classes;
  size: AvatarSize;
  avatar?: IUserAvatar;
  username: string;
  children?: ReactNode;
  name?: string;
  disabled?: boolean;
}

const AvatarImageComponent = ({
  className,
  classes,
  size,
  username,
  children,
  name,
  disabled,
}: IAvatarImageProps) => {
  const theme = useTheme();

  const text = name ? name : username;
  const hasText = !!name || !!username;

  const color = disabled ? theme.palette.grey['100'] : stringToColor(text);
  const style = {
    backgroundColor: color,
  };

  return (
    <div
      className={classNames(classes.root, classes[size], className)}
      style={style}
    >
      {children}

      {hasText && text && (
        <div
          className={classes.initials}
          style={{
            color: disabled
              ? fade(theme.palette.grey['700'], 0.4)
              : theme.palette.common.white,
          }}
        >
          {text.charAt(0)}
        </div>
      )}
    </div>
  );
};

const AvatarImage = withStyles(avatarImageStyles)(AvatarImageComponent);

export { AvatarImage };
