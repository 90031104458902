/* eslint-disable @typescript-eslint/camelcase */
import {
  IApiCheckPrice,
  IExtendedMultiRequestsAction,
  IExtendedRequestAction,
  Month,
} from 'common/types';
import {
  EscrowType,
  IApiCheckSelectableParams,
  IApiWithdrawEscrowAvailableBalanceParam,
  ICard,
  IPaymentMethod,
  PayStatus,
} from '../../apps/types/payTypes';
import { createAction } from '../../../utils/reduxUtils';
import {
  DailyStep,
  DeployStep,
  PaymentConfigurationStep,
  PaymentMethod,
  PaymentStep,
} from '../const';
import generateRandomString from 'uuid/v1';
import * as stripeJs from '@stripe/stripe-js';
import { StripeCardNumberElement } from '@stripe/stripe-js';
import { ICreateAppPayload } from '../../apps/actions/AppsActions';

export interface IExtendAppPayload {
  period: Month;
  teamId: string;
  paymentMethod: PaymentMethod;
  appId: string;
  cardId?: string;
}

export interface IExtendAppByNewCardPayload {
  appId: string;
  period: Month;
}

export interface IBankCardAddress {
  name: string;
  email: string;
  line1?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
}

export interface IEnableNewCardPayload {
  enableCardOnCreate?: boolean;
  cardElement: StripeCardNumberElement;
  address: IBankCardAddress;
  stripe: stripeJs.Stripe | null;
}

export interface IDeleteBankCardPayload {
  cardId: string;
}

export interface ISelectCardPaymentMethod {
  card: Pick<ICard, 'id'>;
}

export const PayActionTypes = {
  FETCH_PAYMENT_METHOD: 'FETCH_PAYMENT_METHOD',
  FETCH_PAYMENT_METHOD_SUCCESS: 'FETCH_PAYMENT_METHOD_SUCCESS',

  FETCH_PAYMENT_METHODS: 'FETCH_PAYMENT_METHODS',
  FETCH_PAYMENT_METHODS_SUCCESS: 'FETCH_PAYMENT_METHODS_SUCCESS',

  SETUP_PAYMENT_METHOD: 'SETUP_PAYMENT_METHOD',
  SETUP_PAYMENT_METHOD_SUCCESS: 'SETUP_PAYMENT_METHOD_SUCCESS',
  SETUP_PAYMENT_METHOD_RESET: 'SETUP_PAYMENT_METHOD_RESET',

  CHECK_PAYMENT_METHOD: 'CHECK_PAYMENT_METHOD',
  CHECK_PAYMENT_METHOD_SUCCESS: 'CHECK_PAYMENT_METHOD_SUCCESS',
  CHECK_PAYMENT_METHOD_RESET: 'CHECK_PAYMENT_METHOD_RESET',

  CHECK_PAYMENT_METHOD_BY_STORE_PARAMS: 'CHECK_PAYMENT_METHOD_BY_STORE_PARAMS',

  CHECK_PAYMENT_METHODS_LIST: 'CHECK_PAYMENT_METHOD_LIST',
  CHECK_PAYMENT_METHODS_LIST_SUCCESS: 'CHECK_PAYMENT_METHOD_LIST_SUCCESS',

  SELECT_PAYMENT_METHOD: 'SELECT_PAYMENT_METHOD',
  SELECT_PAYMENT_METHOD_SUCCESS: 'SELECT_PAYMENT_METHOD_SUCCESS',
  SELECT_PAYMENT_METHOD_RESET: 'SELECT_PAYMENT_METHOD_RESET',

  FETCH_ESCROW: 'FETCH_ESCROW',

  FETCH_ESCROW_LIST: 'FETCH_ESCROW_LIST',

  FETCH_BALANCES_LIST: 'FETCH_BALANCES_LIST',

  WITHDRAW_AVAILABLE_BALANCE: 'WITHDRAW_AVAILABLE_BALANCE',
  WITHDRAW_AVAILABLE_BALANCE_RESET: 'WITHDRAW_AVAILABLE_BALANCE_RESET',

  FETCH_WITHDRAWAL: 'FETCH_WITHDRAWAL',

  FETCH_WITHDRAWAL_LIST: 'FETCH_WITHDRAWAL_LIST',

  CHECK_PRICE: 'CHECK_PRICE',
  CHECK_PRICE_MANUAL: 'CHECK_PRICE_MANUAL',

  SET_CANDIDATE_PAYMENT_METHOD: 'SET_CANDIDATE_PAYMENT_METHOD',

  SET_PAYMENT_CONFIGURATION_STEP: 'SET_PAYMENT_CONFIGURATION_STEP',

  SET_STEP: 'SET_STEP',
  SET_PAYMENT_DEPLOY_STEP: 'SET_PAYMENT_DEPLOY_STEP',

  GET_STRIPE_PUBLIC_KEY: 'GET_STRIPE_PUBLIC_KEY',

  FETCH_STRIPE_CLIENT_SECRET: 'FETCH_STRIPE_CLIENT_SECRET',

  CREATE_BANK_CARD: 'CREATE_BANK_CARD',

  ENABLE_BANK_CARD: 'ENABLE_BANK_CARD',

  CREATE_NEW_BANK_CARD: 'CREATE_NEW_BANK_CARD',
  CREATE_NEW_BANK_CARD_SUCCESS: 'CREATE_NEW_BANK_CARD_SUCCESS',
  CREATE_NEW_BANK_CARD_ERROR: 'CREATE_NEW_BANK_CARD_ERROR',
  CREATE_NEW_BANK_CARD_RESET: 'CREATE_NEW_BANK_CARD_RESET',

  SELECT_CARD_PAYMENT_METHOD: 'SELECT_CARD_PAYMENT_METHOD',
  SELECT_CARD_PAYMENT_METHOD_SUCCESS: 'SELECT_CARD_PAYMENT_METHOD_SUCCESS',
  SELECT_CARD_PAYMENT_METHOD_ERROR: 'SELECT_CARD_PAYMENT_METHOD_ERROR',

  FETCH_BANK_CARDS: 'FETCH_BANK_CARDS',

  DELETE_BANK_CARD: 'DELETE_BANK_CARD',
  DELETE_BANK_CARD_ERROR: 'DELETE_BANK_CARD_ERROR',
  DELETE_BANK_CARD_SUCCESS: 'DELETE_BANK_CARD_SUCCESS',
  DELETE_BANK_CARD_RESET: 'DELETE_BANK_CARD_RESET',

  GET_DAILY_AMOUNT: 'GET_DAILY_AMOUNT',

  GET_DEPOSIT_AMOUNT: 'GET_DEPOSIT_AMOUNT',

  GET_ETH_PAYMENT_STATUS: 'GET_ETH_PAYMENT_STATUS',
  GET_ETH_PAYMENT_STATUS_SUCCESS: 'GET_ETH_PAYMENT_STATUS_SUCCESS',
  GET_ETH_PAYMENT_STATUS_ERROR: 'GET_ETH_PAYMENT_STATUS_ERROR',

  GET_APP_PAYMENT_INFO: 'GET_APP_PAYMENT_INFO',

  GET_DEPOSIT_LIST: 'GET_DEPOSIT_LIST',

  EXTEND_APP: 'EXTEND_APP',
  EXTEND_APP_SUCCESS: 'EXTEND_APP_SUCCESS',
  EXTEND_APP_RESET: 'EXTEND_APP_RESET',
  EXTEND_APP_ERROR: 'EXTEND_APP_ERROR',

  EXTEND_APP_BY_NEW_CARD: 'EXTEND_APP_BY_NEW_CARD',

  GET_PRE_PAYMENT_LIST: 'GET_PRE_PAYMENT_LIST',

  CREATE_APP_BY_DAILY_CARD: 'CREATE_APP_BY_DAILY_CARD',

  CREATE_APP_BY_DAILY_ANKR_NATIVE: 'CREATE_APP_BY_DAILY_ANKR_NATIVE',

  CREATE_APP_BY_DEPOSIT_CARD: 'CREATE_APP_BY_DEPOSIT_CARD',

  CREATE_CARD_AND_DEPLOY: 'CREATE_CARD_AND_DEPLOY',

  CREATE_APP_BY_ANKR_CREDIT: 'CREATE_APP_BY_ANKR_CREDIT',

  SET_DAILY_STEP: 'SET_DAILY_STEP',

  SET_PRE_PAYMENT_PERIOD: 'SET_PRE_PAYMENT_PERIOD',
  GET_TRIAL_AMOUNT: 'GET_TRIAL_AMOUNT',
  RESET_TRIAL_AMOUNT: 'RESET_TRIAL_AMOUNT',
};

export const getReadyPaymentMethodList = (payMethodList: IPaymentMethod[]) =>
  payMethodList.filter(
    item =>
      item.status !== PayStatus.PENDING &&
      item.name !== PaymentMethod.CREDIT_CARD,
  );

export const PayActions = {
  fetchPaymentMethod: (
    teamId: string,
    paymentName: PaymentMethod,
  ): IExtendedRequestAction => ({
    type: PayActionTypes.FETCH_PAYMENT_METHOD,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/pay/v1alpha/teams/${teamId}/payMethods/${paymentName}`,
      method: 'GET',
      // @ts-ignore
      meta: {
        paymentName,
      },
    },
  }),
  fetchPaymentMethodList: (teamId: string): IExtendedRequestAction => ({
    type: PayActionTypes.FETCH_PAYMENT_METHODS,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/pay/v1alpha/teams/${teamId}/payMethods`,
      method: 'GET',
    },
  }),
  setupPaymentMethod: (
    teamId: string,
    paymentName: PaymentMethod,
  ): IExtendedRequestAction => ({
    type: PayActionTypes.SETUP_PAYMENT_METHOD,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/pay/v1alpha/teams/${teamId}/payMethods/${paymentName}:setup`,
      method: 'POST',
      // @ts-ignore
      meta: {
        paymentName,
      },
    },
  }),
  resetSetupPaymentMethod: () =>
    createAction(PayActionTypes.SETUP_PAYMENT_METHOD_RESET),

  checkPaymentMethod: (
    teamId: string,
    payName: PaymentMethod,
    apps?: IApiCheckSelectableParams[],
    needSelect?: boolean,
  ): IExtendedRequestAction => {
    return {
      type: PayActionTypes.CHECK_PAYMENT_METHOD,
      request: {
        url: `${process.env.REACT_APP_API_BASE}/pay/v1alpha/teams/${teamId}/payMethods/${payName}:check`,
        method: 'POST',
        data: { apps: apps && !apps[0] ? [] : apps },
        // @ts-ignore
        payName,
        needSelect: !!needSelect,
      },
    };
  },
  checkPaymentMethodByStoreParams: (
    teamId: string,
    payName: PaymentMethod,
    needSelect?: boolean,
  ) =>
    createAction(PayActionTypes.CHECK_PAYMENT_METHOD_BY_STORE_PARAMS, {
      teamId,
      payName,
      needSelect,
    }),
  resetCheckPaymentMethod: () =>
    createAction(PayActionTypes.CHECK_PAYMENT_METHOD_RESET),
  checkPaymentMethodsList: (
    teamId: string,
    paymentMethods: IPaymentMethod[],
    apps: IApiCheckSelectableParams[] = [],
  ): IExtendedMultiRequestsAction => {
    // TODO Remove the filter
    const list = paymentMethods.filter(
      (item: IPaymentMethod) => item.name !== PaymentMethod.CREDIT_CARD,
    );
    return {
      type: PayActionTypes.CHECK_PAYMENT_METHODS_LIST,
      request: list.map((item: IPaymentMethod) => ({
        url: `${process.env.REACT_APP_API_BASE}/pay/v1alpha/teams/${teamId}/payMethods/${item.name}:check`,
        method: 'POST',
        data: { apps: apps && !apps[0] ? [] : apps },
        // eslint-disable-next-line
        payName: item.name,
      })),
    };
  },
  selectPaymentMethod: (
    teamId: string,
    paymentName: PaymentMethod,
  ): IExtendedRequestAction => ({
    type: PayActionTypes.SELECT_PAYMENT_METHOD,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/pay/v1alpha/teams/${teamId}/payMethods/${paymentName}:select`,
      method: 'POST',
    },
    meta: {
      paymentName,
    },
  }),
  resetSelectPaymentMethod: () =>
    createAction(PayActionTypes.SELECT_PAYMENT_METHOD_RESET),
  fetchEscrow: (
    teamId: string,
    escrowType: EscrowType,
  ): IExtendedRequestAction => ({
    type: PayActionTypes.FETCH_ESCROW,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/pay/v1alpha/teams/${teamId}/escrows/${escrowType}`,
      method: 'GET',
    },
  }),
  fetchEscrowList: (
    teamId: string,
    payMethodsList: IPaymentMethod[],
  ): IExtendedMultiRequestsAction => {
    const readyList = getReadyPaymentMethodList(payMethodsList);
    const escrowList: EscrowType[] = readyList.map(item => item.type);
    const list = escrowList.filter(
      (item: EscrowType, index: number, self: EscrowType[]) => {
        return self.indexOf(item) === index;
      },
    );
    return {
      type: PayActionTypes.FETCH_ESCROW_LIST,
      request: list.map((type: EscrowType) => ({
        url: `${process.env.REACT_APP_API_BASE}/pay/v1alpha/teams/${teamId}/escrows/${type}`,
        method: 'GET',
      })),
    };
  },
  fetchEscrowBalanceList: (
    teamId: string,
    payMethodsList: IPaymentMethod[],
  ): IExtendedMultiRequestsAction => {
    const readyList = getReadyPaymentMethodList(payMethodsList);
    return {
      type: PayActionTypes.FETCH_BALANCES_LIST,
      request: readyList.map((item: IPaymentMethod) => ({
        url: `${process.env.REACT_APP_API_BASE}/pay/v1alpha/teams/${teamId}/escrows/${item.type}/balance?currency=${item.currency}`,
        method: 'GET',
        payName: item.name,
      })),
    };
  },
  withdrawEscrowAvailableBalance: (
    teamId: string,
    escrowType: EscrowType,
    params: IApiWithdrawEscrowAvailableBalanceParam,
  ): IExtendedRequestAction => ({
    type: PayActionTypes.WITHDRAW_AVAILABLE_BALANCE,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/pay/v2alpha/teams/${teamId}/escrows/${escrowType}:withdraw`,
      method: 'POST',
      data: params,
    },
  }),
  resetWithdrawBalance: () =>
    createAction(PayActionTypes.WITHDRAW_AVAILABLE_BALANCE_RESET),
  fetchEscrowWithdrawal: (
    teamId: string,
    withdrawalId: string,
  ): IExtendedRequestAction => ({
    type: PayActionTypes.FETCH_WITHDRAWAL,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/pay/v1alpha/teams/${teamId}/escrowWithdrawals/${withdrawalId}`,
      method: 'GET',
    },
  }),
  fetchEscrowWithdrawalList: (teamId: string): IExtendedRequestAction => ({
    type: PayActionTypes.FETCH_WITHDRAWAL_LIST,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/pay/v1alpha/teams/${teamId}/escrowWithdrawals`,
      method: 'GET',
    },
  }),

  checkPrice: (payload: IApiCheckPrice): IExtendedRequestAction => {
    return {
      type: PayActionTypes.CHECK_PRICE,
      request: {
        url: `${process.env.REACT_APP_API_BASE}/app/v2/app/token/check`,
        method: 'GET',
        params: payload,
      },
    };
  },

  checkPriceManual: () => createAction(PayActionTypes.CHECK_PRICE_MANUAL),

  setCandidatePaymentMethod: (paymentMethod: PaymentMethod) =>
    createAction(PayActionTypes.SET_CANDIDATE_PAYMENT_METHOD, {
      paymentMethod,
    }),

  setCurrentStep: (step: PaymentStep) =>
    createAction(PayActionTypes.SET_STEP, { step }),

  setDeployStep: (step: DeployStep) =>
    createAction(PayActionTypes.SET_PAYMENT_DEPLOY_STEP, { step }),

  setDailyStep: (step: DailyStep) =>
    createAction(PayActionTypes.SET_DAILY_STEP, { step }),

  setPrePaymentPeriod: (months: number) =>
    createAction(PayActionTypes.SET_PRE_PAYMENT_PERIOD, { months }),

  setPaymentConfigurationStep: (step: PaymentConfigurationStep) =>
    createAction(PayActionTypes.SET_PAYMENT_CONFIGURATION_STEP, { step }),

  getStripePublicKey: (): IExtendedRequestAction => {
    return {
      type: PayActionTypes.GET_STRIPE_PUBLIC_KEY,
      request: {
        url: `${process.env.REACT_APP_API_BASE}/pay/v1alpha/publishableKey`,
        method: 'GET',
      },
    };
  },
  fetchStripeClientSecret: (teamId: string): IExtendedRequestAction => {
    return {
      type: PayActionTypes.FETCH_STRIPE_CLIENT_SECRET,
      request: {
        url: `${process.env.REACT_APP_API_BASE}/pay/v1alpha/teams/${teamId}/cards:setup`,
        method: 'POST',
        data: {
          tag: generateRandomString(),
        },
      },
    };
  },
  createNewBankCard: (payload: IEnableNewCardPayload) =>
    createAction(PayActionTypes.CREATE_NEW_BANK_CARD, payload),
  resetEnableNewBankCard: () =>
    createAction(PayActionTypes.CREATE_NEW_BANK_CARD_RESET),
  enableBankCard: (teamId: string, cardId: string): IExtendedRequestAction => {
    return {
      type: PayActionTypes.ENABLE_BANK_CARD,
      request: {
        url: `${process.env.REACT_APP_API_BASE}/pay/v1alpha/teams/${teamId}/cards/${cardId}:enable`,
        method: 'POST',
      },
    };
  },
  selectCardPaymentMethod: (payload: ISelectCardPaymentMethod) =>
    createAction(PayActionTypes.SELECT_CARD_PAYMENT_METHOD, payload),
  createBankCard: (
    teamId: string,
    stripePaymentMethodId: string,
  ): IExtendedRequestAction => {
    return {
      type: PayActionTypes.CREATE_BANK_CARD,
      request: {
        url: `${process.env.REACT_APP_API_BASE}/pay/v1alpha/teams/${teamId}/cards`,
        method: 'POST',
        data: {
          stripe_payment_method_id: stripePaymentMethodId,
        },
      },
    };
  },
  fetchCards: (teamId: string): IExtendedRequestAction => {
    return {
      type: PayActionTypes.FETCH_BANK_CARDS,
      request: {
        url: `${process.env.REACT_APP_API_BASE}/pay/v1alpha/teams/${teamId}/cards`,
        method: 'GET',
      },
    };
  },
  deleteBankCard: (payload: IDeleteBankCardPayload) =>
    createAction(PayActionTypes.DELETE_BANK_CARD, payload),
  resetDeleteBankCard: () =>
    createAction(PayActionTypes.DELETE_BANK_CARD_RESET),

  getDailyAmount: ({
    type,
    clusterId,
    chartName,
    cpu,
    memory,
    storage,
    replicas,
  }: {
    type: PaymentMethod;
    clusterId: string;
    chartName: string;
    cpu: number;
    memory: number;
    storage: number;
    replicas: number;
  }): IExtendedRequestAction => {
    console.warn("Shouldn't be used until backend change");
    return {
      type: PayActionTypes.GET_DAILY_AMOUNT,
      request: {
        url: `${process.env.REACT_APP_API_BASE}/pay/v2alpha/get_all_amount`,
        method: 'POST',
        data: {
          cluster_id: clusterId,
          chart_name: chartName,
          cpu_limit: cpu,
          mem_limit: memory,
          storage_limit: storage,
          replicas_number: replicas,
          period: 1,
        },
      },
      meta: {
        id: type,
        takeLatest: true,
      },
    };
  },

  getTrialAmount: ({ teamId }: { teamId: string }): IExtendedRequestAction => {
    return {
      type: PayActionTypes.GET_TRIAL_AMOUNT,
      request: {
        url: `${process.env.REACT_APP_API_BASE}/pay/v2alpha/teams/${teamId}/get_trial_amount`,
        method: 'GET',
      },
      meta: {
        takeLatest: true,
      },
    };
  },
  resetTrialAmount: () => createAction(PayActionTypes.RESET_TRIAL_AMOUNT),

  getDepositAmount: ({
    type,
    clusterId,
    chartName,
    cpu,
    memory,
    storage,
    months,
    replicas,
  }: {
    type: PaymentMethod;
    clusterId: string;
    chartName: string;
    cpu: number;
    memory: number;
    storage: number;
    replicas: number;
    months: Month;
  }): IExtendedRequestAction => {
    return {
      type: PayActionTypes.GET_DEPOSIT_AMOUNT,
      request: {
        url: `${process.env.REACT_APP_API_BASE}/pay/v2alpha/get_all_amount`,
        method: 'POST',
        data: {
          cluster_id: clusterId,
          chart_name: chartName,
          cpu_limit: cpu,
          mem_limit: memory,
          storage_limit: storage,
          period: months,
          replicas_number: replicas,
        },
      },
      meta: {
        id: type,
        takeLatest: true,
      },
    };
  },

  getDepositList({ teamId }: { teamId: string }) {
    return {
      type: PayActionTypes.GET_DEPOSIT_LIST,
      request: {
        url: `${process.env.REACT_APP_API_BASE}/pay/v2alpha/teams/${teamId}/deposit_list`,
        method: 'GET',
        data: {},
      },
    };
  },

  extendApp(payload: IExtendAppPayload) {
    return createAction(PayActionTypes.EXTEND_APP, payload);
  },
  extendAppReset: () => createAction(PayActionTypes.EXTEND_APP_RESET),
  extendAppByNewCard: (payload: IExtendAppByNewCardPayload) =>
    createAction(PayActionTypes.EXTEND_APP_BY_NEW_CARD, payload),

  getPrePaymentList({ teamId }: { teamId: string }) {
    return {
      type: PayActionTypes.GET_PRE_PAYMENT_LIST,
      request: {
        url: `${process.env.REACT_APP_API_BASE}/pay/v2alpha/${teamId}/pre_payment_list`,
        method: 'GET',
      },
    };
  },

  createAppByDailyCard: (payload: ICreateAppPayload) =>
    createAction(PayActionTypes.CREATE_APP_BY_DAILY_CARD, payload),

  createAppByAnkrCredit: (payload: ICreateAppPayload) =>
    createAction(PayActionTypes.CREATE_APP_BY_ANKR_CREDIT, payload),

  createAppByDailyAnkrNative: (payload: ICreateAppPayload) =>
    createAction(PayActionTypes.CREATE_APP_BY_DAILY_ANKR_NATIVE, payload),

  createAppByDepositCard: (payload: ICreateAppPayload) =>
    createAction(PayActionTypes.CREATE_APP_BY_DEPOSIT_CARD, payload),

  getEthOrderInfo({ appId }: { appId: string }) {
    return {
      type: PayActionTypes.GET_ETH_PAYMENT_STATUS,
      request: {
        url: `${process.env.REACT_APP_API_BASE}/pay/v2alpha/eth_order_info/${appId}`,
        method: 'GET',
      },
    };
  },

  getAppPaymentInfo({ appId }: { appId: string }) {
    return {
      type: PayActionTypes.GET_ETH_PAYMENT_STATUS,
      request: {
        url: `${process.env.REACT_APP_API_BASE}/app/v2/app/${appId}`,
        method: 'GET',
      },
    };
  },
};
