import React from 'react';
import { Breadcrumbs, withTheme, Box } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { PageContext } from 'common-web/nav/PrivateRoute';
import { Link } from 'react-router-dom';
import { withStyles, StyleRules, Theme, fade } from '@material-ui/core/styles';
import { Classes } from 'jss';
import { IBreadcrumb } from 'common/types/commonTypes';
import { Helmet } from 'react-helmet';

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: '100%',
    padding: '18px 0px',
  },
  text: {
    fontWeight: 'bold',
    color: theme.palette.grey[900],
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.grey[700],
    fontWeight: 'bold',
  },
  title: {
    textDecoration: 'none',
  },
  breadcrumbs: {
    display: 'flex',
    height: '100%',
    borderLeft: `1px solid ${fade('#000000', 0.1)}`,
    marginLeft: 20,
    paddingLeft: 20,
  },
  '.MuiBreadcrumbs-ol': {},
});

const BREADCRUMBS_SEPARATOR = '/';

interface IPageTitleComponentProps extends RouteComponentProps {
  classes?: Classes;
  theme: Theme;
}

const PageTitleComponent = ({
  classes = {},
  theme: { breakpoints },
}: IPageTitleComponentProps) => {
  // const isSMDown = useMediaQuery(breakpoints.down('sm'));
  const isSMDown = false;
  const { breadcrumbsState } = React.useContext(PageContext);

  const renderHeaderTitle = () => {
    const headerTitle = breadcrumbsState.reduce(
      (prev: string, breadcrumb: IBreadcrumb | string) => {
        return (
          prev +
          ' - ' +
          (typeof breadcrumb === 'string' ? breadcrumb : breadcrumb.title)
        );
      },
      '',
    );
    return (
      <Helmet>
        <title>Ankr {headerTitle}</title>
      </Helmet>
    );
  };
  const renderTitle = ({
    title,
    isLink = false,
  }: {
    title: string;
    isLink?: boolean;
  }) => {
    return (
      <div key={title} className={isLink ? classes.link : classes.text}>
        {title}
      </div>
    );
  };

  if (breadcrumbsState.length < 1) {
    // if no breadcrumb props, return null
    return null;
  }

  const backLink = breadcrumbsState
    .slice()
    .reverse()
    .find(item => item.hasOwnProperty('link'));

  if (isSMDown) {
    const lastBreadcrumb = breadcrumbsState[breadcrumbsState.length - 1];

    const title =
      typeof lastBreadcrumb === 'string'
        ? lastBreadcrumb
        : lastBreadcrumb.title;
    return (
      <>
        {renderTitle({ title, isLink: false })}
        {renderHeaderTitle()}
      </>
    );
  } else {
    return (
      <Box className={classes.root}>
        {renderHeaderTitle()}
        {backLink && backLink.link && typeof backLink.link === 'string' && (
          <Breadcrumbs
            className={classes.breadcrumbs}
            separator={BREADCRUMBS_SEPARATOR}
          >
            {breadcrumbsState.map((breadcrumb: IBreadcrumb | string) => {
              const title =
                typeof breadcrumb === 'string' ? breadcrumb : breadcrumb.title;
              const link =
                typeof breadcrumb === 'string' ? undefined : breadcrumb.link;

              if (title.length < 1) {
                return null;
              }

              if (typeof link === 'string') {
                return (
                  <Link to={`${link}`} className={classes.title} key={title}>
                    {renderTitle({ title })}
                  </Link>
                );
              } else {
                return null;
              }
            })}
          </Breadcrumbs>
        )}
      </Box>
    );
  }
};

const PageTitle = withStyles(styles)(withTheme(withRouter(PageTitleComponent)));

export { PageTitle };
