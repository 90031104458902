import { createAPIReducer, createReducer } from 'common/utils/reduxUtils';
import { APIMarketActionTypes } from './APIMarketActions';
import { requestInactive, RequestStatus } from 'common/utils/requestStatus';
import {
  IAPIMarketInstanceStatsResponse,
  IAPIMarketInstance,
  IAPIMarketAPIResponse,
  IAPIMarketItem,
  IAPIMarketResponse,
  IAPIMarketInstanceStats,
  IAPIMarketItemDocumentation,
  IAPIMarketItemDocumentationResponse,
  IAPIMarketPlansResponse,
  IAPIMarketPlan,
  IAPIOrderResponse,
  IAPIOrder,
  IAPIDetails,
  IAPIUpgradePlanAmountResponse,
  IAPIDailyQuotaResponse,
  IArchiveModePaymentStatusResponse,
  IArchiveModePaymentStatus,
  ArchiveModePaymentStatus,
  IAPIProjectLogResponse,
} from './APIMarketTypes';
import { IExtendedAxiosResponse } from 'common/types';
import {
  mapInfuraStats,
  mapAPIList,
  mapAPIMarketList,
  mapAPIPlansList,
  mapAPIOrder,
} from './APIMarketMappings';
import { BigNumber } from 'bignumber.js';

export interface IAPIMarketState {
  list: IAPIMarketInstance[];
  market: IAPIMarketItem[];
  stats: IAPIMarketInstanceStats[];
  plans: IAPIMarketPlan[];
  currentPlan: IAPIMarketPlan | undefined;
  order: IAPIOrder | undefined;
  api: IAPIDetails | undefined;
  archiveModePaymentStatus: IArchiveModePaymentStatus | undefined;
  upgradePlanAmount: BigNumber | undefined;
  projectLog: object | undefined;
  apiDailyQuota: BigNumber | undefined;
  getUpgradePlanAmountStatus: RequestStatus;
  getProjectLogStatus: RequestStatus;
  documentation: IAPIMarketItemDocumentation;
  fetchMarketListStatus: RequestStatus;
  fetchAPIListStatus: RequestStatus;
  fetchStatsStatus: RequestStatus;
  fetchPlansStatus: RequestStatus;
  getOrderStatus: RequestStatus;
  fetchNetworkListStatus: RequestStatus;
  fetchNetworkAccessStatus: RequestStatus;
  fetchDocumentationStatus: RequestStatus;
  deleteStatus: RequestStatus;
  createStatus: RequestStatus;
  upgradePlanStatus: RequestStatus;
  getAPIStatus: RequestStatus;
  deleteAPIStatus: RequestStatus;
  getAPIDailyQuotaStatus: RequestStatus;
  getArchiveModePaymentStatusStatus: RequestStatus;
  createArchiveOrderStatus: RequestStatus;
}

const initialState: IAPIMarketState = {
  list: [],
  market: [],
  stats: [],
  plans: [],
  currentPlan: undefined,
  order: undefined,
  api: undefined,
  upgradePlanAmount: undefined,
  apiDailyQuota: undefined,
  documentation: {
    overview: '',
    availableNodes: [],
    explorerURL: '',
    documentationHTML: '',
  },
  archiveModePaymentStatus: undefined,
  fetchAPIListStatus: requestInactive(),
  fetchMarketListStatus: requestInactive(),
  fetchStatsStatus: requestInactive(),
  fetchPlansStatus: requestInactive(),
  getOrderStatus: requestInactive(),
  fetchNetworkListStatus: requestInactive(),
  fetchNetworkAccessStatus: requestInactive(),
  fetchDocumentationStatus: requestInactive(),
  deleteStatus: requestInactive(),
  createStatus: requestInactive(),
  upgradePlanStatus: requestInactive(),
  getAPIStatus: requestInactive(),
  deleteAPIStatus: requestInactive(),
  getUpgradePlanAmountStatus: requestInactive(),
  getProjectLogStatus: requestInactive(),
  getAPIDailyQuotaStatus: requestInactive(),
  getArchiveModePaymentStatusStatus: requestInactive(),
  createArchiveOrderStatus: requestInactive(),
};

export const APIMarketReducer = createReducer(initialState, {
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIMarketResponse>
  >(
    APIMarketActionTypes.FETCH_API_MARKET_MARKET_LIST,
    'fetchMarketListStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          market: action.data ? mapAPIMarketList(action.data) : [],
        };
      },
    },
  ),

  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIMarketAPIResponse>
  >(APIMarketActionTypes.FETCH_API_LIST, 'fetchAPIListStatus', {
    onSuccess: (state, action) => {
      return {
        ...state,
        list: action.data ? mapAPIList(action.data) : [],
      };
    },
  }),

  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIMarketInstanceStatsResponse>
  >(APIMarketActionTypes.FETCH_API_MARKET_API_STATS, 'fetchStatsStatus', {
    onRequest: state => {
      return {
        ...state,
        stats: [],
      };
    },
    onSuccess: (state, action) => {
      return {
        ...state,
        stats: action.data.data ? mapInfuraStats(action.data) : [],
      };
    },
  }),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIMarketPlansResponse>
  >(APIMarketActionTypes.FETCH_API_MARKET_PLANS_LIST, 'fetchPlansStatus', {
    onSuccess: (state, action) => {
      return {
        ...state,
        plans: action.data ? mapAPIPlansList(action.data) : [],
      };
    },
  }),

  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIOrderResponse>
  >(APIMarketActionTypes.GET_API_MARKET_ORDER, 'getOrderStatus', {
    onSuccess: (state, action) => {
      const order = action.data ? mapAPIOrder(action.data) : undefined;
      return {
        ...state,
        order,
      };
    },
  }),

  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIMarketItemDocumentationResponse>
  >(
    APIMarketActionTypes.FETCH_API_MARKET_DOCUMENTATION,
    'fetchDocumentationStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          documentation: action.data.documentation,
        };
      },
    },
  ),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIMarketInstanceStatsResponse>
  >(APIMarketActionTypes.DELETE_API_MARKET_API, 'deleteStatus', {
    onReset: state => {
      return {
        ...state,
        deleteStatus: requestInactive(),
      };
    },
  }),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIMarketInstanceStatsResponse>
  >(APIMarketActionTypes.CREATE_API_PROJECT, 'createStatus', {
    onReset: state => {
      return {
        ...state,
        createStatus: requestInactive(),
      };
    },
  }),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIMarketInstanceStatsResponse>
  >(APIMarketActionTypes.UPGRADE_API_MARKET_PLAN, 'upgradePlanStatus', {
    onReset: state => {
      return {
        ...state,
        upgradePlanStatus: requestInactive(),
      };
    },
  }),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIMarketInstanceStatsResponse>
  >(APIMarketActionTypes.DELETE_API, 'deleteAPIStatus', {
    onReset: state => {
      return {
        ...state,
        deleteAPIStatus: requestInactive(),
      };
    },
  }),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIUpgradePlanAmountResponse>
  >(
    APIMarketActionTypes.GET_API_MARKET_UPDATE_PLAN_AMOUNT,
    'getUpgradePlanAmountStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          upgradePlanAmount: new BigNumber(action.data?.amounts_all_payable),
        };
      },
    },
  ),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIProjectLogResponse>
  >(APIMarketActionTypes.GET_API_MARKET_PROJECT_LOG, 'getProjectLogStatus', {
    onSuccess: (state, action) => {
      return {
        ...state,
        projectLog: action.data?.total_calls,
      };
    },
  }),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIDailyQuotaResponse>
  >(APIMarketActionTypes.GET_API_DAILY_QUOTA, 'getAPIDailyQuotaStatus', {
    onSuccess: (state, action) => {
      return {
        ...state,
        apiDailyQuota: new BigNumber(action.data?.plan_max_req_daily),
      };
    },
  }),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IArchiveModePaymentStatusResponse>
  >(
    APIMarketActionTypes.GET_ARCHIVE_MODE_PAYMENT_STATUS,
    'getArchiveModePaymentStatusStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          archiveModePaymentStatus: {
            status: action.data.payment_status as ArchiveModePaymentStatus,
          },
        };
      },
    },
  ),
  ...createAPIReducer<
    IAPIMarketState,
    IExtendedAxiosResponse<IAPIMarketInstanceStatsResponse>
  >(APIMarketActionTypes.CREATE_ARCHIVE_ORDER, 'createArchiveOrderStatus', {}),
});
