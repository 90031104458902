import { useSelector } from 'react-redux';
import { IZendeskState } from 'common-web/modules/zendesk/reducers/zendeskReducer';
import { ITeamState } from 'common/modules/teams/reducers/teamReducer';

export const useZendeskOrganization = (doFetch = true) => {
  const { zendeskOrganization, fetchOrganizationStatus } = useSelector(
    (state: { team: ITeamState; zendesk: IZendeskState }) => {
      const currentTeamId = state.team.currentTeamId ?? '';
      return {
        zendeskOrganization: state.zendesk.organizations?.[currentTeamId],
        fetchOrganizationStatus: state.zendesk.fetchOrganizationStatus,
      };
    },
  );

  return {
    zendeskOrganization,
    fetchOrganizationStatus,
  };
};
