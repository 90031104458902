import { createAPIReducer, createReducer } from 'common/utils/reduxUtils';
import { requestInactive, RequestStatus } from 'common/utils/requestStatus';
import { IExtendedAxiosResponse } from 'common/types';
import { TwoFactorActionTypes } from '../actions/TwoFactorActions';
import {
  ITwoFactorCodeResponse,
  ITwoFactorConfirmationStatusResponse,
  ITwoFactorGenerateRecoveryCodesResponse,
  ITwoFactorStatusResponse,
} from '../TwoFactorTypes';
import queryString from 'query-string';

export interface ITwoFactorState {
  code: {
    url: string;
    secret: string;
  };
  enabled: boolean;
  fetchCodeStatus: RequestStatus;
  fetchStatus: RequestStatus;
  fetchEnableTwoFactorStatus: RequestStatus;
  fetchDisableTwoFactorStatus: RequestStatus;
  fetchDigitCodeStatus: RequestStatus;
  generateRecoveryCodesStatus: RequestStatus;
  getRecoveryCodesStatus: RequestStatus;
  recoveryCodes: string[];
  getReminderStatus: RequestStatus;
  showReminder: boolean;
}

const initialState: ITwoFactorState = {
  code: {
    url: '',
    secret: '',
  },
  enabled: false,
  fetchCodeStatus: requestInactive(),
  fetchStatus: requestInactive(),
  fetchEnableTwoFactorStatus: requestInactive(),
  fetchDisableTwoFactorStatus: requestInactive(),
  fetchDigitCodeStatus: requestInactive(),
  generateRecoveryCodesStatus: requestInactive(),
  getRecoveryCodesStatus: requestInactive(),
  recoveryCodes: [],
  getReminderStatus: requestInactive(),
  showReminder: false,
};

const extractSecretFromUrlCode = (url: string) => {
  const { search } = new URL(url);
  return queryString.parse(search).secret as string;
};

export const TwoFactorReducer = createReducer(initialState, {
  ...createAPIReducer<
    ITwoFactorState,
    IExtendedAxiosResponse<ITwoFactorCodeResponse>
  >(TwoFactorActionTypes.GET_CODE, 'fetchCodeStatus', {
    onSuccess: (state, action) => {
      return {
        ...state,
        code: {
          url: action.data.key_url,
          secret: extractSecretFromUrlCode(action.data.key_url),
        },
      };
    },
  }),
  ...createAPIReducer<
    ITwoFactorState,
    IExtendedAxiosResponse<ITwoFactorStatusResponse>
  >(TwoFactorActionTypes.GET_STATUS, 'fetchStatus', {
    onSuccess: (state, action) => {
      return {
        ...state,
        enabled: action.data.status === 'ENABLED',
      };
    },
  }),
  ...createAPIReducer<
    ITwoFactorState,
    IExtendedAxiosResponse<ITwoFactorConfirmationStatusResponse>
  >(
    TwoFactorActionTypes.ENABLE_TWO_FACTOR_STATUS,
    'fetchEnableTwoFactorStatus',
  ),
  ...createAPIReducer<
    ITwoFactorState,
    IExtendedAxiosResponse<ITwoFactorConfirmationStatusResponse>
  >(
    TwoFactorActionTypes.DISABLE_TWO_FACTOR_STATUS,
    'fetchDisableTwoFactorStatus',
  ),
  ...createAPIReducer<
    ITwoFactorState,
    IExtendedAxiosResponse<ITwoFactorConfirmationStatusResponse>
  >(TwoFactorActionTypes.CHECK_DIGIT_CODE, 'fetchDigitCodeStatus'),
  ...createAPIReducer<
    ITwoFactorState,
    IExtendedAxiosResponse<ITwoFactorGenerateRecoveryCodesResponse>
  >(
    TwoFactorActionTypes.GENERATE_RECOVERY_CODES,
    'generateRecoveryCodesStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          recoveryCodes: action.data.codes,
        };
      },
    },
  ),
  ...createAPIReducer<
    ITwoFactorState,
    IExtendedAxiosResponse<ITwoFactorGenerateRecoveryCodesResponse>
  >(TwoFactorActionTypes.GET_RECOVERY_CODES, 'getRecoveryCodesStatus', {
    onSuccess: (state, action) => {
      return {
        ...state,
        recoveryCodes: action.data.codes,
      };
    },
  }),
  ...createAPIReducer<ITwoFactorState, IExtendedAxiosResponse<{ ok: boolean }>>(
    TwoFactorActionTypes.GET_TWOFA_REMINDER,
    'getReminderStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          showReminder: action.data.ok,
        };
      },
      onReset: state => ({
        ...state,
        showReminder: false,
      }),
    },
  ),
});
