import { createReducer } from 'common/utils/reduxUtils';

import {
  LOCATION_CHANGE,
  LocationChangeAction,
  RouterActionType,
} from 'connected-react-router';
import { mixpanelTrack } from 'common/utils/analytics/mixpanelTrack';
import { GATrackPageView } from 'common/utils/analytics/GATrack';

export interface ILocationState {
  pathname: string;
  stack: string[];
}

const initialState: ILocationState = {
  pathname: '/',
  stack: [],
};

const modifyStack = (
  action: RouterActionType,
  stack: string[],
  current: string,
) => {
  switch (action) {
    case 'PUSH':
      return [...stack, current];
    case 'POP':
      return [...stack].slice(1);
    case 'REPLACE':
      return [...[...stack].slice(1), current];
  }
};

const locationReducer = createReducer(initialState, {
  [LOCATION_CHANGE]: (state, action: LocationChangeAction) => {
    const { pathname } = action.payload.location;

    mixpanelTrack({
      event: 'Page View',
      properties: { pathname },
    });
    GATrackPageView(pathname);

    return {
      pathname,
      stack: modifyStack(action.payload.action, state.stack, pathname),
    };
  },
});

export { locationReducer };
