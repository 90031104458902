import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { persistReducer, persistStore } from 'redux-persist';
import { requestsCacheMiddleware } from 'redux-saga-requests';
import storage from 'redux-persist/lib/storage';
import { rootSaga } from './effects';
import { createRootReducer } from './reducers';
import { ROOT_PERSIST_KEY } from 'common/core/const';
import { historyInstance } from 'common/utils/historyInstance';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: ROOT_PERSIST_KEY,
  storage,
  whitelist: ['ui', 'redirect'],
};

const composeEnhancers =
  typeof window === 'object' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(
    routerMiddleware(historyInstance),
    requestsCacheMiddleware(),
    sagaMiddleware,
  ),
);

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(historyInstance),
);
const store = createStore(persistedReducer, enhancer);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
