import { IExtendedRequestAction } from 'common/types';
import { API_MARKET_ADMIN } from '../APIMarketConsts';
import {
  APIAuthenticationType,
  APIMarketStatsFilter,
  IAPIPayment,
  SubscribeStatus,
} from './APIMarketTypes';
import { createAction } from 'common/utils/reduxUtils';
import { MissingOrderEmptyAPIList } from './effects/interceptors';

export const APIMarketActionTypes = {
  FETCH_API_MARKET_MARKET_LIST: 'FETCH_API_MARKET_MARKET_LIST',
  FETCH_API_MARKET_API_LIST: 'FETCH_API_MARKET_API_LIST',
  FETCH_API_LIST: 'FETCH_API_LIST',
  FETCH_API_MARKET_DOCUMENTATION: 'FETCH_API_MARKET_DOCUMENTATION',
  CREATE_API_PROJECT: 'CREATE_API_PROJECT',
  CREATE_API_PROJECT_RESET: 'CREATE_API_PROJECT_RESET',
  GET_API_RESET: 'GET_API_RESET',
  UPGRADE_API_MARKET_PLAN: 'UPGRADE_API_MARKET_PLAN',
  UPGRADE_API_MARKET_PLAN_RESET: 'UPGRADE_API_MARKET_PLAN_RESET',
  DELETE_API_MARKET_API: 'DELETE_API_MARKET_API',
  DELETE_API_MARKET_API_RESET: 'DELETE_API_MARKET_API_RESET',
  FETCH_API_MARKET_API_STATS: 'FETCH_API_MARKET_API_STATS',
  FETCH_API_MARKET_CREATE_DATA: 'FETCH_API_MARKET_CREATE_DATA',
  FETCH_API_MARKET_CREATE_DATA_RESET: 'FETCH_API_MARKET_CREATE_DATA',
  FETCH_API_MARKET_PLANS_LIST: 'FETCH_API_MARKET_PLANS_LIST',
  GET_API_MARKET_ORDER: 'GET_API_MARKET_ORDER',
  GET_API_MARKET_UPDATE_PLAN_AMOUNT: 'GET_API_MARKET_UPDATE_PLAN_AMOUNT',
  GET_API_MARKET_PROJECT_LOG: 'GET_API_MARKET_PROJECT_LOG',
  DELETE_API: 'DELETE_API',
  DELETE_API_RESET: 'DELETE_API_RESET',
  GET_API_DAILY_QUOTA: 'GET_API_DAILY_QUOTA',
  GET_API_DAILY_QUOTA_RESET: 'GET_API_DAILY_QUOTA_RESET',
  GET_ARCHIVE_MODE_PAYMENT_STATUS: 'GET_ARCHIVE_MODE_PAYMENT_STATUS',
  GET_ARCHIVE_MODE_PAYMENT_STATUS_RESET:
    'GET_ARCHIVE_MODE_PAYMENT_STATUS_RESET',
  CREATE_ARCHIVE_ORDER: 'CREATE_ARCHIVE_ORDER',
  CREATE_ARCHIVE_ORDER_RESET: 'CREATE_ARCHIVE_ORDER_RESET',
};

export interface ICreateAPIPayload {
  serviceId: string;
  projectName: string;
  nodeCode: string;
  teamId: string;
  subscribeStatus: SubscribeStatus;
  authType: APIAuthenticationType;
  authPassword: string;
  authUsername: string;
  authToken: string;

  paymentMethods: string;
  creditCardId: string;
}

export interface IUpgradePlanPayload {
  payment: IAPIPayment;
  planId: string;
  orderId: string;
}

export interface IArchiveModePaymentStatusPayload {
  teamId: string;
  nodeCode: string;
  planId: string;
}

export interface IArchiveOrderPayload {
  teamId: string;
  nodeCode: string;
  payment: IAPIPayment;
}

function createStatsDate(from: APIMarketStatsFilter): number {
  switch (from) {
    case APIMarketStatsFilter.allTime:
      return 1;
    case APIMarketStatsFilter.oneMonth:
      return 30;
    case APIMarketStatsFilter.sevenDays:
      return 7;
  }
}
export const APIMarketActions = {
  fetchInfuraMarketList: (): IExtendedRequestAction => ({
    type: APIMarketActionTypes.FETCH_API_MARKET_MARKET_LIST,
    request: {
      url: `/apis/apiservice/node/v1alpha/list`,
      method: 'GET',
    },
  }),
  fetchInfuraAPIList: (teamId: string): IExtendedRequestAction => ({
    type: 'EMPTY', // should be retrieved together with market list, see FETCH_API_LIST and runFetchAPIListSaga
    request: {
      url: `/apis/apiorder/v2alpha/api/project/list/${teamId}`,
      method: 'GET',
      // @ts-ignore
      handleError: [MissingOrderEmptyAPIList],
    },
  }),
  fetchAPIList: (teamId: string) =>
    createAction(APIMarketActionTypes.FETCH_API_LIST, teamId),
  fetchAPIPlansList: (): IExtendedRequestAction => ({
    type: APIMarketActionTypes.FETCH_API_MARKET_PLANS_LIST,
    request: {
      url: `/apis/apiservice/plan/v1alpha/system`,
      method: 'GET',
    },
  }),
  getAPIUpdatePlanAmount: (
    planId: string,
    orderId: string,
  ): IExtendedRequestAction => ({
    type: APIMarketActionTypes.GET_API_MARKET_UPDATE_PLAN_AMOUNT,
    request: {
      url: `/apis/apiorder/v2alpha/api/order/GetUpgradeOrderPayableAmount`,
      method: 'POST',
      data: {
        plan_id: planId,
        order_id: orderId,
      },
    },
  }),
  getAPIProjetLog: (
    projectId: string,
    timeType: string,
  ): IExtendedRequestAction => ({
    type: APIMarketActionTypes.GET_API_MARKET_PROJECT_LOG,
    request: {
      url: `/apis/apiorder/v2alpha/api/project/log/total_HM/${projectId}/${timeType}`,
      method: 'GET',
    },
  }),
  fetchInfuraAPIDocumentation: (id: string): IExtendedRequestAction => ({
    type: APIMarketActionTypes.FETCH_API_MARKET_DOCUMENTATION,
    request: {
      url: `${API_MARKET_ADMIN}/api/documentation`,
      method: 'POST',
      data: {
        apiId: id,
      },
    },
    meta: {
      noAuth: true,
      driver: 'mock',
    },
  }),
  createAPI: (payload: ICreateAPIPayload) =>
    createAction(APIMarketActionTypes.CREATE_API_PROJECT, payload),
  createInfuraAPIReset: () =>
    createAction(APIMarketActionTypes.CREATE_API_PROJECT_RESET),

  getAPIReset: () => createAction(APIMarketActionTypes.GET_API_RESET),

  upgradePlan: (payload: IUpgradePlanPayload) =>
    createAction(APIMarketActionTypes.UPGRADE_API_MARKET_PLAN, payload),
  upgradePlanReset: () =>
    createAction(APIMarketActionTypes.UPGRADE_API_MARKET_PLAN_RESET),

  deleteAPI: (id: string) => createAction(APIMarketActionTypes.DELETE_API, id),
  deleteAPIReset: () => createAction(APIMarketActionTypes.DELETE_API_RESET),

  deleteInfuraAPI: (key: string): IExtendedRequestAction => ({
    type: APIMarketActionTypes.DELETE_API_MARKET_API,
    request: {
      url: `${API_MARKET_ADMIN}/api/delete`,
      method: 'POST',
      data: { key },
    },
    meta: {
      noAuth: true,
      driver: 'mock',
    },
  }),
  deleteInfuraAPIReset: () =>
    createAction(APIMarketActionTypes.DELETE_API_MARKET_API_RESET),

  // from could be just YYYY-MM-DD or with HH:II:SS
  // if period is more than 24 hours, daily statistic will return,
  // else hourly statistics
  fetchInfuraAPIStats: (
    key: string,
    from: APIMarketStatsFilter,
  ): IExtendedRequestAction => ({
    type: APIMarketActionTypes.FETCH_API_MARKET_API_STATS,
    request: {
      url: `${API_MARKET_ADMIN}/statistics`,
      method: 'POST',
      data: {
        key,
        period: createStatsDate(from),
        //from.toISOString()
      },
    },
    meta: {
      noAuth: true,
      driver: 'mock',
    },
  }),
  getAPIDailyQuota: (planId: string): IExtendedRequestAction => ({
    type: APIMarketActionTypes.GET_API_DAILY_QUOTA,
    request: {
      url: `/apis/apiorder/v1alpha/api/order/max-req-daily/${planId}`,
      method: 'GET',
    },
  }),
  createArchiveOrder: ({
    order,
    api,
  }: {
    order: IArchiveOrderPayload;
    api: ICreateAPIPayload;
  }) =>
    createAction(APIMarketActionTypes.CREATE_ARCHIVE_ORDER, {
      order,
      api,
    }),
  createArchiveOrderReset: () =>
    createAction(APIMarketActionTypes.CREATE_ARCHIVE_ORDER_RESET),
};
