import React, { useEffect } from 'react';

import { StyledComponentProps, Theme, withStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { tHTML } from 'common/utils/intl';
import { useDispatch, useSelector } from 'react-redux';
import { USD_PRICE_PRECISION } from 'common/core/const';
import { PayActions } from 'common/modules/billing';
import { useInterval } from 'common/utils/hooks/useInterval';
import { Milliseconds } from 'common/types';
import { useEnterprise } from 'common/modules/teams/hooks/useEnterprise';
import { IApiStoreState } from 'store/reducers';
import { isUserAuthenticated } from 'auth/store/reducers/tokensReducer';
import { useCallback } from 'react';

const styles = (theme: Theme): StyleRules => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    color: theme.palette.grey[700],
    fontSize: 12,
    fontWeight: 'normal',
    paddingRight: 20,
    height: 34,
    borderRight: '1px solid #E1E4EB',
    '& .balance': {
      paddingLeft: 3,
      color: theme.palette.grey[800],
      fontWeight: 'bold',
      fontSize: 14,
    },
  },
});

interface IToolBarBalanceProps extends StyledComponentProps {
  prop?: string;
}

const REFRESH_RATE: Milliseconds = 60_000 * 5; // 5 min

const ToolBarBalanceComponent = ({ classes = {} }: IToolBarBalanceProps) => {
  const { amount, teamId } = useSelector((state: IApiStoreState) => ({
    amount: state.pay.trialAmount,
    teamId: state.team.currentTeamId || '',
  }));
  const { isEnterprise } = useEnterprise();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state: IApiStoreState) =>
    isUserAuthenticated(state.tokens),
  );

  const getTrialAmount = useCallback(
    () =>
      isAuthenticated &&
      !isEnterprise &&
      teamId &&
      dispatch(PayActions.getTrialAmount({ teamId })),
    [isAuthenticated, isEnterprise, teamId, dispatch],
  );

  useEffect(() => {
    getTrialAmount();
  }, [getTrialAmount]);

  useInterval(() => {
    getTrialAmount();
  }, REFRESH_RATE);

  if (!isAuthenticated) return null;

  return !isEnterprise && amount && amount.gt(0) ? (
    <div className={classes.root}>
      {tHTML('layout.balance', {
        balance: amount.toFormat(USD_PRICE_PRECISION),
      })}
    </div>
  ) : null;
};

const ToolBarBalance = withStyles(styles)(ToolBarBalanceComponent);

export { ToolBarBalance };
