import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { StyledComponentProps, withStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import { t } from 'common/utils/intl';
import { toolbarStyles } from './ToolbarStyles';
import { ProfileButtonContainer } from './ProfileButton';
import { UnauthenticatedButton } from './UnauthenticatedButton/UnauthenticatedButton';
import { ToolBarBalance } from './ToolBarBalance';

const ENABLE_LINKS = false;

interface IToolBarProps extends StyledComponentProps {}

const ToolBarComponent = ({ classes = {} }: IToolBarProps) => {
  return (
    <div className={classes.root}>
      {ENABLE_LINKS && (
        <>
          <Link
            to="/documentation"
            component={RouterLink}
            underline="none"
            className={classes.link}
          >
            {t('layout.documentation')}
          </Link>
          <Link
            to="/support"
            component={RouterLink}
            underline="none"
            className={classes.link}
          >
            {t('layout.support')}
          </Link>
        </>
      )}
      <ToolBarBalance />
      <ProfileButtonContainer />
      <UnauthenticatedButton />
    </div>
  );
};

const ToolBar = withStyles(toolbarStyles)(ToolBarComponent);

export { ToolBar };
