import {
  all,
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { sendRequest } from 'redux-saga-requests';
import { TeamActions, TeamActionTypes } from '../actions/TeamActions';
import { UserActionTypes } from 'auth/store/actions/UserActions';
import { ITeamState } from '../reducers/teamReducer';
import {
  isUserAuthenticated,
  ITokensState,
} from 'auth/store/reducers/tokensReducer';
import { REHYDRATE } from 'redux-persist';
import { historyInstance } from 'common/utils/historyInstance';
import { Action } from 'redux-actions';
import * as Sentry from '@sentry/browser';
import { isRequestSuccessful } from 'common/utils/requestStatus';
import { ICreateTeamWithMemberPayload } from '../teamTypes';
import { onInvitationConfirm } from './confirmUserEffect';
import { PROVIDER_SELECT_TEAM_PATH } from '../../../core/const';
import { PayActions } from '../../billing';
import { TEAM_PREFIX } from 'common/core/webPaths';

export function* createTeamWithMembers(
  action: Action<ICreateTeamWithMemberPayload & { redirectOnSuccess?: string }>,
) {
  try {
    const { createTeamStatus, inviteTeamMemberStatus } = yield select(
      (state: { team: ITeamState }) => {
        return {
          createTeamStatus: state.team.createTeamStatus,
          inviteTeamMemberStatus: state.team.inviteTeamMemberStatus,
        };
      },
    );

    if (!isRequestSuccessful(createTeamStatus)) {
      const { error } = yield call(
        sendRequest,
        TeamActions.createTeam({ teamName: action.payload.teamName }),
        { dispatchRequestAction: true },
      );

      if (error) {
        return;
      }
    }

    const emails = action.payload.members.map(member => member.email);
    const roles = action.payload.members.map(member => member.role);

    const { currentTeamId } = yield select((state: { team: ITeamState }) => {
      return {
        currentTeamId: state.team.currentTeamId,
      };
    });

    if (!isRequestSuccessful(inviteTeamMemberStatus)) {
      const { error } = yield call(
        sendRequest,
        TeamActions.inviteTeamMember(emails, currentTeamId, roles),
        { dispatchRequestAction: true },
      );

      if (error) {
        return;
      }
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* onFetchTeamSuccess() {
  const teamId = yield select((state: { team: ITeamState }) => {
    return state.team.currentTeamId;
  });
  if (teamId) {
    yield put(PayActions.fetchPaymentMethodList(teamId));
    yield put(PayActions.fetchCards(teamId));
  }
}

export function* onTeamSelectSaga(action: Action<{ teamId: string }>) {
  return yield all([
    call(sendRequest, TeamActions.fetchTeamRoles(action.payload.teamId)),
    call(sendRequest, TeamActions.fetchTeamDetail(action.payload.teamId)),
  ]);
}

function* onTeamsFetchSuccess() {
  yield delay(0);
  const teamId = yield select((state: { team: ITeamState }) => {
    return state.team.currentTeamId;
  });
  yield put(TeamActions.fetchTeamRoles(teamId));
  yield put(TeamActions.fetchTeamDetail(teamId));
  const teamIds = yield select((state: { team: ITeamState }) => {
    return state.team.teams.map(team => team.id);
  });
  if (
    historyInstance.location.pathname === PROVIDER_SELECT_TEAM_PATH ||
    historyInstance.location.pathname === TEAM_PREFIX
  ) {
    yield call(sendRequest, TeamActions.fetchAllTeamsDetail(teamIds), {
      dispatchRequestAction: true,
    });
  }
}

function* fetchTeamsSaga() {
  const isAuthenticated = yield select((state: { tokens: ITokensState }) => {
    return isUserAuthenticated(state.tokens);
  });

  if (isAuthenticated) {
    yield put(TeamActions.fetchTeams());
  }
}

function* teamSaga() {
  yield fetchTeamsSaga();
  yield takeEvery(
    TeamActionTypes.CONFIRM_TEAM_MEMBER_SUCCESS,
    onInvitationConfirm,
  );
  yield takeEvery(TeamActionTypes.FETCH_TEAMS_SUCCESS, onFetchTeamSuccess);
  yield takeEvery(TeamActionTypes.SET_CURRENT_TEAM, onTeamSelectSaga);
  yield takeEvery(TeamActionTypes.FETCH_TEAMS_SUCCESS, onTeamsFetchSuccess);
  yield takeEvery(
    TeamActionTypes.CREATE_TEAM_WITH_MEMBERS,
    createTeamWithMembers,
  );
  yield takeLatest([REHYDRATE, UserActionTypes.SIGNIN_SUCCESS], fetchTeamsSaga);
}

export { teamSaga };
