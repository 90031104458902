import { RequestStatus, RequestStatusType } from '../requestStatus';
import { useRef } from 'react';

/**
 *  Runs a callback each time when status changes to the callOnStatus
 * @param callback
 * @param status
 * @param callOnStatus
 */
export const useRequestStatusCallback = (
  callback: () => void,
  status: RequestStatus,
  callOnStatus: RequestStatusType,
) => {
  const prevStatus = useRef(status);
  if (status.type !== prevStatus.current.type) {
    prevStatus.current = status;
    if (status.type === callOnStatus) {
      callback();
    }
  }
};
/**
 * Runs a callback each time when status changes to the successful state
 * @param callback
 * @param status
 */
export const useRequestSuccess = (
  callback: () => void,
  status: RequestStatus,
) => {
  useRequestStatusCallback(callback, status, RequestStatusType.successful);
};
/**
 * Runs a callback each time when status changes to the failed state
 * @param callback
 * @param status
 */
export const useRequestFailure = (
  callback: () => void,
  status: RequestStatus,
) => {
  useRequestStatusCallback(callback, status, RequestStatusType.failed);
};
/**
 * Runs a callback each time when status changes to the active state
 * @param callback
 * @param status
 */
export const useRequestActive = (
  callback: () => void,
  status: RequestStatus,
) => {
  useRequestStatusCallback(callback, status, RequestStatusType.active);
};
