import { PaymentMethod } from '../../billing/const';

export const CRYPTO_PAY_METHODS_LIST: PaymentMethod[] = [
  PaymentMethod.ANKR_ERC20,
  PaymentMethod.USDT,
  PaymentMethod.ANKR,
];

export const PAY_METHODS_LIST: PaymentMethod[] = [
  ...CRYPTO_PAY_METHODS_LIST,
  PaymentMethod.CREDIT_CARD,
  PaymentMethod.CREDIT_CARD,
];

export enum EscrowType {
  ANKR = 'ANKR',
  ETH = 'ETH',
  CREDIT_CARD = 'CREDIT_CARD',
}

export const getEscrowType = (payName: PaymentMethod): EscrowType => {
  switch (payName) {
    case PaymentMethod.ANKR:
      return EscrowType.ANKR;
    case PaymentMethod.ANKR_CREDIT: // TODO?!
      return EscrowType.ANKR;
    case PaymentMethod.CREDIT_CARD:
      return EscrowType.CREDIT_CARD;
    case PaymentMethod.ANKR_ERC20:
    case PaymentMethod.USDT:
      return EscrowType.ETH;
  }
  throw new Error('Unknown error');
};

export enum PayCurrency {
  ANKR = 'ANKR',
  USDT = 'USDT',
  USD = 'USD',
}

export enum PayStatus {
  PENDING = 'PENDING',
  READY = 'READY', //READY means ready for pay or deposite or withdraw.
  ENABLED = 'ENABLED', //ENABLED means current active pay method
}

export enum WithdrawStatus {
  PENDING = 'PENDING', //PENDING: waiting for withdrawing finish
  CONFIRMED = 'CONFIRMED', //CONFIRMED: withdraw success
  ROLLBACK = 'ROLLBACK', //ROLLBACK: withdraw fail
}

export interface IApiPayMethod {
  name: PaymentMethod;
  type: EscrowType;
  currency: PayCurrency;
  selected: boolean; //pay method is selected. selected means current active pay method for next metering recycle. selected pay method will be ENABLEDin next metering recycle
  create_time: Date;
  update_time: Date;
  status: PayStatus;
}

export interface IPaymentMethod {
  name: PaymentMethod;
  type: EscrowType;
  currency: PayCurrency;
  selected: boolean;
  createTime: Date;
  updateTime: Date;
  status: PayStatus;
  needRetry: boolean;
}

export interface IApiEscrow {
  addr: string; //blockchain address
  type: EscrowType;
  create_time: Date;
  update_time: Date;
}

export interface IEscrow {
  addr: string;
  type: EscrowType;
  createTime: Date;
  updateTime: Date;
}

export interface IApiMoney {
  unit: PayCurrency;
  precision: string;
  scale: number;
  text: string;
}

export type IMoney = IApiMoney;

export interface IApiWithdrawal {
  id: string; //unique withdrawal id
  tx_hash: string; //chain tx hash. only show when the status is CONFIRMED
  team_id: string;
  type: EscrowType;
  currency: PayCurrency;
  from: string; //from address
  to: string; //to address
  tag: string; //request tag or automatically generate if request tag is empty
  amount: IApiMoney; //withdraw amount
  create_time: Date;
  update_time: Date;
  status: WithdrawStatus;
}

export interface IAipWithdrawalListResponse {
  escrow_withdrawals: IApiWithdrawal[];
}

export interface IWithdrawal {
  id: string;
  txHash: string;
  teamId: string;
  type: EscrowType;
  currency: PayCurrency;
  from: string;
  to: string;
  amount: IMoney;
  createTime: Date;
  updateTime: Date;
  status: WithdrawStatus;
}

export interface IApiBalance {
  available: IApiMoney; // Available balance. available = total - uncharged - reserved
  total: IApiMoney; // Address balance
  uncharged: IApiMoney; // Uncharged cost. uncharged means metered but not charged cost
  reserved: IApiMoney; // Reserved cost. currently is equal to all running applications hour cost
}

export type IBalance = {
  payName: PaymentMethod;
  data: IApiBalance;
};

export enum CardStatus {
  VERIFYING = 'VERIFYING',
  READY = 'READY',
}

export enum CardBrand {
  AMEX = 'amex',
  DINERS = 'diners',
  DISCOVER = 'discover',
  JCB = 'jcb',
  MASTER = 'mastercard',
  UNIONPAY = 'unionpay',
  VISA = 'visa',
  UNKNOWN = 'unknown',
}

export interface IApiCard {
  id: string;
  brand: CardBrand;
  last4: string;
  exp_year: number;
  exp_month: number;
  enabled: boolean;
  status: CardStatus;
  create_time: Date;
  update_time: Date;
}

export interface IApiCardList {
  cards: IApiCard[];
}

export interface ICard {
  id: string;
  brand: CardBrand;
  last4: string;
  expYear: number;
  expMonth: number;
  enabled: boolean;
  status: CardStatus;
  createTime: Date;
  updateTime: Date;
}

export interface IApiStripeClientSecret {
  stripe_client_secret: string;
}

export interface IApiCreateCardResponse {
  id: string;
  brand: CardBrand;
  last4: string;
  exp_year: number;
  exp_month: number;
  enabled: boolean;
  status: 'VERIFYING' | 'READY';
  create_time: string;
  update_time: string;
}

export interface IApiPayMethodListResponse {
  methods: IApiPayMethod[];
}

export interface IApiCheckSelectableParams {
  cluster_id: string;
  cpu: string;
  memory: string;
  storage: string;
  chart_name: string;
}

export interface IApiCheckPayMethodSelectableResponse {
  selectable: boolean;
  demand: IApiMoney;
  total: IApiMoney;
  available_balance: IApiMoney;
}

export interface IPaymentMethodAvailabilityData {
  selectable: boolean;
  demand: IMoney;
  total: IMoney;
  availableBalance: IMoney;
}

export interface IPaymentMethodAvailability {
  payName: PaymentMethod;
  data: IPaymentMethodAvailabilityData | undefined;
}

export interface IApiWithdrawEscrowAvailableBalanceParam {
  currency: PayCurrency;
  to: string;
  amount: string;
  tag?: string; //optional. if tag is empty, the system will automatically creates one. tag prevents duplicate request, recommend use UUID.
  code?: string;
}

export interface IPrePaymentPeriod {
  months: number;
}
