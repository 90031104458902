/* eslint-disable @typescript-eslint/camelcase */
import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import {
  IExtendAppByNewCardPayload,
  PayActions,
  PayActionTypes,
} from '../actions/PayActions';
import { ITeamState } from '../../teams/reducers/teamReducer';
import {
  getPaymentMethod,
  getPaymentMethodAvailability,
  IPayState,
} from '../reducers/payReducer';
import {
  IApiCheckSelectableParams,
  IPaymentMethod,
  PayStatus,
} from '../../apps/types/payTypes';
import { IAppsState } from '../../apps/reducers/appsReducer';
import { AppsActionTypes } from 'common/modules/apps/actions/AppsActions';
import * as Sentry from '@sentry/browser';
import { RequestAction, sendRequest } from 'redux-saga-requests';
import { PaymentMethod, PaymentStep } from '../const';
import { IChartDetails } from '../../apps/types';

const FETCH_PAYMENT_REPEAT_DELAY = 2000;

function* onFetchPaymentMethodSuccess(action: RequestAction) {
  try {
    const paymentName = action.meta?.requestAction.request.meta.paymentName;

    const {
      currentTeamId = '',
      paymentMethod,
      checkPriceParam,
      paymentMethods,
    }: {
      currentTeamId?: string;
      paymentMethod?: IPaymentMethod;
      paymentMethods: IPaymentMethod[];
      checkPriceParam: IApiCheckSelectableParams;
    } = yield select(
      (state: { team: ITeamState; pay: IPayState; apps: IAppsState }) => {
        return {
          currentTeamId: state.team.currentTeamId,
          paymentMethod: getPaymentMethod(
            state.pay.paymentMethods,
            paymentName,
          ),
          paymentMethods: state.pay.paymentMethods,
          checkPriceParam: state.apps?.checkPriceParam,
        };
      },
    );

    if (paymentMethod?.status === PayStatus.PENDING) {
      yield delay(FETCH_PAYMENT_REPEAT_DELAY);
      yield put(PayActions.fetchPaymentMethod(currentTeamId, paymentName));
    } else if (paymentMethod?.status === PayStatus.READY) {
      yield call(
        sendRequest,
        PayActions.fetchEscrowList(currentTeamId, [paymentMethod]),
        { dispatchRequestAction: true },
      );
      yield call(
        sendRequest,
        PayActions.fetchEscrowBalanceList(currentTeamId, [paymentMethod]),
        { dispatchRequestAction: true },
      );
      const { response } = yield call(
        sendRequest,
        PayActions.checkPaymentMethod(currentTeamId, paymentName, [
          checkPriceParam,
        ]),
        { dispatchRequestAction: true },
      );

      if (
        response.data.selectable ||
        (paymentMethods.length === 1 &&
          paymentMethods.find(item => item.name === paymentName))
      ) {
        yield call(
          sendRequest,
          PayActions.selectPaymentMethod(currentTeamId, paymentName),
          { dispatchRequestAction: true },
        );
      }

      const { paymentStep } = yield select(
        (state: { team: ITeamState; pay: IPayState }) => {
          return {
            paymentStep: state.pay.paymentStep,
          };
        },
      );

      if (paymentStep === PaymentStep.CREATING_ESCROW) {
        yield put(PayActions.setCurrentStep(PaymentStep.DEPOSIT));
      }
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* fetchBalances() {
  const { currentTeamId, paymentMethods } = yield select(
    (state: { team: ITeamState; pay: IPayState }) => {
      return {
        paymentMethods: state.pay.paymentMethods,
        currentTeamId: state.team.currentTeamId,
      };
    },
  );

  if (currentTeamId) {
    yield call(
      sendRequest,
      PayActions.fetchEscrowBalanceList(currentTeamId, paymentMethods),
      { dispatchRequestAction: true },
    );
  }
}

function* createAppByNewCard(action: Action<IExtendAppByNewCardPayload>) {
  const { currentTeamId, justCreatedCardId } = yield select(
    (state: { team: ITeamState; pay: IPayState }) => {
      return {
        justCreatedCardId: state.pay.justCreatedCardId,
        currentTeamId: state.team.currentTeamId ?? '',
      };
    },
  );

  yield put(
    PayActions.extendApp({
      teamId: currentTeamId,
      paymentMethod: PaymentMethod.CREDIT_CARD,
      appId: action.payload.appId,
      cardId: justCreatedCardId,
      period: action.payload.period,
    }),
  );
}

function* onFetchPaymentMethodsSuccess() {
  const { currentTeamId, paymentMethods, checkPriceParam } = yield select(
    (state: { team: ITeamState; pay: IPayState; apps: IAppsState }) => {
      return {
        currentTeamId: state.team.currentTeamId,
        paymentMethods: state.pay.paymentMethods,
        checkPriceParam: state.apps?.checkPriceParam,
      };
    },
  );

  if (currentTeamId) {
    yield call(
      sendRequest,
      PayActions.fetchEscrowList(currentTeamId, paymentMethods),
      { dispatchRequestAction: true },
    );

    yield call(
      sendRequest,
      PayActions.fetchEscrowBalanceList(currentTeamId, paymentMethods),
      { dispatchRequestAction: true },
    );

    if (checkPriceParam) {
      yield call(
        sendRequest,
        PayActions.checkPaymentMethodsList(currentTeamId, paymentMethods, [
          checkPriceParam,
        ]),
        { dispatchRequestAction: true },
      );
    }
  }
}

function* onSetupPayMethodSuccess(action: RequestAction) {
  try {
    const paymentName = action.meta?.requestAction.request.meta.paymentName;
    const { currentTeamId } = yield select(
      (state: { team: ITeamState; pay: IPayState }) => {
        return {
          currentTeamId: state.team.currentTeamId,
        };
      },
    );
    yield put(PayActions.fetchPaymentMethod(currentTeamId, paymentName));
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* onSelectPayMethodSuccess(action: RequestAction) {
  const paymentName = action.meta?.requestAction.meta.paymentName;

  yield put(PayActions.checkPriceManual());

  const {
    currentTeamId = '',
    checkPriceParam,
    paymentMethods,
  }: {
    currentTeamId?: string;
    paymentMethods: IPaymentMethod[];
    checkPriceParam: IApiCheckSelectableParams;
  } = yield select(
    (state: { team: ITeamState; pay: IPayState; apps: IAppsState }) => {
      return {
        currentTeamId: state.team.currentTeamId,
        paymentMethods: state.pay.paymentMethods,
        checkPriceParam: state.apps?.checkPriceParam,
      };
    },
  );

  yield call(
    sendRequest,
    PayActions.checkPaymentMethodsList(
      currentTeamId,
      paymentMethods.filter(item => item.name !== paymentName),
      [checkPriceParam],
    ),
    { dispatchRequestAction: true },
  );
}

function* onCheckPrice() {
  try {
    const { teamId, checkPriceParam } = yield select(
      (state: { team: ITeamState; apps: IAppsState }) => {
        return {
          teamId: state.team.currentTeamId,
          checkPriceParam: state.apps?.checkPriceParam,
        };
      },
    );

    if (checkPriceParam) {
      yield put(
        PayActions.checkPrice({
          team_id: teamId,
          cpu: checkPriceParam.cpu,
          memory: checkPriceParam.memory,
          storage: checkPriceParam.storage,
          chart_name: checkPriceParam.chart_name,
          cluster_id: checkPriceParam.cluster_id,
        }),
      );
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* onCheckPaymentMethodByStoreParams(
  action: Action<{
    teamId: string;
    payName: PaymentMethod;
    needSelect?: boolean;
  }>,
) {
  try {
    const { checkPriceParam } = yield select((state: { apps: IAppsState }) => {
      return {
        checkPriceParam: state.apps.checkPriceParam,
      };
    });
    yield put(
      PayActions.checkPaymentMethod(
        action.payload.teamId,
        action.payload.payName,
        [checkPriceParam],
        !!action.payload.needSelect,
      ),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* onCheckPaymentSuccess(action: RequestAction) {
  const request = action.meta?.requestAction.request;
  const { teamId, paymentMethods, paymentMethodAvailability } = yield select(
    (state: { team: ITeamState; pay: IPayState }) => {
      return {
        teamId: state.team.currentTeamId,
        paymentMethods: state.pay.paymentMethods,
        paymentMethodAvailability: getPaymentMethodAvailability(
          state.pay.paymentMethodsAvailability,
          state.pay.candidatePaymentMethod,
        )?.data,
      };
    },
  );
  const payName = request.payName;
  const currentPayMethod = paymentMethods.find(
    (item: IPaymentMethod) => item.name === payName,
  );
  if (currentPayMethod) {
    yield put(PayActions.fetchEscrowBalanceList(teamId, [currentPayMethod]));
    yield put(PayActions.fetchEscrowList(teamId, [currentPayMethod]));
  }

  if (
    request.needSelect &&
    currentPayMethod &&
    !currentPayMethod.selected &&
    paymentMethodAvailability?.selectable
  ) {
    yield put(PayActions.selectPaymentMethod(teamId, payName));
  }
}

function* onCalculatePriceSuccess(action: RequestAction) {
  try {
    const request = action.meta?.requestAction.request;
    const {
      teamId,
      paymentMethods,
      currentPaymentMethod,
      cpu,
      memory,
      storage,
      chartName,
      clusterId,
    } = yield select((state: { team: ITeamState; pay: IPayState }) => {
      return {
        teamId: state.team.currentTeamId,
        paymentMethods: state.pay.paymentMethods,
        currentPaymentMethod: state.pay.currentPaymentMethod,
        cpu: request.params['resource.cpu'],
        memory: request.params['resource.memory'],
        storage: request.params['resource.storage'],
        chartName: request.params.node_kind,
        clusterId: request?.clusterId,
      };
    });
    if (clusterId) {
      yield put(
        PayActions.checkPrice({
          team_id: teamId,
          cpu: cpu,
          memory: memory,
          storage: storage,
          chart_name: chartName,
          cluster_id: clusterId,
        }),
      );

      yield put(
        PayActions.checkPaymentMethodsList(
          teamId,
          paymentMethods.filter(
            (item: IChartDetails) => item.name !== currentPaymentMethod,
          ),
          [
            {
              cluster_id: clusterId,
              cpu: cpu,
              memory: memory,
              storage: storage,
              chart_name: chartName,
            },
          ],
        ),
      );
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* paySaga() {
  yield takeEvery(
    PayActionTypes.FETCH_PAYMENT_METHOD_SUCCESS,
    onFetchPaymentMethodSuccess,
  );

  yield takeEvery(
    PayActionTypes.FETCH_PAYMENT_METHODS_SUCCESS,
    onFetchPaymentMethodsSuccess,
  );
  yield takeEvery(
    PayActionTypes.SETUP_PAYMENT_METHOD_SUCCESS,
    onSetupPayMethodSuccess,
  );
  yield takeEvery(
    PayActionTypes.SELECT_PAYMENT_METHOD_SUCCESS,
    onSelectPayMethodSuccess,
  );
  yield takeEvery(PayActionTypes.CHECK_PRICE_MANUAL, onCheckPrice);
  yield takeEvery(
    PayActionTypes.CHECK_PAYMENT_METHOD_BY_STORE_PARAMS,
    onCheckPaymentMethodByStoreParams,
  );
  yield takeEvery(
    PayActionTypes.CHECK_PAYMENT_METHOD_SUCCESS,
    onCheckPaymentSuccess,
  );
  yield takeEvery(
    AppsActionTypes.CALCULATE_APP_PRICE_SUCCESS,
    onCalculatePriceSuccess,
  );
  yield takeEvery(AppsActionTypes.APP_CREATE_SUCCESS, fetchBalances);
  yield takeEvery(PayActionTypes.EXTEND_APP_BY_NEW_CARD, createAppByNewCard);
}

export { paySaga };
