/* eslint-disable @typescript-eslint/camelcase */
import {
  IExtendedMultiRequestsAction,
  IExtendedRequestAction,
} from 'common/types/requestTypes';
import {
  IChangeTeamNameFormValues,
  ICreateTeamWithMemberPayload,
  ITeamFormValues,
  TeamRole,
} from '../teamTypes';
import { createAction } from 'common/utils/reduxUtils';

const TeamActionTypes = {
  FETCH_TEAMS: 'FETCH_TEAMS',
  FETCH_TEAMS_SUCCESS: 'FETCH_TEAMS_SUCCESS',
  FETCH_TEAMS_ERROR: 'FETCH_TEAMS_ERROR',
  FETCH_TEAMS_RESET: 'FETCH_TEAMS_RESET',

  FETCH_ALL_TEAMS_DETAIL: 'FETCH_ALL_TEAMS_DETAIL',
  FETCH_ALL_TEAMS_DETAIL_SUCCESS: 'FETCH_ALL_TEAMS_DETAIL_SUCCESS',
  FETCH_ALL_TEAMS_DETAIL_ERROR: 'FETCH_ALL_TEAM_DETAIL_ERROR',

  ASSIGN_TEAM: 'ASSIGN_TEAM',
  ASSIGN_TEAM_SUCCESS: 'ASSIGN_TEAM_SUCCESS',
  ASSIGN_TEAM_RESET: 'ASSIGN_TEAM_RESET',

  TEAM_CREATE: 'TEAM_CREATE',
  TEAM_CREATE_SUCCESS: 'TEAM_CREATE_SUCCESS',
  TEAM_CREATE_RESET: 'TEAM_CREATE_RESET',

  TEAM_DETAIL_FETCH: 'TEAM_DETAIL_FETCH',

  TEAM_ROLES_FETCH: 'TEAM_ROLES_FETCH',

  INVITE_TEAM_MEMBER: 'INVITE_TEAM_MEMBER',
  INVITE_TEAM_MEMBER_SUCCESS: 'INVITE_TEAM_MEMBER_SUCCESS',
  INVITE_TEAM_MEMBER_RESET: 'INVITE_TEAM_MEMBER_RESET',

  UPDATE_TEAM_MEMBER: 'UPDATE_TEAM_MEMBER',
  UPDATE_TEAM_MEMBER_RESET: 'UPDATE_TEAM_MEMBER_RESET',

  DELETE_TEAM_MEMBER: 'DELETE_TEAM_MEMBER',
  DELETE_TEAM_MEMBER_RESET: 'DELETE_TEAM_MEMBER_RESET',

  CONFIRM_TEAM_MEMBER: 'CONFIRM_TEAM_MEMBER',
  CONFIRM_TEAM_MEMBER_SUCCESS: 'CONFIRM_TEAM_MEMBER_SUCCESS',
  CONFIRM_TEAM_MEMBER_RESET: 'CONFIRM_TEAM_MEMBER_RESET',

  SET_CURRENT_TEAM: 'SET_CURRENT_TEAM',

  CLOSE_TEAM: 'CLOSE_TEAM',
  CLOSE_TEAM_SUCCESS: 'CLOSE_TEAM_SUCCESS',
  CLOSE_TEAM_ERROR: 'CLOSE_TEAM_ERROR',
  CLOSE_TEAM_RESET: 'CLOSE_TEAM_RESET',

  CHANGE_TEAM_NAME: 'CHANGE_TEAM_NAME',
  CHANGE_TEAM_NAME_RESET: 'CHANGE_TEAM_NAME_RESET',

  RESEND_INVITATION_EMAIL: 'RESEND_INVITATION_EMAIL',
  RESEND_INVITATION_EMAIL_RESET: 'RESEND_INVITATION_EMAIL_RESET',

  CREATE_TEAM_WITH_MEMBERS: 'CREATE_TEAM_WITH_MEMBERS',
};

const TeamActions = {
  fetchTeams: (): IExtendedRequestAction => ({
    type: TeamActionTypes.FETCH_TEAMS,
    request: {
      url: '/team/v1alpha/teams',
      method: 'GET',
    },
  }),
  resetFetchTeamsStatus: () => createAction(TeamActionTypes.FETCH_TEAMS_RESET),
  assignTeam: (
    teamId: string,
    redirectOnSuccess?: string,
  ): IExtendedRequestAction => ({
    type: TeamActionTypes.ASSIGN_TEAM,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/cluster/v2/cluster`,
      method: 'POST',
      data: {
        team_id: teamId,
      },
    },
    meta: {
      redirectOnSuccess,
    },
  }),
  resetAssignTeamStatus: () => createAction(TeamActionTypes.ASSIGN_TEAM_RESET),
  createTeam: (values: ITeamFormValues): IExtendedRequestAction => ({
    type: TeamActionTypes.TEAM_CREATE,
    request: {
      url: '/team/v1alpha/team',
      method: 'POST',
      data: {
        team_name: values.teamName,
      },
    },
  }),
  resetCreateTeamStatus: () => createAction(TeamActionTypes.TEAM_CREATE_RESET),
  changeTeamName: (
    values: IChangeTeamNameFormValues,
  ): IExtendedRequestAction => ({
    type: TeamActionTypes.CHANGE_TEAM_NAME,
    request: {
      url: `/team/v1alpha/teams/${values.teamId}/name`,
      method: 'PUT',
      data: {
        team_name: values.teamName,
      },
    },
  }),
  resetChangeTeamNameStatus: () =>
    createAction(TeamActionTypes.CHANGE_TEAM_NAME_RESET),
  fetchTeamDetail: (teamId: string): IExtendedRequestAction => ({
    type: TeamActionTypes.TEAM_DETAIL_FETCH,
    request: {
      url: `/team/v1alpha/teams/${teamId}/members`,
      method: 'GET',
      // @ts-ignore to fix in the future
      meta: {
        teamId,
      },
    },
  }),
  // TODO: looks like a previous one
  fetchAllTeamsDetail: (teamIds: string[]): IExtendedMultiRequestsAction => ({
    type: TeamActionTypes.FETCH_ALL_TEAMS_DETAIL,
    request: teamIds.map((teamId: string) => ({
      url: `/team/v1alpha/teams/${teamId}/members`,
      method: 'GET',
      // @ts-ignore to fix in the future
      meta: {
        teamId,
      },
    })),
  }),
  setCurrentTeam: (teamId: string) =>
    createAction(TeamActionTypes.SET_CURRENT_TEAM, {
      teamId,
    }),
  inviteTeamMember: (
    emails: string[],
    teamId: string,
    role_ids: TeamRole[],
  ): IExtendedRequestAction => ({
    type: TeamActionTypes.INVITE_TEAM_MEMBER,
    request: {
      url: `/team/v1alpha/teams/${teamId}/members:invite`,
      method: 'POST',
      data: {
        emails,
        role_ids,
      },
    },
  }),
  resetInviteTeamMemberStatus: () =>
    createAction(TeamActionTypes.INVITE_TEAM_MEMBER_RESET),
  closeTeam: (teamId: string): IExtendedRequestAction => ({
    type: TeamActionTypes.CLOSE_TEAM,
    request: {
      url: `/team/v1alpha/teams/${teamId}`,
      method: 'DELETE',
      // @ts-ignore to fix in the future
      meta: {
        teamId,
      },
    },
  }),
  resetCloseTeamStatus: () => createAction(TeamActionTypes.CLOSE_TEAM_RESET),
  updateTeamMember: (
    memberId: string,
    name: string,
    teamId: string,
    roleIds: TeamRole[],
  ): IExtendedRequestAction => ({
    type: TeamActionTypes.UPDATE_TEAM_MEMBER,
    request: {
      url: `/team/v1alpha/teams/${teamId}/members/${memberId}`,
      method: 'PATCH',
      data: {
        role_ids: roleIds,
        member_name: name,
      },
    },
  }),
  resetUpdateTeamMemberStatus: () =>
    createAction(TeamActionTypes.UPDATE_TEAM_MEMBER_RESET),
  deleteTeamMember: (teamId: string, uid: string): IExtendedRequestAction => ({
    type: TeamActionTypes.DELETE_TEAM_MEMBER,
    request: {
      url: `/team/v1alpha/teams/${teamId}/members/${uid}`,
      method: 'DELETE',
    },
  }),
  resetDeleteTeamMemberStatus: () =>
    createAction(TeamActionTypes.DELETE_TEAM_MEMBER_RESET),
  confirmTeamMember: ({
    teamId,
    memberId,
    code,
  }: {
    teamId: string;
    memberId: string;
    code: string;
  }): IExtendedRequestAction => ({
    type: TeamActionTypes.CONFIRM_TEAM_MEMBER,
    request: {
      url: `/team/v1alpha/teams/${teamId}/members/${memberId}:confirm`,
      method: 'POST',
      data: {
        code,
      },
    },
    meta: {
      id: teamId,
    },
  }),
  resetConfirmTeamMember: () =>
    createAction(TeamActionTypes.CONFIRM_TEAM_MEMBER_RESET),
  fetchTeamRoles: (teamId: string): IExtendedRequestAction => ({
    type: TeamActionTypes.TEAM_ROLES_FETCH,
    request: {
      url: `/team/v1alpha/teams/${teamId}/roles`,
      method: 'GET',
    },
  }),
  resendInvitationEmail: (
    teamId: string,
    memberId: string,
  ): IExtendedRequestAction => ({
    type: TeamActionTypes.RESEND_INVITATION_EMAIL,
    request: {
      url: `/team/v1alpha/teams/${teamId}/members/${memberId}:reInvite`,
      method: 'POST',
    },
  }),
  resetResendInvitationEmailStatus: () =>
    createAction(TeamActionTypes.RESEND_INVITATION_EMAIL_RESET),

  createTeamWithMembers: (
    payload: ICreateTeamWithMemberPayload,
    redirectOnSuccess?: string,
  ) => ({
    type: TeamActionTypes.CREATE_TEAM_WITH_MEMBERS,
    payload: {
      ...payload,
      redirectOnSuccess,
    },
  }),
};

export { TeamActionTypes, TeamActions };
