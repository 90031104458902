import * as Sentry from '@sentry/browser';
import BigNumber from 'bignumber.js';

import {
  APIAuthenticationType,
  APIOrderStatus,
  APIType,
  IAPIMarketAPIResponse,
  IAPIMarketInstance,
  IAPIMarketInstanceStats,
  IAPIMarketInstanceStatsResponse,
  IAPIMarketItem,
  IAPIMarketPlan,
  IAPIMarketPlansResponse,
  IAPIMarketResponse,
  IAPIOrder,
  IAPIOrderResponse,
} from './APIMarketTypes';

export const mapInfuraStats = (
  response: IAPIMarketInstanceStatsResponse,
): IAPIMarketInstanceStats[] => {
  try {
    return response.data.map(item => ({
      key: item.key,
      date: new Date(item.date),
      value: item.value,
    }));
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export const mapAPIMarketList = (
  response: IAPIMarketResponse,
): IAPIMarketItem[] => {
  try {
    return response.data
      .filter(item => {
        return item.id !== '21';
      })
      .map(item => {
        if (item.node_family === 'Matic') {
          return {
            id: item.id,
            code: item.code,
            name: 'Polygon',
            network: item.network,
            nodeType: 'Polygon',
            iconUrl: 'https://assets.ankr.com/charts/icon-only/polygon.png',
            iconOnlyUrl: 'https://assets.ankr.com/charts/icon-only/polygon.png',
            nodeName: item.name,
            price: new BigNumber(item.price),
          };
        }
        return {
          id: item.id,
          code: item.code,
          name: item.name,
          network: item.network,
          nodeType: item.node_family,
          iconUrl: item.icon_url,
          iconOnlyUrl: item.icon_only_url,
          nodeName: item.name,
          price: new BigNumber(item.price),
        };
      });
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export const mapAPIList = (
  response: IAPIMarketAPIResponse,
): IAPIMarketInstance[] => {
  try {
    return response.data
      .sort((a, b) => +new Date(b.create_time) - +new Date(a.create_time))
      .map(item => {
        const result: IAPIMarketInstance = {
          id: item.id,
          code: item.node_code,
          network: item.network,
          nodeType: item.node_type,
          nodeName: item.node_name,
          projectName: item.project_name,
          totalRequests: item.total_requests,
          todaysRequests: item.today_request,
          maximumLimit: parseInt(item.maximum_limit, 10),
          endpoint: item.endpoint,
          wsEndpoint: item.ws_endpoint,
          iconUrl: item.iconUrl,
          iconOnlyUrl: item.iconOnlyUrl,
          nodeCode: item.node_code,
          plan: item.plan,
          authUsername: item.auth_username,
          authPassword: item.auth_password,
          isActive: item.status === 'api_status_active',
          orderId: item.order_id,
          createTime: item.create_time,
        };

        return result;
      });
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export const mapAPIPlansList = (
  response: IAPIMarketPlansResponse,
): IAPIMarketPlan[] => {
  try {
    return response.data
      .filter(item => !item.type?.includes('test'))
      .map(item => ({
        id: item.id,
        type: item.type,
        limitDaily: new BigNumber(item.max_req_daily),
        limitMonthly: new BigNumber(item.max_req_mo),
        limitSecondly: new BigNumber(item.ratet_limit_sec),
        price: new BigNumber(item.price),
        nodeCodes: item.node_codes,
        dateCreated: new Date(item.create_time),
        support: item.support,
      }));
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export const mapAPIOrder = (item: IAPIOrderResponse): IAPIOrder | undefined => {
  try {
    return {
      id: item.order_id,
      apiType: item.api_type as APIType,
      planId: item.plan_id,
      duration: +item.plan_duration_month,
      price: new BigNumber(item.server_price),
      authType: item.auth_type as APIAuthenticationType,
      username: item.auth_username,
      password: item.auth_password,
      token: item.auth_token,
      dateCreated: new Date(item.create_time),
      status: item.order_status as APIOrderStatus,
    };
  } catch (error) {
    Sentry.captureException(error);
    return undefined;
  }
};
