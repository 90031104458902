import {
  IApiPayMethod,
  IPaymentMethod,
  IApiPayMethodListResponse,
  IApiCheckPayMethodSelectableResponse,
  IPaymentMethodAvailabilityData,
  IApiEscrow,
  IEscrow,
  IApiWithdrawal,
  IWithdrawal,
  PayStatus,
  IApiCard,
  ICard,
} from '../types/payTypes';
import * as Sentry from '@sentry/browser';
import { differenceInHours } from 'date-fns';

export const normalizePayMethod = (data: IApiPayMethod): IPaymentMethod => {
  const createTime = new Date(data.create_time);
  const distance = differenceInHours(createTime, new Date());
  const status = data.status;
  const needRetry = status === PayStatus.PENDING && distance <= -1;
  return {
    name: data.name,
    type: data.type,
    currency: data.currency,
    selected: data.selected,
    createTime: createTime,
    updateTime: new Date(data.update_time),
    status: data.status,
    needRetry: needRetry,
  };
};

export const normalizePayMethodList = (
  data: IApiPayMethodListResponse,
): IPaymentMethod[] => {
  try {
    return data.methods.map((item: IApiPayMethod) => normalizePayMethod(item));
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export const normalizePaymentMethodAvailability = (
  data: IApiCheckPayMethodSelectableResponse,
): IPaymentMethodAvailabilityData | undefined => {
  try {
    return {
      selectable: data.selectable,
      demand: data.demand,
      total: data.total,
      availableBalance: data.available_balance,
    };
  } catch (error) {
    Sentry.captureException(error);
    return undefined;
  }
};

export const normalizeCards = (data: IApiCard[]): ICard[] => {
  return data.map((item: IApiCard) => {
    return {
      id: item.id,
      brand: item.brand,
      last4: item.last4,
      expYear: item.exp_year,
      expMonth: item.exp_month,
      enabled: item.enabled,
      status: item.status,
      createTime: item.create_time,
      updateTime: item.update_time,
    };
  });
};

export const normalizeEscrow = (data: IApiEscrow): IEscrow => {
  return {
    addr: data.addr,
    type: data.type,
    createTime: new Date(data.create_time),
    updateTime: new Date(data.update_time),
  };
};

export const normalizeEscrowWithdrawal = (
  data: IApiWithdrawal,
): IWithdrawal => {
  return {
    id: data.id,
    txHash: data.tx_hash,
    teamId: data.team_id,
    type: data.type,
    currency: data.currency,
    from: data.from,
    to: data.to,
    amount: data.amount,
    createTime: new Date(data.create_time),
    updateTime: new Date(data.update_time),
    status: data.status,
  };
};

export const normalizeEscrowWithdrawalList = (
  list: IApiWithdrawal[],
): IWithdrawal[] => {
  try {
    return list.map((item: IApiWithdrawal) => normalizeEscrowWithdrawal(item));
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};
