/* eslint-disable @typescript-eslint/camelcase */
import { createAction } from 'common/utils/reduxUtils';
import queryString from 'query-string';

const TokenActionTypes = {
  SET_TOKENS: 'SET_TOKENS',

  REFRESH_TOKEN: 'REFRESH_TOKEN',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_ERROR: 'REFRESH_TOKEN_ERROR',

  SSO_CHECK: 'SSO_CHECK',
  SSO_CHECK_ERROR: 'SSO_CHECK_ERROR',
  SSO_CHECK_SUCCESS: 'SSO_CHECK_SUCCESS',

  SET_EXTERNAL_TOKEN: 'SET_EXTERNAL_TOKEN',
};

const TokenActions = {
  setTokens: ({
    refreshToken,
    accessToken,
  }: {
    refreshToken?: string;
    accessToken: string;
  }) =>
    createAction(TokenActionTypes.SET_TOKENS, { refreshToken, accessToken }),
  setExternalToken: ({ accessToken }: { accessToken: string }) =>
    createAction(TokenActionTypes.SET_EXTERNAL_TOKEN, { accessToken }),
  refreshToken: (refreshToken: string) => ({
    type: TokenActionTypes.REFRESH_TOKEN,
    request: {
      url: '/oauth2/token',
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: queryString.stringify({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      }),
    },
    meta: {
      noAuth: true,
    },
  }),
  checkSSOToken: ({ token }: { token: string }) => ({
    type: TokenActionTypes.SSO_CHECK,
    request: {
      url: process.env.REACT_APP_SSO_BASE + '/token/check',
      method: 'GET',
      params: {
        access_token: token,
      },
    },
    meta: {
      noAuth: true,
    },
  }),
};

export { TokenActionTypes, TokenActions };
