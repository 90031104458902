import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useCodeFieldStyles = makeStyles<Theme>(theme => ({
  component: {
    position: 'relative',

    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    height: 44,
    padding: theme.spacing(1.5, 1.75),

    boxSizing: 'border-box',

    fontSize: 16,
    lineHeight: 1.3,
    fontWeight: 700,

    borderRadius: 4,
    border: `1px solid ${theme.palette.grey[200]}`,
  },

  code: {
    flexGrow: 1,

    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  copy: {
    flexShrink: 0,

    width: 24,
    height: 24,
    marginLeft: theme.spacing(1.5),

    '& svg': {
      width: '100%',
      height: 'auto',
    },
  },
}));
