import * as Sentry from '@sentry/browser';
import { parseBoincCheckStatus } from './Hooks/parseBoinc';
import { IHookDataItem } from '../reducers/appsReducer';
import { requestSuccessful } from '../../../utils/requestStatus';

type HookParser = (data: string) => any;
type HookParsers = Array<HookParser> | HookParser;
const HOOK_PARSERS: { [key: string]: HookParsers } = {
  'boinc.apphook1=check-status': parseBoincCheckStatus,
};

export const normalizeHook = ({
  data,
  hookId,
  container,
}: {
  data?: { result?: string }; // TODO: This can be a string or number, obvisouly
  hookId: string;
  container: string;
}): IHookDataItem<any> => {
  const result: string = isBase64(data?.result || data + '')
    ? isJSON(window.atob(data?.result || data + ''))
      ? JSON.parse(window.atob(data?.result || data + ''))
      : window.atob(data?.result || data + '')
    : data?.result || data;

  if (!result && typeof data === 'object') {
    return { data: { result: result }, status: requestSuccessful() };
  }

  const parsers = HOOK_PARSERS[hookId];
  let meta;

  try {
    if (parsers) {
      if (Array.isArray(parsers)) {
        meta = parsers.map(parser => parser(result));
      } else {
        meta = parsers(result);
      }
    } else {
      return { data: result, status: requestSuccessful() };
    }
  } catch (e) {}
  try {
    return {
      data: result,
      meta,
      status: requestSuccessful(),
    };
  } catch (error) {
    Sentry.captureException(error);
    return { data: '', meta: {}, status: requestSuccessful() };
  }
};

export const isBase64 = (str?: string): boolean => {
  if (!str) {
    return false;
  }

  if (str === '' || (typeof str === 'string' && str.trim() === '')) {
    return false;
  }

  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
};

export const isJSON = (str: string): boolean => {
  try {
    if (typeof JSON.parse(str) === 'object') {
      return true;
    }
  } catch (e) {}
  return false;
};
