import { mergeDeep } from 'common/utils/object';
import localeUS from './en-US.json';
import localeUSCountries from 'common/locales/en-US-countries.json';
import localeUSTimezones from 'common/locales/en-US-timezones.json';
import commonUSLocale from 'common/locales/en-US.json';
import authUsLocale from 'auth/locales/en-US.json';
import apiMarketLocale from 'modules/APIMarketUI/locales/en-US.json';

export type LocaleName = 'en-US';

export const LOCALES_DATA = {
  'en-US': mergeDeep(
    commonUSLocale,
    localeUS,
    authUsLocale,
    localeUSCountries,
    localeUSTimezones,
    apiMarketLocale,
  ),
};
