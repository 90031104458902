import { useMediaQuery, useTheme } from '@material-ui/core';
import { BREAKPOINT_XXS } from 'common/core/const';

export const useIsXXSDown = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(BREAKPOINT_XXS));
};

export const useIsXSDown = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('xs'));
};

export const useIsSMDown = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
};

export const useIsMDDown = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('md'));
};

export const useIsLGDown = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('lg'));
};
