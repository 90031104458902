import { ActionPattern, call, put, takeEvery } from 'redux-saga/effects';
import { UserActionTypes } from 'auth/store/actions/UserActions';
import { SnackbarsActions } from 'common/store/actions/SnackbarsActions';
import { t } from 'common/utils/intl';
import { AppsActions, AppsActionTypes } from '../actions/AppsActions';
import { fetchHookDataSaga } from './fetchHookDataSaga';
import { TeamActionTypes } from '../../teams/actions/TeamActions';
import { RequestAction, sendRequest } from 'redux-saga-requests';
import { PayActionTypes } from '../../billing/actions/PayActions';

function* showModifyInfoSuccessSaga() {
  yield put(
    SnackbarsActions.showSnackbar(t('user.avatar-modified-successfully'), {
      key: 'SET_AVATAR_SUCCESS',
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    }),
  );
}

function* notifyModifyInfoSuccessSaga() {
  yield takeEvery(
    UserActionTypes.SET_AVATAR_SUCCESS,
    showModifyInfoSuccessSaga,
  );
  yield takeEvery(
    UserActionTypes.UPDATE_USERNAME_SUCCESS,
    showModifyInfoSuccessSaga,
  );
}

function* runUpdateTeamNotificationSaga() {
  // yield put(
  //   NotificationsActions.get({
  //     type: 'team',
  //   }),
  // );
}
function* updateTeamNotificationSaga() {
  yield takeEvery(
    UserActionTypes.SIGNIN_SUCCESS,
    runUpdateTeamNotificationSaga,
  );
  yield takeEvery(
    TeamActionTypes.TEAM_CREATE_SUCCESS,
    runUpdateTeamNotificationSaga,
  );
  yield takeEvery(
    TeamActionTypes.INVITE_TEAM_MEMBER_SUCCESS,
    runUpdateTeamNotificationSaga,
  );
  yield takeEvery(
    TeamActionTypes.INVITE_TEAM_MEMBER_SUCCESS,
    runUpdateTeamNotificationSaga,
  );
}

export function* appRequestsSaga() {
  yield takeEvery(
    AppsActionTypes.APP_HOOK_DATA_FETCH as ActionPattern<any>,
    fetchHookDataSaga,
  );
}

function* runFetchAddressInfoSaga(action: RequestAction) {
  const { teamId = '', id: appId = '' } = action.meta as any;

  yield call(sendRequest, AppsActions.fetchAppAddressInfo({ teamId, appId }));
}

export function* fetchAddressInfoSaga() {
  yield takeEvery(PayActionTypes.EXTEND_APP_SUCCESS, runFetchAddressInfoSaga);

  yield takeEvery(
    AppsActionTypes.APP_CANCEL_EXTEND_SUCCESS,
    runFetchAddressInfoSaga,
  );
}
export { notifyModifyInfoSuccessSaga, updateTeamNotificationSaga };
