import React from 'react';
import { withErrorHandler } from 'common-web/HOC/withErrorHandler';
import { Route, Switch } from 'react-router';
import { PrivateRoute } from 'common-web/nav/PrivateRoute';
import { t } from 'common/utils/intl';
import { LoadableNotFoundPaper } from '../../nav/CommonLoadables';
import {
  API_MARKET_CREATE_PATH,
  API_MARKET_DETAILS_PATHS,
  API_MARKET_LIST_PATH,
  API_MARKET_PATH,
  API_MARKET_PLANS,
} from './APIMarketConsts';
import loadable, { LoadableComponent } from '@loadable/component';
import { LoadableScreen } from 'common-web/components/LoadableScreen';
import { PublicRouteWithBreadcrumbs } from 'common-web/nav/PublicRouteWithBreadcrumbs';
import { withApiLayout } from '../../components/Layout';

const LoadableNodesContainer = withApiLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/APIMarketUI/screens/Nodes').then(
          module => module.Nodes,
        ),
      {
        fallback: <LoadableScreen />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadableAPIMarketInstanceListContainer = withApiLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/APIMarketUI/screens/APIMarketRoot').then(
          module => module.APIMarketRoot,
        ),
      {
        fallback: <LoadableScreen />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadableAPIDetailsContainer = withApiLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/APIMarketUI/screens/APIDetails').then(
          module => module.APIDetails,
        ),
      {
        fallback: <LoadableScreen />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadableAPICreateContainer = withApiLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/APIMarketUI/screens/APICreate').then(
          module => module.APICreate,
        ),
      {
        fallback: <LoadableScreen />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadableAPIPlansContainer = withApiLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/APIMarketUI/screens/APIPlans').then(
          module => module.APIPlans,
        ),
      {
        fallback: <LoadableScreen />,
      },
    ) as LoadableComponent<any>,
  ),
);

export const APIMarketRoutes = ({ isSignedIn }: { isSignedIn: boolean }) => {
  return (
    <Switch>
      <PublicRouteWithBreadcrumbs
        exact={true}
        path={API_MARKET_PATH}
        component={LoadableNodesContainer}
        breadcrumbs={[
          { title: t('api-market.tabs.list'), link: API_MARKET_LIST_PATH },
          t('api-market.navigation.create'),
        ]}
      />
      <PrivateRoute
        isSignedIn={isSignedIn}
        exact={true}
        path={API_MARKET_LIST_PATH}
        component={LoadableAPIMarketInstanceListContainer}
        breadcrumbs={[t('api-market.tabs.list')]}
      />
      <PrivateRoute
        isSignedIn={isSignedIn}
        exact={true}
        path={API_MARKET_DETAILS_PATHS}
        component={LoadableAPIDetailsContainer}
        breadcrumbs={[
          { title: t('api-market.tabs.list'), link: API_MARKET_LIST_PATH },
          t('api-market.navigation.details'),
        ]}
      />
      <PrivateRoute
        isSignedIn={isSignedIn}
        exact={true}
        path={`${API_MARKET_CREATE_PATH}/:node?`}
        component={LoadableAPICreateContainer}
        breadcrumbs={[
          { title: t('api-market.tabs.list'), link: API_MARKET_LIST_PATH },
          t('api-market.navigation.create'),
        ]}
      />
      <PrivateRoute
        isSignedIn={isSignedIn}
        exact={true}
        path={API_MARKET_PLANS}
        component={LoadableAPIPlansContainer}
        breadcrumbs={[
          { title: t('api-market.tabs.list'), link: API_MARKET_LIST_PATH },
          t('navigation.upgrade-plan'),
        ]}
      />
      <Route
        isSignedIn={isSignedIn}
        component={LoadableNotFoundPaper}
        breadcrumbs={[t('navigation.404')]}
      />
    </Switch>
  );
};
