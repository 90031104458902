import { AnyAction } from 'redux';
import { UserActionTypes } from 'auth/store/actions/UserActions';
import {
  AppsActions,
  AppsActionTypes,
} from 'common/modules/apps/actions/AppsActions';
import { put, race, select, take, takeEvery } from 'redux-saga/effects';
import { clearRequestsCache } from 'redux-saga-requests';
import { delayedRedirect } from 'common/store/effects/utilitySagas';
import { push, replace } from 'connected-react-router';
import { UtilityActionTypes } from 'common/store/actions/UtilityActions';
import { IApiStoreState } from 'store/reducers';
import {
  getCurrentTeam,
  isEnterpriseModeAvailable,
  isPersonalModeAvailable,
} from 'common/modules/teams/reducers/teamReducer';
import { TeamActionTypes } from 'common/modules/teams/actions/TeamActions';
import * as Sentry from '@sentry/browser';
import { historyInstance } from 'common/utils/historyInstance';
import { parse } from 'query-string';
import { setPathEffect } from 'common/modules/Redirect/effects/setPathEffect';
import { RedirectActions } from 'common/modules/Redirect/actions/RedirectActions';
import { IExtendedAxiosResponse } from 'common/types';
import { IApiLoginResponse } from 'auth/store/mappings/userMappings';
import {
  API_MARKET_LIST_PATH,
  API_MARKET_PATH,
} from 'modules/APIMarketUI/APIMarketConsts';
import {
  EXPIRED_PATH,
  LOGIN_PATH,
  SIGN_UP_PATH,
  SIGN_UP_SUCCESS_PATH,
} from 'auth-web/core/const';

function* onSignInSaga({
  meta: { type = 'user' },
  response,
}: IExtendedAxiosResponse<IApiLoginResponse>) {
  // 2FA: Request for code:
  if (response.status === 202) {
    return;
  }
  // not the best place, but will left it here.
  yield put(clearRequestsCache());

  const params = parse(historyInstance.location.search);
  if (typeof params.resource === 'string' && params.resource) {
    const isResourceAuthPage =
      params.resource === LOGIN_PATH || params.resource === SIGN_UP_PATH;

    if (!isResourceAuthPage) {
      yield delayedRedirect(params.resource);
      return;
    }
  }

  const { resource } = yield select((state: IApiStoreState) => {
    return { resource: state.redirect.resource };
  });

  if (resource) {
    yield delayedRedirect(resource);
    yield put(RedirectActions.resetRedirect());
    return;
  }

  yield race([
    take([
      TeamActionTypes.FETCH_TEAMS_SUCCESS,
      TeamActionTypes.FETCH_TEAMS_ERROR,
    ]),
  ]);

  const {
    teamId,
    isEnterpriseAvailable,
    isPersonalAvailable,
  }: {
    teamId: string;
    isEnterpriseAvailable: boolean;
    isPersonalAvailable: boolean;
  } = yield select((state: IApiStoreState) => {
    return {
      teamId: getCurrentTeam(state.team)?.id,
      isEnterpriseAvailable: isEnterpriseModeAvailable(state.team),
      isPersonalAvailable: isPersonalModeAvailable(state.team),
    };
  });

  if (teamId) {
    try {
      if (isEnterpriseAvailable && isPersonalAvailable) {
        yield delayedRedirect(API_MARKET_LIST_PATH);
        return;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  yield delayedRedirect(API_MARKET_LIST_PATH);
}

function* onSignUpSaga() {
  yield delayedRedirect(SIGN_UP_SUCCESS_PATH);
}

function* onLogoutSaga({ payload }: AnyAction) {
  yield put(replace(payload?.tokenFailed ? EXPIRED_PATH : LOGIN_PATH));
}

function* doRedirect(action: AnyAction) {
  yield put(replace(action.payload.to));
}

function* redirectToApp(action: AnyAction) {
  yield put(push(API_MARKET_PATH));
}

function* resetWallet() {
  yield put(AppsActions.resetWalletInfo());
}

/**
 * We need these redirects to run async after some of the actions
 */
function* setRedirects() {
  yield takeEvery(UserActionTypes.SIGNIN_SUCCESS, onSignInSaga);
  yield takeEvery(AppsActionTypes.APP_CREATE, resetWallet);
  yield takeEvery(AppsActionTypes.APP_CREATE_SUCCESS, redirectToApp);
  yield takeEvery(UserActionTypes.SIGNUP_SUCCESS, setPathEffect);
  yield takeEvery(UserActionTypes.SIGNUP_SUCCESS, onSignUpSaga);
  yield takeEvery(UserActionTypes.USER_REDIRECT_TO_LOGIN, onLogoutSaga);
  yield takeEvery(UtilityActionTypes.REDIRECT, doRedirect);
}

export { setRedirects };
