export const ROOT_PATH = '/';
export const TERMS_PATH = 'https://www.ankr.com/terms';
export const AUTH_PATH = '/auth';
export const API_PATH = '/api';
export const EXPIRED_PATH = `${AUTH_PATH}/expired`;
export const LOGIN_PATH = `${AUTH_PATH}/login`;
export const MODE_PATH = `${AUTH_PATH}/mode`;
export const SIGN_UP_PATH = `${AUTH_PATH}/sign-up`;
export const SIGN_UP_SUCCESS_PATH = `${AUTH_PATH}/sign-up-success`;
export const USER_CONFIRM_REGISTRATION_PATH = '/confirm-register';
export const USER_REGISTRATION_SUCCESS_PATH = '/register-success';
export const CHANGE_EMAIL_PATH = '/change-email';
export const RESET_PASSWORD_PATH = '/reset-password';
export const RESET_PASSWORD_SUCCESS_PATH = `${AUTH_PATH}/reset-password-success`;
export const PASSWORD_RECOVERY_PATH = `${AUTH_PATH}/recover-password`;
export const PASSWORD_RECOVERY_SENT_PATH = `${PASSWORD_RECOVERY_PATH}/sent`;

export const CONTENT_MIN_HEIGHT = 434;
export const FORM_WIDTH = 340;
export const DEFAULT_HEIGHT = 500;
