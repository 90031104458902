import { StyleRules, Theme } from '@material-ui/core/styles';

const loadingEllipsisStyles = (theme: Theme): StyleRules => ({
  '@keyframes lds-ellipsis3': {
    '0%': {
      left: '32px',
      transform: 'scale(0)',
    },
    '50%': {
      left: '32px',
      transform: 'scale(1)',
    },
    '75%': {
      left: '100px',
    },
    '100%': {
      left: '168px',
      transform: 'scale(1)',
    },
  },

  '@keyframes lds-ellipsis2': {
    '0%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(0)',
    },
  },

  '@keyframes lds-ellipsis': {
    '0%': {
      left: 32,
      transform: 'scale(0)',
    },
    '25%': {
      left: 32,
      transform: 'scale(1)',
    },
    '50%': {
      left: 100,
    },
    '75%': {
      left: 168,
      transform: 'scale(1)',
    },
    '100%': {
      left: 168,

      transform: 'scale(0)',
    },
  },
  progress: {
    '&$default': {
      margin: theme.spacing(1),
    },
    width: '100%',
    height: '100%',

    position: 'relative',

    '& > div': {
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      width: 40,
      height: 40,
    },
    '& div > div': {
      width: 40,
      height: 40,
      borderRadius: '50%',
      background: '#f00',
      position: 'absolute',
      top: 100,
      left: 32,
      animation:
        '$lds-ellipsis 0.9s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards',
    },
    '& div:nth-child(1) div': {
      animation:
        '$lds-ellipsis2 0.9s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards',
      background: '#cecece',
    },
    '& div:nth-child(2) div': {
      animationDelay: '-0.45s',
      background: '#9d9d9d',
    },
    '& div:nth-child(3) div': {
      animationDelay: '-0.225s',
      background: '#7a7a7a',
    },
    '& div:nth-child(4) div': {
      animationDelay: '0s',
      background: '#434343',
    },
    '& div:nth-child(5) div': {
      animation:
        '$lds-ellipsis3 0.9s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards',
      background: '#cecece',
    },

    transform:
      'translate(-14.5px, -14.5px) scale(0.145) translate(14.5px, 14.5px)',
  },
  root: {
    overflow: 'hidden',
    '&$default': {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '40px',
    },
    '&$small': {
      display: 'inline-block',
      fontSize: '20px',
    },
  },
  small: {},
  default: {},
});

export { loadingEllipsisStyles };
