import React from 'react';
import { connect } from 'react-redux';
import {
  StyledComponentProps,
  StyleRules,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import { Snackbar } from 'common-web/components/Snackbar';
import {
  ISnackbar,
  ISnackbarsState,
  SnackbarKey,
} from 'common/store/reducers/snackbarsReducer';
import { SnackbarsActions } from 'common/store/actions/SnackbarsActions';
import { SNACKBAR_AUTO_HIDE_DURATION } from 'common/core/const';

const styles = (theme: Theme): StyleRules => ({
  close: {
    padding: theme.spacing(0.5),
  },
});

interface ISnackbarsDisplay extends StyledComponentProps {
  snackbars: ISnackbar[];
  removeSnackbar: typeof SnackbarsActions.removeSnackbar;
}

class SnackbarsDisplayComponent extends React.Component<
  ISnackbarsDisplay,
  any
> {
  public render() {
    const { snackbars } = this.props;

    return (
      <>
        {snackbars.map(snackbar => {
          const { persist, autoHideDuration, onClose, ...rest } = snackbar;
          return (
            <Snackbar
              key={snackbar.key}
              snackbarKey={snackbar.key}
              onClose={this.handleClose}
              autoHideDuration={
                persist
                  ? undefined
                  : autoHideDuration || SNACKBAR_AUTO_HIDE_DURATION
              }
              {...rest}
            />
          );
        })}
      </>
    );
  }

  private handleClose = (key: SnackbarKey) => {
    const snackbar = this.props.snackbars.find(element => element.key === key);
    if (snackbar && snackbar.onClose) {
      snackbar.onClose(key);
    }
  };
}

const StyledNotifications = withStyles(styles)(SnackbarsDisplayComponent);

const mapDispatchToProps = {
  removeSnackbar: SnackbarsActions.removeSnackbar,
};

const mapStateToProps = (state: { snackbars: ISnackbarsState }) => ({
  snackbars: state.snackbars.queue,
});

const SnackbarsDisplay = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StyledNotifications);

export { SnackbarsDisplay };
