import { StyleRules, Theme } from '@material-ui/core/styles';

export const toolbarStyles = (theme: Theme): StyleRules => ({
  root: {
    margin: theme.spacing(-2),
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  link: {
    margin: theme.spacing(2),
    color: theme.palette.grey['700'],
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  button: {
    position: 'relative',
    padding: `0 ${theme.spacing(2)}px`,

    '&::after': {
      position: 'absolute',
      top: '50%',
      right: 0,
      content: '""',

      display: 'block',
      width: 1,
      height: theme.spacing(4),

      backgroundColor: theme.palette.divider,

      transform: 'translateY(-50%)',
    },
  },
});
