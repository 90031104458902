/**
 * API endpoints
 */
export const APPAPI = '/app/v2';
export const APPAPI_APP = `${process.env.REACT_APP_API_BASE}/app/v2/app`;
export const APPAPI_ENTERPRISE_APP = `${process.env.REACT_APP_API_BASE}${APPAPI}/app`;
export const APPAPI_STATS = `${APPAPI_APP}/statistic/overview`;

export const MY_APPS_URI = '/dashboard';

export const CHARTSAPI = `${process.env.REACT_APP_API_BASE}/chart/v2/chart`;

export enum OLD_HOOKS {
  OASIS_PUBKEY = 'apphook1=retrieve-entitypubkey',
  OASIS_SYNC = 'apphook2=retrieve-notesynced',
  OASIS_GET_INFO = 'apphook3=retrieve-stakeinfo',
  OASIS_BALANCE = 'apphook4=retrieve-stakeaccountinfo',
  OASIS_REGISTERED = 'apphook5=retrieve-noteregistered',
  OASIS_REGISTER = 'apphook6=staking-registering',
  OASIS_NODE_STATUS = 'apphook7=retrieve-nodestatus',
  OASIS_AVAILABLE_WITHDRAWAL = 'apphook8=retrieve-withdrawavailamount',
  OASIS_WITHDRAWAL = 'apphook9=withdraw',
  OASIS_AVAILABLE_RECLAIM = 'apphook10=retrieve-reclaimavailamount',
  OASIS_RECLAIM = 'apphook11=reclaim',
  OASIS_STACK_ESCROW = 'apphook12=staking-escrow',
  OASIS_RESTAKE = 'apphook13=restake',
  OASIS_SET_INITIAL_STATE = 'apphook14=set_initialstate',
  OASIS_GET_INITIAL_STATE = 'apphook15=get_initialstate',

  TOMOX_GET_INFO = 'apphook1=getInfo',
  TOMOX_GET_STATUS = 'apphook2=getStatus',
  TOMOX_UPDATE = 'apphook3=update',
  TOMOX_GET_UPDATE_AVAILABILITY = 'apphook4=updateStatus',
  TOMOX_GET_PROGRESS = 'apphook5=getProgress',
  TOMOX_RESTART = 'apphook6=restart',

  TOMOCHAIN_GET_NAME = 'apphook1=getName',
  TOMOCHAIN_GET_NODE_TYPE = 'apphook2=getStatus',
  TOMOCHAIN_INSPECT = 'apphook3=inspect',
  TOMOCHAIN_GET_PROGRESS = 'apphook4=getProgress',
  TOMOCHAIN_GET_SYNC_STATUS = 'apphook5=getSync',
  TOMOCHAIN_GET_ADDRESS = 'apphook6=getAddress',

  EDGEWARE_GET_SESSION_KEYS = 'apphook1=rotateKey',
  EDGEWARE_GET_NODE_NAME = 'apphook2=getNodeName',

  LTO_NETWORK_GET_ADDRESS = 'apphook=get-address',

  CELO_RETRIEVE_KEYSTORE = 'apphook1=retrieve-keystore',
  CELO_QUERY_REWARD = 'apphook2=query-reward',
  CELO_WITHDRAW_REWARD = 'apphook3=withdraw-reward',
  CELO_NODE_ACCOUNT = 'apphook4=node-account',
  CELO_STATUS = 'apphook6=query-node-status',

  CELO_VALIDATOR_QUERY_BALANCE = 'apphook2=query-balance',
  CELO_VALIDATOR_QUERY_ADDRESS = 'apphook1=query-address',
  CELO_VALIDATOR_LOCK_UNLOCK = 'apphook3=query-lock-unlock',
  CELO_VALIDATOR_NODE_STATUS = 'apphook4=query-node-status',
  CELO_VALIDATOR_GIST_URL = 'apphook5=query-gist-url',
  CELO_VALIDATOR_QUERY_ATTESTATION = 'apphook6=query-attestation',
  CELO_VALIDATOR_ACTION_LOCK = 'apphook10=action-lock',
  CELO_VALIDATOR_ACTION_UNLOCK = 'apphook11=action-unlock',
  CELO_VALIDATOR_ACTION_WITHDRAW = 'apphook12=action-withdraw',
  CELO_VALIDATOR_ACTION_STOP_VALIDATING = 'apphook13=action-stop-validating',
  CELO_VALIDATOR_ACTION_START_VALIDATING = 'apphook14=action-start-validating',
  CELO_VALIDATOR_ACTION_INITIAL = 'apphook15=action-initial-node',
  CELO_VALIDATOR_ACTION_ELECTION_VOTE = 'apphook16=action-election-vote',
  CELO_VALIDATOR_ACTION_ELECTION_REVOKE = 'apphook17=action-election-revoke',

  CELO_DAPP_QUERY_ADDRESS = 'apphook1=query-address',
  CELO_DAPP_QUERY_BALANCE = 'apphook2=query-balance',
  CELO_DAPP_QUERY_ELECTION_LIST = 'apphook3=query-election-list',
  CELO_DAPP_QUERY_ELECTION_CURRENT = 'apphook4=query-election-current',
  CELO_DAPP_QUERY_ELECTION_SHOW = 'apphook5=query-election-show',
  CELO_DAPP_LOCKEDGOLD_SHOW = 'apphook6=query-lockedgold-show',
  CELO_DAPP_QUERY_STATUS = 'apphook7=query-status',
  CELO_DAPP_QUERY_VOTE_HISTORY = 'apphook8=query-vote-history',
  CELO_DAPP_LOCKEDGOLD_LOCK = 'apphook20=action-lockedgold-lock',
  CELO_DAPP_LOCKEDGOLD_UNLOCK = 'apphook21=action-lockedgold-unlock',
  CELO_DAPP_LOCKEDGOLD_WITHDRAW = 'apphook22=action-lockedgold-withdraw',
  CELO_DAPP_IMPORT_MNEMONIC = 'apphook23=action-import-mnemonic',
  CELO_DAPP_QUERY_NEXT_ELECTION = 'apphook9=query-next-election',
  CELO_DAPP_QUERY_GROUP_LIST = 'apphook10=query-group-list',

  DASH_INSIGHT_STATUS = 'apphook1=node-status',
  DASH_NODE_STATUS = 'apphook1=get-node-status',
  DASH_RPC_CREDENTIALS = 'apphook2=get-rpc-credentials',
  BITCOIN_NODE_STATUS = 'apphook1=node-status',
  BITCOIN_RPC_CREDENTIALS = 'apphook2=rpc-credentials',

  // HARMONY_STAKING_NODE_STATUS = 'apphook1=node-status',
  HARMONY_STAKING_APPLY_TOKENS = 'apphook2=apply-tokens',
  HARMONY_STAKING_GET_BALANCE = 'apphook3=get-balance',
  // HARMONY_STAKING_GET_ADDRESS = 'apphook4=get-address',
  // HARMONY_STAKING_GET_NODENAME = 'apphook5=get-nodename',
  HARMONY_STAKING_CREATE_VALIDATOR = 'apphook6=create-validator',
  HARMONY_STAKING_CHECK_STATUS = 'apphook7=check-status',
  HARMONY_STAKING_UPGRADE_NODE = 'apphook8=upgrade-node',
  HARMONY_STAKING_EXPORT_ACCOUNT_KEY = 'apphook9=export-account-key',
  HARMONY_STAKING_EXPORT_BLS_KEY = 'apphook10=export-bls-key',
  HARMONY_STAKING_SET_VALIDATOR_ACTIVE = 'apphook11=set-validator-active',
  HARMONY_STAKING_SELF_STAKING = 'apphook12=self-staking',
  HARMONY_STAKING_COLLECT_REWARD = 'apphook13=collect-reward',

  KUSAMA_FULL_NODE_NAME = 'apphook1=node-name',
  KUSAMA_FULL_ARCHIVE_MODE = 'apphook2=archive-mode',
  KUSAMA_FULL_NODE_STATUS = 'apphook3=node-status',
  KUSAMA_FULL_QUERY_CHAIN_PROPERTIES = 'apphook4=query-chain-properties',
  KUSAMA_FULL_QUERY_NODE_STATUS = 'apphook5=query-node-status',

  KUSAMA_VALIDATOR_QUERY = 'apphook1=query-all',
  KUSAMA_VALIDATOR_BOND = 'apphook2=action-bond',
  KUSAMA_VALIDATOR_UNSTAKE = 'apphook3=action-unbond',
  KUSAMA_VALIDATOR_COMMISSION = 'apphook4=action-validate',
  KUSAMA_VALIDATOR_STAKE_MORE = 'apphook5=action-bond-extra',
  KUSAMA_VALIDATOR_DESTINATION = 'apphook6=action-set-payee',
  KUSAMA_VALIDATOR_CHILL = 'apphook7=action-chill',
  KUSAMA_VALIDATOR_KEYSTORE_QUERY = 'apphook9=query-keystore',
  KUSAMA_QUERY_CHAIN_PROPERTIES = 'apphook10=query-chain-properties',
  KUSAMA_VALIDATOR_ROTATE_KEYS = 'apphook11=action-rotate-keys',
  KUSAMA_VALIDATOR_QUERY_KEYS = 'apphook12=query-keys',
  KUSAMA_VALIDATOR_QUERY_NODE_STATUS = 'apphook13=query-node-status',
  KUSAMA_VALIDATOR_NODE_NAME = 'apphook14=node-name',

  ARWEAVE_NODE_STATUS = 'apphook1=node-status',
  ARWEAVE_WALLET_ADDRESS = 'apphook2=get-wallet-address',
  ARWEAVE_GET_PROGRESS = 'apphook3=get-progress-bar',

  CARDANO_NODE_STATUS = 'apphook1=get-status',
  CARDANO_GET_NODE_MODE = 'apphook2=get-node-mode',

  CARDANO_STAKE_RETRIEVE_KEYS = 'apphook1=retrieve-keys',
  CARDANO_STAKE_CREATE_POOL = 'apphook2=create-pool',
  CARDANO_STAKE_NODE_STATUS = 'apphook3=node-status',
  CARDANO_STAKE_GET_BALANCE = 'apphook4=get-balance',
  CARDANO_STAKE_NODE_ID = 'apphook5=node-id',
  CARDANO_STAKE_WITHDRAWAL = 'apphook6=send-money',
  CARDANO_STAKE_NODE_SECRET = 'apphook7=node-secret',

  ELROND_GET_STATUS = 'apphook1=getStatus',
  ELROND_RENAME_NODE = 'apphook2=renameNode',
  ELROND_RESTART_NDOE = 'apphook3=restartNode',
  ELROND_UPGRADE_NODE = 'apphook4=upgradeNode',
  ELROND_PEER_RATING = 'apphook5=peerRating',
  ELROND_GET_KEY = 'apphook6=getKey',
  ELROND_GET_VERSION = 'apphook7=getVersion',
  ELROND_SWITCH_SHARD = 'apphook8=switchShard',
  ELROND_AUTO_UPDATE = 'apphook9=autoUpdate',
  ELROND_GET_KEYBASE_NAME = 'apphook10=getKeybaseName',
  ELROND_RENAME_KEYBASE = 'apphook11=renameKeybase',
  ELROND_AUTO_UPDATE_STATUS = 'apphook12=autoUpdateStatus',

  ELROND_OBSERVER_GET_STATUS = 'apphook1=getStatus',
  ELROND_OBSERVER_SWITCH_SHARD = 'apphook2=switchShard',
  ELROND_OBSERVER_RENAME_NODE = 'apphook3=renameNode',
  ELROND_OBSERVER_RESTART_NODE = 'apphook4=restartNode',
  ELROND_OBSERVER_UPGRADE_NODE = 'apphook5=upgradeNode',
  ELROND_OBSERVER_PEER_RATING = 'apphook6=peerRating',
  ELROND_OBSERVER_RECONFIGURE_NODE = 'apphook7=reconfigureNode',
  ELROND_OBSERVER_GET_VERSION = 'apphook8=getVersion',

  ICON_ENDPOINT = 'apphook1=retrieve-p2pendpoint',
  ICON_STATUS = 'apphook2=query-status',

  IOST_GET_STATUS = 'apphook1=get-status',
  IOST_GET_PUBLIC_KEY = 'apphook2=get-public-key',
  IOST_GET_ACCOUNT = 'apphook3=get-account',
  IOST_GET_NETWORK_ID = 'apphook4=network-id',
  IOST_ADD_ACCOUNT = 'apphook5=add-account',
  IOST_GET_BALANCE = 'apphook6=get-balance',
  IOST_GET_VOTES = 'apphook7=get-votes',
  IOST_VOTE = 'apphook8=vote',
  IOST_PLEDGE_GAS = 'apphook9=pledge-gas',
  IOST_BUY_RAM = 'apphook10=buy-ram',
  IOST_CANCEL_VOTE = 'apphook11=unvote',
  IOST_REGISTER = 'apphook12=register',
  IOST_GET_PROGRESS = 'apphook13=get-progress',
  IOST_NODE_NAME = 'apphook14=get-name',
  IOST_IS_REGISTERED = 'apphook15=is-registered',

  HORIZEN_GET_PROGRESS = 'apphook1=get-progress',
  HORIZEN_GET_ADDRESS = 'apphook2=get-address',
  HORIZEN_GET_BALANCE = 'apphook3=get-balance',
  HORIZEN_GET_HEIGHT = 'apphook4=get-height',
  HORIZEN_ACTION_REQUIRED = 'apphook5=action-required',
  HORIZEN_READY_TO_PROD = 'apphook6=ready-to-prod',
  HORIZEN_BECOME_PROD = 'apphook7=become-prod',

  PCHAIN_GET_NODE_STATUS = 'apphook1=node-status',
  PCHAIN_GET_EPOCH = 'apphook2=get-epoch',
  PCHAIN_GET_ADDRESS = 'apphook3=get-address',
  PCHAIN_VALIDATE_KEYSTORE = 'apphook4=validate-keystore',
  PCHAIN_GET_BALANCE = 'apphook5=get-balance',
  PCHAIN_GET_KEYSTORE = 'apphook6=get-keystore',
  PCHAIN_UPDATE_KEYSTORE = 'apphook7=update-keystore',
  PCHAIN_APPLY_CANDIDATE = 'apphook8=apply-candidate',
  PCHAIN_CANCEL_CANDIDATE = 'apphook9=cancel-candidate',
  PCHAIN_GEN_VOTE = 'apphook10=gen-vote',
  PCHAIN_GET_CANDIDATE_STATUS = 'apphook11=show-candidate',
  PCHAIN_REVEAL_VOTE = 'apphook12=reveal-vote',
  PCHAIN_SHOW_VALIDATOR = 'apphook13=show-validator',
  PCHAIN_GET_PUBKEY = 'apphook14=show-pubkey',
  PCHAIN_IS_UPDATABLE = 'apphook15=updatable',
  PCHAIN_UPDATE = 'apphook16=update',
  PCHAIN_GET_VALIDATOR_STATUS = 'apphook17=get-status',

  COMPOUND_GET_METADATA = 'apphook1=get-metadata',
  COMPOUND_GET_USAGE = 'apphook2=get-usage',
  COMPOUND_RESET_DB = 'apphook3=reset-db',

  QTUM_WALLET_ADDRESS = 'apphook1=get-address',
  QTUM_STATUS = 'apphook2=node-status',
  QTUM_BALANCE = 'apphook3=get-balance',

  QTUM_FULL_NODE_STATUS = 'apphook1=get-node-status',
  QTUM_FULL_RPC_CREDENTIALS = 'apphook2=get-rpc-credentials',

  QTUM_STAKING_GET_ADDRESS = 'apphook1=get-address',
  QTUM_STAKING_GET_BALANCE = 'appphook2=get-balance',
  QTUM_STAKING_NODE_STATUS = 'apphook3=node-status',
  QTUM_STAKING_GET_STAKING_INFO = 'appphook4=getstakinginfo',

  QTUM_INFO_NODE_STATUS = 'apphook1=get-node-status',
  QTUM_INFO_RPC_CREDENTIALS = 'apphook2=get-rpc-credentials',
  QTUM_INFO_INDEXING_STATUS = 'apphook3=get-indexing-status',

  VITE_FULL_GET_ADDRESS = 'apphook=get-address',
  VITE_FULL_GET_PROGRESS = 'apphook2=get-progress',
  VITE_FULL_GET_NODE_NAME = 'apphook3=get-nodename',
  VITE_FULL_GET_SYNC_INFO = 'apphook4=get-syncinfo',

  CHAINLINK_GET_ADDRESS = 'apphook1=get-address',
  CHAINLINK_GET_EMAIL = 'apphook2=get-email',
  CHAINLINK_GET_STATUS = 'apphook3=get-status',
  CHAINLINK_GET_KEYS = 'apphook4=get-keystore',
  MATIC_STAKE = 'apphook1=stake',
  MATIC_GET_PUBLIC_ADDRESS = 'apphook2=get-public-address',
  MATIC_GET_STATUS = 'apphook3=get-status',
  MATIC_GET_VALIDATOR_INFO = 'apphook4=get-validator-info',
  MATIC_GET_BALANCE = 'apphook5=get-balance',
  MATIC_JOIN_VALIDATOR = 'apphook6=validator-join',
  MATIC_STAKING_STATUS = 'apphook7=stake-status',
  MATIC_FULL_GET_STATUS = 'apphook1=get-status',
  MATIC_FULL_GET_BOR_STATUS = 'apphook2=get-bor-status',
  MATIC_FULL_GET_NETWORK = 'apphook3=get-network',

  TRON_GET_PROGRESS = 'apphook1=get-progress',
  TRON_STATUS = 'apphook2=get-status',
  TRON_GET_VOTES = 'apphook3=get-votes',
  TRON_WALLET_ADDRESS = 'apphook4=get-address',

  ETHEREUM_NODE_STATUS = 'apphook1=node-status',
  ETHEREUM_GENESIS_BLOCK_HASH = 'apphook2=genesis-block-hash',
  ETHEREUM_LATEST_BLOCK_HEIGHT = 'apphook3=latest-block-height',
  ETHEREUM_GET_BLOCK_TRANSACTIONS = 'apphook4=block-transactions',
  ETHEREUM_GET_ACCOUNTS = 'apphook5=accounts',
  ETHEREUM_NETWORK_ID = 'apphook6=network',
  ETHEREUM_ARCHIVE_MODE = 'apphook7=archive-mode',

  ETHEREUM2_NODE_STATUS = 'apphook1=node-status',
  ETHEREUM2_PEERS_COUNT = 'apphook2=peers-count',
  ETHEREUM2_GET_GENESIS = 'apphook3=get-genesis',
  ETHEREUM2_GET_ACTIVATION_EPOCH = 'apphook4=get-activation-epoch',
  ETHEREUM2_GET_ETH1_DEPOSIT_BLOCK_NUMBER = 'apphook5=get-eth1-deposit-block-number',
  ETHEREUM2_GET_EFFECTIVE_BALANCE = 'apphook6=get-validator-info',
  ETHEREUM2_GET_VALIDATOR_INDEX = 'apphook7=get-validator-index',
  ETHEREUM2_GET_VALIDATOR_SLASHING_STATUS = 'apphook8=get-validator-slashing-status',
  ETHEREUM2_GET_VALIDATOR_STATUS = 'apphook9=get-validator-status',
  ETHEREUM2_GET_VALIDATOR_WITHDRAWAL_CREDENTIALS = 'apphook10=get-validator-withdrawal-credentials',
  ETHEREUM2_GET_VALIDATOR_PUBLIC_KEY = 'apphook11=get-validator-public-key',
  ETHEREUM2_GET_VALIDATOR_KEYSTORE = 'apphook12=get-validator-keystore',
  ETHEREUM2_GET_VALIDATOR_ASSIGNMENTS = 'apphook13=get-validator-assignments',
  ETHEREUM2_GET_KEY_BACKUP_STATUS = 'apphook14=get-key-backup-status',
  ETHEREUM2_SET_KEY_BACKUP_STATUS = 'apphook15=set-key-backup-status',
  ETHEREUM2_GET_DEPOSIT_DATA = 'apphook16=get-deposit-data',
  ETHEREUM2_UPLOAD_NEW_KEYSTORE = 'apphook17=upload-new-keystore',
  ETHEREUM2_UPLOAD_NEW_DEPOSIT_DATA = 'apphook18=upload-new-deposit-data',
  ETHEREUM2_GET_KEYSTORE_VALIDATION_STATUS = 'apphook19=get-keystore-validation-status',
  ETHEREUM2_GET_VALIDATOR_ATTESTATION = 'apphook21=get-validator-attestation',

  ETHEREUM_CLASSIC_NODE_STATUS = 'apphook1=node-status',
  ETHEREUM_CLASSIC_ARCHIVE_MODE = 'apphook2=archive-mode',

  SMARTCHAIN_VALIDATOR_GET_STATUS = 'apphook1=node-status',
  SMARTCHAIN_VALIDATOR_GET_NAME = 'apphook2=get-name',
  SMARTCHAIN_VALIDATOR_GET_BALANCE = 'apphook3=get-balance',
  SMARTCHAIN_VALIDATOR_APPLY = 'apphook4=apply',
  SMARTCHAIN_VALIDATOR_GET_STAKED = 'apphook5=get-staked',
  SMARTCHAIN_VALIDATOR_GET_VALIDATOR_STATUS = 'apphook6=get-validator-status',
  SMARTCHAIN_VALIDATOR_STAKE = 'apphook7=stake',
  SMARTCHAIN_VALIDATOR_GET_TYPE = 'apphook8=get-type',
  SMARTCHAIN_VALIDATOR_GET_NODE_ADDRESS = 'apphook9=get-smartchain-address',
  SMARTCHAIN_VALIDATOR_GET_BINANCE_ADDRESS = 'apphook10=get-binance-address',
  SMARTCHAIN_VALIDATOR_UNBOUND = 'apphook11=unbound',
  SMARTCHAIN_VALIDATOR_TRANSFER_FROM_SMARTCHAIN = 'apphook12=transfer-from-bsc',
  SMARTCHAIN_VALIDATOR_TRANSFER_FROM_BINANCE = 'apphook13=transfer-from-bs',
  SMARTCHAIN_VALIDATOR_UNJAIL = 'apphook14=unjail',

  DECRED_NODE_STATUS = 'apphook1=get-node-status',
  DECRED_RPC_CREDENTIALS = 'apphook2=get-rpc-credentials',

  NEO_GET_STATUS = 'apphook1=get-status',

  BLOCKSTACK_NODE_STATUS = 'apphook1=node-status',

  EOS_GET_NODE_STATUS = 'apphook1=node-status',

  REDDCOIN_NODE_STATUS = 'apphook1=get-node-status',
  REDDCOIN_RPC_CREDENTIALS = 'apphook2=get-rpc-credentials',
  REDDCOIN_INSIGHT_STATUS = 'apphook3=get-insight-status',
}

export const HOOKS = OLD_HOOKS;

export enum ChartName {
  arweave = 'arweave',
  oasis = 'oasis-validator',
  matic = 'matic',
  maticNode = 'matic-node',
  maticFull = 'matic-full',
  celer = 'celer',
  vite = 'vite',
  viteFull = 'vite-full',
  viteInit = 'init-vite',
  viteSupernode = 'vite-supernode',
  kusama = 'kusama',
  kusamaNode = 'kusama-node',
  kusamaValidator = 'kusama-validator',
  bifrost = 'bifrost',
  bifrostNode = 'bifrost-node',
  bifrostValidator = 'bifrost-validator',
  polkadot = 'polkadot',
  polkadotNode = 'polkadot-node',
  polkadotValidator = 'polkadot-validator',
  darwinia = 'darwinia',
  darwiniaNode = 'darwinia-node',
  darwiniaValidator = 'darwinia-validator',
  chainx = 'chainx',
  chainxNode = 'chainx-node',
  chainxValidator = 'chainx-validator',
  acala = 'acala',
  acalaNode = 'acala-node',
  acalaValidator = 'acala-validator',
  celo = 'celo',
  celoDApp = 'celo-dapp',
  boinc = 'boinc',
  edgeware = 'edgeware',
  tomoX = 'tomochain-tomox',
  tomochain = 'tomochain',
  tomochainNode = 'tomochain-node',
  elrondObs = 'elrond-obs',
  elrond = 'elrond',
  elrondNode = 'elrond-node',
  harmonyStaking = 'harmony-staking',
  oasisValidatorNode = 'oasis-validator-node',

  cardano = 'cardano',
  cardanoMain = 'cardano-main',
  cardanoFull = 'cardano-full',
  cardanoStake = 'cardano-stake',
  iost = 'iost',
  iostNode = 'iots-node',
  iostInit = 'init-iost',
  celoValidator = 'celo-validator',
  horizen = 'horizen',
  horizenNode = 'horizen-node',
  horizenSuper = 'horizen-super',
  horizenInit = 'init-horizen',
  pchain = 'pchain',
  pchainTest = 'pchain-test',
  compound = 'compound',
  compoundRestful = 'compound-restful',

  qtum = 'qtum',
  qtumFull = 'qtum-full',
  qtumStaking = 'qtum-staking',
  qtumStakingNode = 'qtum-staking-node',
  qtumInfo = 'qtum-info',
  qtumNode = 'qtum-node',
  qtumDb = 'qtum-db',

  chainlink = 'chainlink',
  dashInsight = 'dash-insight',
  dash = 'dash',
  dashNode = 'dash-node',
  bitcoin = 'bitcoin',
  bitcoinNode = 'bitcoin-node',

  tron = 'tron',
  tronNode = 'tron-node',
  tronInit = 'init-tron',

  ethereum = 'ethereum',
  ethereumNode = 'ethereum-node',
  ethereumKovan = 'ethereum-kovan',
  ethereumClassic = 'ethereum-classic',
  ethereumClassicNode = 'ethereum-classic-node',

  ethereumMedalla = 'ethereum-medalla',
  eth2Beacon = 'eth2-beacon',

  smartchainValidator = 'binance-val',

  pgs = 'pgs',

  decred = 'decred',
  decredNode = 'decred-node',

  neo = 'neo',
  neoNode = 'neo-node',

  blockstack = 'blockstack',
  blockstackFull = 'blockstack-full',

  nulsNode = 'nuls-node',

  eos = 'eos',
  eosNode = 'eos-node',
  reddcoin = 'reddcoin',
  reddcoinNode = 'reddcoin-node',
  reddcoinInsight = 'reddcoin-insight',
}
