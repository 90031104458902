import BigNumber from 'bignumber.js';
import { PaymentMethod } from '../const';
import { Days } from '../../../types';

export interface IApiDepositItem {
  app_id: string;
  deposit_time: string;
  app_end_time: string;
  usd: string;
  amount: string;
  project_name: string;
  chart_name: string;
  icon_url: string;
  pay_method: PaymentMethod;
  address: string;
  create_time: string;
  app_run_time: string;
  app_total_time: string;
  transaction_hash: string;
  card_id: string;
}

export interface IDepositItem {
  appId: string;
  depositTime: Date;
  appEndTime: Date;
  usd: BigNumber;
  amount: BigNumber;
  projectName: string;
  chartName: string;
  iconUrl: string;
  payMethod: PaymentMethod;
  address: string;
  createTime: Date;
  appRunTime: Days;
  appTotalTime: Days;
  transactionHash: string;
  cardId: string;
}

export interface IApiDepositList {
  deposit_list: IApiDepositItem[];
}

// TODO catch errors
export function mapDepositItem(item: IApiDepositItem): IDepositItem {
  return {
    appId: item.app_id,
    depositTime: new Date(parseInt(item.deposit_time) * 1000),
    appEndTime: new Date(parseInt(item.app_end_time) * 1000),
    usd: new BigNumber(item.usd),
    amount: new BigNumber(item.amount),
    projectName: item.project_name,
    chartName: item.chart_name,
    iconUrl: item.icon_url,
    payMethod: item.pay_method,
    address: item.address,
    createTime: new Date(parseInt(item.create_time) * 1000),
    appRunTime: parseInt(item.app_run_time),
    appTotalTime: parseInt(item.app_total_time),
    transactionHash: item.transaction_hash,
    cardId: item.card_id,
  };
}

export function mapDepositList(data: IApiDepositList): IDepositItem[] {
  return data.deposit_list.map(mapDepositItem);
}
