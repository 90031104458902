import React, { useCallback } from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  Hidden,
  MenuItem,
  MenuList,
  Popper,
  StyledComponentProps,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Username } from 'common-web/components/Username';
import { t } from 'common/utils/intl';
import { ProfileButtonStyles } from './ProfileButtonStyles';
import { AvatarImageContainer } from 'auth-web/components/Avatar/AvatarImage/AvatarImageContainer';
import { ITeam } from 'common/modules/teams/teamTypes';
import { ArrowDropDownIcon } from 'common-web/components/Icons/ArrowDropDownIcon';
import { GearIcon } from 'common-web/components/Icons/GearIcon';
import { LogoutIcon } from 'common-web/components/Icons/LogoutIcon';

export interface IProfileButtonStoreProps {
  currentTeam?: ITeam;
  teams: ITeam[];
  isUserAuthenticated: boolean;
}

interface IProfileButtonProps
  extends IProfileButtonStoreProps,
    StyledComponentProps {
  onProfileClick: () => void;
  onSignOutClick: () => void;
}

const ProfileButtonComponent = ({
  onProfileClick,
  onSignOutClick,
  classes = {},
  isUserAuthenticated,
}: IProfileButtonProps) => {
  const [open, setOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProfileClick = useCallback(() => {
    onProfileClick();
    setOpen(false);
  }, [onProfileClick]);

  const handleSignOutClick = () => {
    onSignOutClick();
    setOpen(false);
  };

  const setButtonRef = (node: HTMLElement) => {
    setAnchorEl(node);
  };

  if (!isUserAuthenticated) {
    return null;
  }
  return (
    <div className={classes.root}>
      <Button
        variant="text"
        className={classes.button}
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        buttonRef={setButtonRef}
        color="inherit"
        onClick={handleToggle}
      >
        <Box display="flex" alignItems="center">
          <AvatarImageContainer size="tiny" />
          <Hidden xsDown>
            <span className={classes.name}>
              <Username />
            </span>
            <ArrowDropDownIcon size="xs" />
          </Hidden>
        </Box>
      </Button>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorEl}
        transition={true}
        disablePortal={true}
      >
        {({ TransitionProps, placement }) => (
          <>
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <div className={classes.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <div>
                    <Divider />
                    <MenuList className={classes.menuList}>
                      <MenuItem
                        className={classes.menuItem}
                        onClick={handleProfileClick}
                      >
                        <GearIcon />
                        <Typography variant="body1" className={classes.text}>
                          {t('navigation.profile')}
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        className={classes.menuItem}
                        onClick={handleSignOutClick}
                      >
                        <LogoutIcon />
                        <Typography variant="body1" className={classes.text}>
                          {t('navigation.signout')}
                        </Typography>
                      </MenuItem>
                    </MenuList>
                  </div>
                </ClickAwayListener>
              </div>
            </Grow>
          </>
        )}
      </Popper>
    </div>
  );
};

const ProfileButton = withStyles(ProfileButtonStyles)(ProfileButtonComponent);

export { ProfileButton };
