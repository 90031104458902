/* eslint-disable @typescript-eslint/camelcase */

export function isObject(item?: object) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function mergeDeep(...args: object[]): object {
  const target = args[0];
  const sources = args.slice(1) as Record<string, any>;
  const output = Object.assign({}, target);
  if (isObject(target)) {
    for (const sourceIndex in sources) {
      const source = sources[sourceIndex];
      Object.keys(source).forEach(key => {
        if (isObject(source[key])) {
          if (!(key in target)) Object.assign(output, { [key]: source[key] });
          else {
            output[key] = mergeDeep(target[key], source[key]);
          }
        } else {
          Object.assign(output, { [key]: source[key] });
        }
      });
    }
  }
  return output;
}

export function objectToArray(object: any): any[] {
  try {
    return Object.keys(object).map(function(key) {
      return object[key];
    });
  } catch {
    return [];
  }
}
