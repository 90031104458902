import React, { useEffect, useRef } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom';
import { IBreadcrumb } from 'common/types/commonTypes';
import { parse, stringify } from 'query-string';
import { AUTH_PATH_KEY, PERSIST_KEY } from 'common/modules/Redirect/const';

type Component =
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>;

interface IPrivateRouteProps extends RouteProps {
  component?: Component;
  render?: (props: RouteComponentProps<any>) => React.ReactNode;
  isSignedIn: boolean;
  title?: string;
  breadcrumbs?: (IBreadcrumb | string)[];
}

const PageContext = React.createContext<{
  title: string;
  breadcrumbsState: (IBreadcrumb | string)[];
  setBreadcrumbs: (breadcrumbs: (IBreadcrumb | string)[]) => void;
}>({
  title: '',
  breadcrumbsState: [],
  setBreadcrumbs: (breadcrumbs: (IBreadcrumb | string)[]) => {
    return null;
  },
});

const PrivateRoute = (props: IPrivateRouteProps) => {
  const {
    component: CurrentComponent,
    render,
    isSignedIn,
    title = '',
    breadcrumbs = [],
    location,
    ...rest
  } = props;

  const [breadcrumbsState, setBreadcrumbs] = React.useState(breadcrumbs);

  const handleSetBreadcrumbs = (newBreadcrumbs: (IBreadcrumb | string)[]) => {
    setBreadcrumbs(newBreadcrumbs);
  };

  const previousComponent = useRef<Component>();

  previousComponent.current = CurrentComponent;

  useEffect(
    () => () => {
      if (CurrentComponent !== previousComponent.current) {
        setBreadcrumbs([]);
      }
    },
    [CurrentComponent],
  );

  const renderComponentOrRedirect = (routeProps: RouteComponentProps) => {
    if (isSignedIn) {
      return (
        <PageContext.Provider
          value={{
            title,
            breadcrumbsState:
              breadcrumbsState && breadcrumbsState.length
                ? breadcrumbsState
                : breadcrumbs,
            setBreadcrumbs: handleSetBreadcrumbs,
          }}
        >
          {CurrentComponent && <CurrentComponent {...routeProps} />}
          {render && render(routeProps)}
        </PageContext.Provider>
      );
    }

    const currentSearch = parse(routeProps.location.search);

    const search = stringify({
      resource: `${routeProps.location.pathname}${
        routeProps.location.search ? routeProps.location.search : ''
      }`,
      persist: currentSearch[PERSIST_KEY],
    });

    const pathname = (() => {
      if (
        typeof currentSearch[AUTH_PATH_KEY] === 'string' &&
        currentSearch[AUTH_PATH_KEY]
      ) {
        return currentSearch[AUTH_PATH_KEY] as string;
      }

      return '/auth/login';
    })();

    return (
      <Redirect
        to={{
          pathname,
          search,
        }}
      />
    );
  };

  return <Route {...rest} render={renderComponentOrRedirect} />;
};

export { PrivateRoute, PageContext };
