import {
  ICluster,
  IClusterStatus,
  IClusterGeoLocation,
  RunningState,
  ClusterStatus,
} from 'common/types/apiTypes';
import { IApiMoney } from '../../modules/apps/types/payTypes';
import { CheckFailPhase } from '../../modules/apps/types';
import BigNumber from 'bignumber.js';
import { PaymentMethod } from '../../modules/billing/const';

interface IApiClusterStatus {
  geo_location: IClusterGeoLocation;
}

export interface IApiCluster {
  id: string;
  name: string;
  provider_id: string;
  cluster_kind: RunningState;
  status: IApiClusterStatus;
  hourly_price: string;
  monthly_price: string;
  daily_amount: string;
}

export interface IClusterFetchResponse {
  clusters?: IApiCluster[];
}

export interface ICheckPriceResponse {
  ok: boolean;
  demand: IApiMoney;
  total: IApiMoney;
  balance: IApiMoney;
  pay_method_name: PaymentMethod;
  fail_phase: CheckFailPhase;
}

const normalizeClusterStatus = (status: IApiClusterStatus): IClusterStatus => {
  return {
    geoLocation: {
      lat: status?.geo_location?.lat,
      lng: status?.geo_location?.lng,
      country: status?.geo_location?.country,
      continent: status?.geo_location?.continent,
      city: status?.geo_location?.city,
      province: status?.geo_location?.province,
    },
  };
};

const renderRunningStatus = (status: RunningState): ClusterStatus => {
  switch (status) {
    case 'PUBLIC':
    case 'PRIVATE':
      return 'AVAILABLE';
    default:
      return 'UNAVAILABLE';
  }
};

const normalizeClusterItem = (item: IApiCluster): ICluster => {
  const status = item.status;
  return {
    id: item.id,
    name: item?.name,
    providerId: item?.provider_id,
    clusterKind: renderRunningStatus(item?.cluster_kind),
    monthlyPrice: new BigNumber(item.monthly_price || 0),
    hourlyPrice: new BigNumber(item.hourly_price || 0),
    dailyPrice: new BigNumber(item.daily_amount || 0),
    status: normalizeClusterStatus(status),
  };
};

const mappingClusters = (data: IApiCluster[]): ICluster[] => {
  return data.map(item => normalizeClusterItem(item));
};

export { mappingClusters };
