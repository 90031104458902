import {
  requestFailed,
  requestInactive,
  requestInProgress,
  RequestStatus,
  requestSuccessful,
} from 'common/utils/requestStatus';
import { createAPIReducer, createReducer } from 'common/utils/reduxUtils';

import { ZendeskActionTypes } from '../actions/ZendeskActions';

import {
  IAPIZendeskOrganization,
  IZendeskOrganization,
} from '../types/organizationTypes';
import {
  normalizeSingleZendeskOrganization,
  normalizeZendeskOrganization,
} from '../apiMappings/organizationMappings';
import { IAPIZendeskUser, IZendeskUser } from '../types/zendeskUserTypes';
import {
  normalizeSingleZendeskUser,
  normalizeZendeskUser,
} from '../apiMappings/zendeskUserMappings';
import {
  IAPIZendeskMembership,
  IZendeskMembership,
} from '../types/membershipTypes';
import {
  normalizeSingleZendeskMembership,
  normalizeZendeskMembership,
} from '../apiMappings/membershipMappings';
import {
  normalizeZendeskTicket,
  normalizeZendeskTickets,
} from '../apiMappings/ticketMappings';
import { normalizeZendeskTicketComments } from '../apiMappings/ticketCommentMappings';
import { IAPIZendeskTicket, IZendeskTicket } from '../types/ticketTypes';
import {
  IAPIZendeskTicketComment,
  IZendeskTicketComment,
  IZendeskTicketCommentAttachment,
} from '../types/ticketCommentTypes';
import { IExtendedAxiosResponse } from 'common/types';
import { UserActionTypes } from 'auth/store/actions/UserActions';

export function getTicket(state: IZendeskState, id: number) {
  return state.ticketList.find(item => item.id === id);
}

export interface IZendeskState {
  organizations: {
    [key: string]: IZendeskOrganization;
  };
  fetchOrganizationStatus: RequestStatus;

  user: IZendeskUser | undefined;
  fetchUserStatus: RequestStatus;

  users: {
    [key: string]: IZendeskUser;
  };
  fetchUsersStatus: {
    [key: string]: RequestStatus;
  };
  fetchUsersAllStatus: RequestStatus;

  memberships: {
    [key: string]: IZendeskMembership;
  };
  fetchMemebershipStatus: RequestStatus;

  ticketList: IZendeskTicket[];
  fetchTicketListStatus: RequestStatus;
  ticketsCount: number;

  fetchTicketStatus: RequestStatus;

  ticketComments: {
    [key: string]: {
      comments: IZendeskTicketComment[];
      attachments: IZendeskTicketCommentAttachment[];
    };
  };
  fetchTicketCommentsStatus: {
    [key: string]: RequestStatus;
  };
  fetchTicketCommentsAllStatus: RequestStatus;

  createTicketCommentStatus: RequestStatus;
  createTicketStatus: RequestStatus;
  updateTicketStatus: RequestStatus;
}

const initialState: IZendeskState = {
  organizations: {},
  fetchOrganizationStatus: requestInactive(),

  user: undefined,
  fetchUserStatus: requestInactive(),

  users: {},
  fetchUsersStatus: {},
  fetchUsersAllStatus: requestInactive(),

  memberships: {},
  fetchMemebershipStatus: requestInactive(),

  ticketList: [],
  ticketsCount: 0,
  fetchTicketListStatus: requestInactive(),

  fetchTicketStatus: requestInactive(),

  ticketComments: {},
  fetchTicketCommentsStatus: {},
  fetchTicketCommentsAllStatus: requestInactive(),
  createTicketCommentStatus: requestInactive(),
  createTicketStatus: requestInactive(),
  updateTicketStatus: requestInactive(),
};

export const zendeskReducer = createReducer(initialState, {
  /* zendesk organization */
  ...createAPIReducer<
    IZendeskState,
    IExtendedAxiosResponse<{ organizations: IAPIZendeskOrganization[] }>
  >(ZendeskActionTypes.FETCH_ZENDESK_ORGANIZATION, 'fetchOrganizationStatus', {
    onSuccess: (state: IZendeskState, action) => {
      const organization = normalizeSingleZendeskOrganization(action.data);

      const organizationObject = organization
        ? {
            [organization?.teamId]: organization,
          }
        : {};

      return {
        ...state,
        organizations: {
          ...state.organizations,
          ...organizationObject,
        },
      };
    },
  }),
  ...createAPIReducer<
    IZendeskState,
    IExtendedAxiosResponse<{ organization: IAPIZendeskOrganization }>
  >(ZendeskActionTypes.CREATE_ZENDESK_ORGANIZATION, 'fetchOrganizationStatus', {
    onSuccess: (state: IZendeskState, action) => {
      const organization = normalizeZendeskOrganization(
        action.data?.organization,
      );

      const organizationObject = organization
        ? {
            [organization?.teamId]: organization,
          }
        : {};

      return {
        ...state,
        organizations: {
          ...state.organizations,
          ...organizationObject,
        },
      };
    },
  }),

  /* zendesk user */
  ...createAPIReducer<
    IZendeskState,
    IExtendedAxiosResponse<{ users: IAPIZendeskUser[] }>
  >(ZendeskActionTypes.FETCH_ZENDESK_USER, 'fetchUserStatus', {
    onSuccess: (state: IZendeskState, action) => {
      const user = normalizeSingleZendeskUser(action.data);

      return {
        ...state,
        user,
      };
    },
  }),
  ...createAPIReducer<
    IZendeskState,
    IExtendedAxiosResponse<{ user: IAPIZendeskUser }>
  >(ZendeskActionTypes.CREATE_ZENDESK_USER, 'fetchUserStatus', {
    onSuccess: (state: IZendeskState, action) => {
      const user = action.data?.user
        ? normalizeZendeskUser(action.data?.user)
        : undefined;

      return {
        ...state,
        user,
      };
    },
  }),

  /* zendesk organization-user membership */
  ...createAPIReducer<
    IZendeskState,
    IExtendedAxiosResponse<{
      organization_memberships: IAPIZendeskMembership[];
    }>
  >(
    ZendeskActionTypes.FETCH_ZENDESK_ORGANIZATION_MEMEBERSHIP,
    'fetchMemebershipStatus',
    {
      onSuccess: (state: IZendeskState, action) => {
        const membership = normalizeSingleZendeskMembership(action.data);

        const membershipObject = membership
          ? {
              [membership?.zendeskOrganizationId]: membership,
            }
          : {};

        return {
          ...state,
          memberships: {
            ...state.memberships,
            ...membershipObject,
          },
        };
      },
    },
  ),
  ...createAPIReducer<
    IZendeskState,
    IExtendedAxiosResponse<{ organization_membership: IAPIZendeskMembership }>
  >(
    ZendeskActionTypes.CREATE_ZENDESK_ORGANIZATION_MEMEBERSHIP,
    'fetchMemebershipStatus',
    {
      onSuccess: (state: IZendeskState, action) => {
        const membership = normalizeZendeskMembership(
          action.data?.organization_membership,
        );

        const membershipObject = membership
          ? {
              [membership?.zendeskOrganizationId]: membership,
            }
          : {};

        return {
          ...state,
          memberships: {
            ...state.memberships,
            ...membershipObject,
          },
        };
      },
    },
  ),

  /* zendesk tickets */
  ...createAPIReducer<
    IZendeskState,
    IExtendedAxiosResponse<{
      tickets: IAPIZendeskTicket[];
      count: number;
    }>
  >(ZendeskActionTypes.FETCH_ZENDESK_TICKETS, 'fetchTicketListStatus', {
    onSuccess: (state: IZendeskState, action) => {
      const ticketList = normalizeZendeskTickets(action.data.tickets);

      return {
        ...state,
        ticketList,
        ticketsCount: action.data.count,
      };
    },
  }),
  ...createAPIReducer<
    IZendeskState,
    IExtendedAxiosResponse<{
      tickets?: IAPIZendeskTicket[];
      results?: IAPIZendeskTicket[];
      count: number;
    }>
  >(ZendeskActionTypes.FETCH_ZENDESK_TICKETS_SEARCH, 'fetchTicketListStatus', {
    onSuccess: (state: IZendeskState, action) => {
      const tickets = action.data.tickets || action.data.results;

      const ticketList = tickets ? normalizeZendeskTickets(tickets) : [];

      return {
        ...state,
        ticketList,
        ticketsCount: action.data.count,
      };
    },
  }),
  ...createAPIReducer<
    IZendeskState,
    IExtendedAxiosResponse<{
      tickets: IAPIZendeskTicket[];
      count: number;
    }>
  >(ZendeskActionTypes.FETCH_ZENDESK_TICKETS_GET, 'fetchTicketStatus', {
    onSuccess: (state: IZendeskState, action) => {
      // TODO do we need the action?
      const ticketList = normalizeZendeskTickets(action.data.tickets);

      return {
        ...state,
        ticketList: state.ticketList.map(item => {
          const newItem = ticketList.find(ticket => ticket.id === item.id);

          if (newItem) {
            return newItem;
          }

          return item;
        }),
      };
    },
  }),
  ...createAPIReducer<
    IZendeskState,
    IExtendedAxiosResponse<{ ticket: IAPIZendeskTicket }>
  >(ZendeskActionTypes.FETCH_ZENDESK_TICKET, 'fetchTicketStatus', {
    onSuccess: (state: IZendeskState, action) => {
      const ticketList = (() => {
        const ticket = normalizeZendeskTicket(action.data.ticket);

        const index = state.ticketList.findIndex(item => item.id === ticket.id);

        if (index === -1) {
          return [ticket, ...state.ticketList];
        }

        const arr = [...state.ticketList];
        arr.splice(index, 1, ticket);
        return arr;
      })();

      return {
        ...state,
        ticketList,
      };
    },
  }),
  ...createAPIReducer<
    IZendeskState,
    IExtendedAxiosResponse<{ ticket: IAPIZendeskTicket }>
  >(ZendeskActionTypes.CREATE_ZENDESK_TICKET, 'createTicketStatus', {
    onSuccess: (state: IZendeskState, action) => {
      const ticketList = (() => {
        const ticket = normalizeZendeskTicket(action.data.ticket);

        const index = state.ticketList.findIndex(item => item.id === ticket.id);

        if (index === -1) {
          return [ticket, ...state.ticketList];
        }

        const arr = [...state.ticketList];
        arr.splice(index, 1, ticket);
        return arr;
      })();

      return {
        ...state,
        ticketList,
      };
    },
  }),
  ...createAPIReducer<
    IZendeskState,
    IExtendedAxiosResponse<{ ticket: IAPIZendeskTicket }>
  >(ZendeskActionTypes.UPDATE_ZENDESK_TICKET, 'updateTicketStatus', {
    onSuccess: (state: IZendeskState) => {
      return {
        ...state,
      };
    },
  }),

  /* zendesk ticket comments */
  ...createAPIReducer<
    IZendeskState,
    IExtendedAxiosResponse<{ comments: IAPIZendeskTicketComment[] }>
  >(
    ZendeskActionTypes.FETCH_ZENDESK_TICKET_COMMENTS,
    'fetchTicketCommentsAllStatus',
    {
      onRequest: (state: IZendeskState, action) => {
        const statusObject = {
          [action.meta?.ticketId]: requestInProgress(),
        };

        return {
          ...state,
          fetchTicketCommentsStatus: {
            ...state.fetchTicketCommentsStatus,
            ...statusObject,
          },
        };
      },
      onError: (state: IZendeskState, action) => {
        const statusObject = {
          [action.meta?.ticketId]: requestFailed(),
        };

        return {
          ...state,
          fetchTicketCommentsStatus: {
            ...state.fetchTicketCommentsStatus,
            ...statusObject,
          },
        };
      },
      onSuccess: (state: IZendeskState, action) => {
        const comments = normalizeZendeskTicketComments(action.data);

        // extract attachments from comment 0
        const { attachments } = action.data.comments[0];

        const ticketId = action.meta?.ticketId;

        const commentsObject = {
          [ticketId]: { comments, attachments },
        };
        const statusObject = {
          [ticketId]: requestSuccessful(),
        };

        return {
          ...state,
          ticketComments: {
            ...state.ticketComments,
            ...commentsObject,
          },
          fetchTicketCommentsStatus: {
            ...state.fetchTicketCommentsStatus,
            ...statusObject,
          },
        };
      },
    },
  ),
  ...createAPIReducer<
    IZendeskState,
    IExtendedAxiosResponse<{ comments: IAPIZendeskTicketComment[] }>
  >(
    ZendeskActionTypes.CREATE_ZENDESK_TICKET_COMMENT,
    'createTicketCommentStatus',
    {
      onSuccess: (state: IZendeskState) => {
        return {
          ...state,
        };
      },
    },
  ),

  /* zendesk users */
  ...createAPIReducer<
    IZendeskState,
    IExtendedAxiosResponse<{ users: IAPIZendeskUser[] }>
  >(ZendeskActionTypes.FETCH_ZENDESK_USERS, 'fetchUsersStatus', {
    onSuccess: (state: IZendeskState, action) => {
      const users = action.data.users.map(normalizeZendeskUser);

      const userObject = users.reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
      }, {} as Record<number, IZendeskUser>);

      return {
        ...state,
        users: {
          ...state.users,
          ...userObject,
        },
      };
    },
  }),
  ...createAPIReducer<
    IZendeskState,
    IExtendedAxiosResponse<{ user: IAPIZendeskUser }>
  >(ZendeskActionTypes.FETCH_ZENDESK_USER_BY_ID, 'fetchUsersAllStatus', {
    onRequest: (state: IZendeskState, action) => {
      const statusObject = {
        [action.meta?.userId]: requestInProgress(),
      };

      return {
        ...state,
        fetchUsersStatus: {
          ...state.fetchUsersStatus,
          ...statusObject,
        },
      };
    },
    onError: (state: IZendeskState, action) => {
      const statusObject = {
        [action.meta?.userId]: requestFailed(),
      };

      return {
        ...state,
        fetchUsersStatus: {
          ...state.fetchUsersStatus,
          ...statusObject,
        },
      };
    },
    onSuccess: (state: IZendeskState, action) => {
      const user = normalizeZendeskUser(action.data?.user);

      const userObject = {
        [action.meta?.userId]: user,
      };
      const statusObject = {
        [action.meta?.userId]: requestSuccessful(),
      };

      return {
        ...state,
        users: {
          ...state.users,
          ...userObject,
        },
        fetchUsersStatus: {
          ...state.fetchUsersStatus,
          ...statusObject,
        },
      };
    },
  }),

  [UserActionTypes.SIGNOUT]: (): IZendeskState => {
    return initialState;
  },
});
