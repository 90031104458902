import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Typography } from '@material-ui/core';
import { t } from 'common/utils/intl';
import { useStep3Styles } from './Step3Styles';
import { useIsXXSDown } from 'common-web/utils/hooks/themeHooks';
import classNames from 'classnames';
import {
  getRequestError,
  isRequestFailed,
  isRequestSuccessful,
  RequestStatus,
} from 'common/utils/requestStatus';
import { connect } from 'react-redux';
import { TwoFactorActions } from 'auth/store/actions/TwoFactorActions';
import { IStepProps } from '../types';
import { defineFlowStep } from '../../../components/Flow/definition';
import { useFlowControl } from '../../../components/Flow/hooks';
import { CheckForm } from '../../../components/CheckForm';
import { ICheckFormProps } from '../../../components/CheckForm/CheckForm';
import { useSnackbar } from 'common/utils/hooks/useSnackbar';
import { ITwoFactorState } from 'auth/store/reducers/TwoFactorReducer';

interface IStep3Props extends ICheckFormProps {
  className?: string;
}

const Step3Component = ({ className, ...props }: IStep3Props) => {
  const classes = useStep3Styles();

  const isXXSDown = useIsXXSDown();

  return (
    <div className={classNames(className, classes.component)}>
      <Typography
        className={classes.title}
        variant={isXXSDown ? 'h3' : 'h2'}
        component="p"
      >
        {t('two-factor-auth.captions.enable.fourth-screen')}
      </Typography>

      <CheckForm className={classes.form} {...props} />
    </div>
  );
};

interface IConfirmationData {
  twoFactorConfirmed: boolean;
  code: string;
  password: string;
}

const Step3Imp = ({
  innerClassName,
  getConfirmationStatus,
  confirmationStatus,
  updateTwoFactorStatus,
}: IStepProps & {
  confirmationStatus: RequestStatus;
  getConfirmationStatus: typeof TwoFactorActions.activateTwoFactorAuth;
  updateTwoFactorStatus: () => void;
}) => {
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [codeKey, setCodeKey] = useState({});
  const [requestSent, setRequestSent] = useState(false);
  const updateData = useRef<() => void>(null as any);

  const handleSubmit = useCallback(
    ({ code, password }: Record<'code' | 'password', string>) => {
      setCode(code);
      setPassword(password);
    },
    [],
  );

  const { addData, onMoveForward, moveForward } = useFlowControl<
    IConfirmationData
  >();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    onMoveForward(() => updateData.current!());
  }, [updateData, handleSubmit, onMoveForward]);

  useEffect(() => {
    if (password && code) {
      getConfirmationStatus(password, code);
      setRequestSent(true);
    }
  }, [getConfirmationStatus, password, code]);

  const requestFailed = isRequestFailed(confirmationStatus);
  const { showSnackbar } = useSnackbar();

  // react on failed request by reseting everything
  useEffect(() => {
    if (requestFailed) {
      setCodeKey({});
      addData({ twoFactorConfirmed: false, code: '', password: '' });
      // resetStatus();
      setRequestSent(false);
    }
  }, [addData, requestFailed]);

  // react on successful request
  useEffect(() => {
    if (requestSent) {
      if (isRequestSuccessful(confirmationStatus)) {
        updateTwoFactorStatus();
        showSnackbar(t('two-factor-auth.enabled'), {
          key: 'two-factor-auth.enabled',
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        addData({ twoFactorConfirmed: true, password, code });
        moveForward();
      }
    }
  }, [
    showSnackbar,
    addData,
    updateTwoFactorStatus,
    confirmationStatus,
    moveForward,
    requestSent,
    password,
    code,
  ]);

  const error = requestFailed
    ? t(`two-factor-auth.errors.${getRequestError(confirmationStatus)}`)
    : undefined;

  return (
    <Step3Component
      className={innerClassName}
      onSubmit={handleSubmit}
      codeKey={codeKey}
      updateDataRef={updateData}
      error={error}
    />
  );
};

const Step3Wrapper = connect(
  (state: { twoFactor: ITwoFactorState }) => ({
    confirmationStatus: state.twoFactor.fetchEnableTwoFactorStatus,
  }),
  dispatch => ({
    getConfirmationStatus: (password: string, code: string) =>
      dispatch(TwoFactorActions.activateTwoFactorAuth(password, code)),
    updateTwoFactorStatus: () => dispatch(TwoFactorActions.getStatus()),
  }),
)(Step3Imp);

export const Step3 = defineFlowStep<{}, IConfirmationData, IStepProps>({
  Body: Step3Wrapper,
  validate: ({ twoFactorConfirmed }) => twoFactorConfirmed,
});
