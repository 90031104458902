/* eslint-disable @typescript-eslint/camelcase */
import { IUserState } from 'auth/store/reducers/userReducer';

export interface IUserAvatar {
  url: string;
  bgColor: string;
}

export interface IApiUserAvatar {
  url: string;
  bg_color: string;
}

export type UserStatus = 'CONFIRMING' | 'CONFIRMED' | 'DEACTIVATED';
export interface IApiUserResponse {
  id: string;
  email: string;
  nickname: string;
  crypto_address: string;
  avatar: IApiUserAvatar;
  // phone: string;
  status: UserStatus;
  create_time: Date;
  update_time: Date;
}

export interface IApiLoginResponse {
  access_token: string;
  expires_in: string;
  refresh_token: string;
  token_type: string;
}

const mapAvatar = ({ url, bg_color }: IApiUserAvatar): IUserAvatar => ({
  url,
  bgColor: bg_color || '#ffffff',
});

const mapApiResponseToUserInfo = (
  user: IApiUserResponse,
): Partial<IUserState> => {
  if (!user) {
    return {};
  }
  return {
    id: user.id,
    email: user.email,
    name: user.nickname,
    avatar: mapAvatar(user.avatar),
    depositAddress: user.crypto_address,
  };
};

export interface ISingUp {
  name: string;
  email: string;
  password: string;
  token: string;
}

const mapApiSignUp = (values: ISingUp) => {
  return {
    nickname: values.name,
    email: values.email,
    password: values.password,
    recaptcha_token: values.token,
  };
};

export { mapApiResponseToUserInfo, mapApiSignUp };
