import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { tHTML } from 'common/utils/intl';
import { useXSStep2Styles } from './XSStep2Styles';
import { useIsXXSDown } from 'common-web/utils/hooks/themeHooks';
import classNames from 'classnames';
import { CodeField } from '../CodeField';
import { connect, useSelector } from 'react-redux';
import {
  getTwoFactorCode,
  getTwoFactorIsEnabled,
} from 'auth/store/selectors/TwoFactorSelectors';
import { IStepProps } from '../types';
import { defineFlowStep } from '../../../components/Flow/definition';
import { RequestStatusDisplay } from 'common-web/components/RequestStatusDisplay';
import { TwoFactorActions } from 'auth/store/actions/TwoFactorActions';
import { ITwoFactorState } from 'auth/store/reducers/TwoFactorReducer';
import { IUserState } from 'auth/store/reducers/userReducer';

interface IXSStep2Props {
  className?: string;
  code: string;
  isError?: boolean;

  onChange?(checked: boolean): void;
}

export const XSStep2Component = ({ className, code }: IXSStep2Props) => {
  const classes = useXSStep2Styles();

  const isXXSDown = useIsXXSDown();

  return (
    <div className={classNames(className, classes.component)}>
      <Typography
        className={classes.title}
        variant={isXXSDown ? 'h3' : 'h2'}
        component="p"
      >
        {tHTML('two-factor-auth.captions.enable.third-screen-on-mobile')}
      </Typography>
      <div className={classes.content}>
        <CodeField className={classes.code} code={code} />
      </div>
    </div>
  );
};

interface IUserAgreement {}

const XSStep2Wrapper = ({
  innerClassName,
  getCode,
  regenerateCode,
}: IStepProps & {
  getCode: typeof TwoFactorActions.getCode;
  regenerateCode: typeof TwoFactorActions.regenerateCode;
}) => {
  const code = useSelector(getTwoFactorCode).secret;

  const userName = useSelector(
    (state: { user: IUserState }) => state.user.name,
  );
  const twoFactorAuthEnabled = useSelector(
    (state: { twoFactor: ITwoFactorState }) => getTwoFactorIsEnabled(state),
  );
  const status = useSelector(
    (state: { twoFactor: ITwoFactorState }) => state.twoFactor.fetchCodeStatus,
  );

  useEffect(() => {
    if (twoFactorAuthEnabled) {
      regenerateCode(userName);
    } else {
      getCode(userName);
    }
  }, [getCode, regenerateCode, twoFactorAuthEnabled, userName]);

  return (
    <RequestStatusDisplay
      status={status}
      showLoadingWhenInactive={true}
      showError={true}
    >
      <XSStep2Component className={innerClassName} code={code} />
    </RequestStatusDisplay>
  );
};

const ConnectedXSStep2 = connect(null, {
  getCode: TwoFactorActions.getCode,
  regenerateCode: TwoFactorActions.regenerateCode,
})(XSStep2Wrapper);

export const XSStep2 = defineFlowStep<{}, IUserAgreement, IStepProps>({
  Body: ConnectedXSStep2,
});
