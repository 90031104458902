import React, { useCallback, useEffect } from 'react';

import { useRef, useState } from 'react';
import classNames from 'classnames';
import { useEnterCodeStyles } from './EnterCodeStyles';
import { SingleField } from './SingleField';
import { useMergeRefs } from 'use-callback-ref';

const keepLastNumber = (fullString: string) =>
  fullString[fullString.length - 1];

interface IRecursiveFieldProps {
  onValueChange(index: number, value: string): void;

  index: number;
  length: number;
  large?: boolean;
}

const RecursiveField = React.forwardRef<HTMLInputElement, IRecursiveFieldProps>(
  ({ onValueChange, index, length, large }, ref) => {
    const nextRef = useRef<HTMLInputElement>(null);
    const selfRef = useRef<HTMLInputElement>(null);

    const mergedRef = useMergeRefs([selfRef, ref]);

    const [value, setValue] = useState('');
    const handleValueChange = useCallback(
      (v: string) => {
        const c = keepLastNumber(v);

        if (c !== undefined) {
          // try to move to the next Element
          if (nextRef.current) {
            nextRef.current.focus();
          } else if (selfRef.current) {
            selfRef.current.blur();
          }
        }
        // handle value change after focusing to prevent focus fighting
        onValueChange(index, c);
        setValue(c);
      },
      [index, onValueChange],
    );

    return (
      <>
        <SingleField
          key={index}
          ref={mergedRef}
          value={value}
          onValueChange={handleValueChange}
          name={`code-${index + 1}`}
          large={large}
        />
        {index < length && (
          <RecursiveField
            ref={nextRef}
            onValueChange={onValueChange}
            index={index + 1}
            length={length}
            large={large}
          />
        )}
      </>
    );
  },
);

interface IEnterCodeProps {
  className?: string;
  name?: string;
  required?: boolean;
  length?: number;
  large?: boolean;

  onReady(code: string): void;
}

export const EnterCode = ({
  className,
  name,
  required,
  onReady,
  length = 6,
  large,
}: IEnterCodeProps) => {
  const [value, setValue] = useState<string[]>(Array(length).fill(''));

  const handleValueChange = useCallback((index: number, str: string) => {
    setValue(oldState => {
      const newState = [...oldState];
      newState[index] = str;
      return newState;
    });
  }, []);

  useEffect(() => {
    if (value.some(x => x === '' || x === undefined)) {
      onReady('');
    } else {
      const code = value.join('');
      onReady(code);
    }
  }, [value, onReady]);

  const classes = useEnterCodeStyles();

  return (
    <div className={classNames(className, classes.code)}>
      <input
        type="hidden"
        required={required}
        value={value.join('')}
        name={name}
      />
      <RecursiveField
        onValueChange={handleValueChange}
        index={0}
        length={length - 1}
        large={large}
      />
    </div>
  );
};
