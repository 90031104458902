import {
  EscrowType,
  IAipWithdrawalListResponse,
  IApiBalance,
  IApiCardList,
  IApiCheckPayMethodSelectableResponse,
  IApiEscrow,
  IApiPayMethod,
  IApiPayMethodListResponse,
  IBalance,
  ICard,
  IEscrow,
  IPaymentMethod,
  IPaymentMethodAvailability,
  IPrePaymentPeriod,
  IWithdrawal,
  PayStatus,
} from '../../apps/types/payTypes';
import { PayActionTypes } from '../actions/PayActions';
import { requestInactive, RequestStatus } from '../../../utils/requestStatus';
import { createAPIReducer, createReducer } from '../../../utils/reduxUtils';
import {
  normalizeCards,
  normalizeEscrow,
  normalizeEscrowWithdrawalList,
  normalizePaymentMethodAvailability,
  normalizePayMethod,
  normalizePayMethodList,
} from '../../apps/apiMappings/payMappings';
import {
  DeployStep,
  PaymentConfigurationStep,
  PaymentMethod,
  PaymentMode,
  PaymentStep,
} from '../const';
import { Action } from 'redux-actions';
import { UserActionTypes } from 'auth/store/actions/UserActions';
import { IInvoiceDetails } from '../../apps/types/invoiceType';
import { IPriceResult } from 'common/modules/apps/types';
import { ICheckPriceResponse } from 'common/store/apiMappings/clusterMappings';
import { normalizePriceResult } from 'common/modules/apps/apiMappings/appMappings';
import {
  Days,
  IExtendedAxiosResponse,
  IExtendedRequestAction,
  IExtendedRequestActionMeta,
} from '../../../types';
import { IOverviewItem } from './invoiceReducerTypes';
import BigNumber from 'bignumber.js';
import {
  IApiDepositAmount,
  mapDepositAmount,
} from '../apiMapping/getDepositAmount';
import {
  IApiEthPaymentStatus,
  mapEthPaymentStatus,
} from '../apiMapping/getEthPaymentStatus';
import {
  IApiDepositList,
  IDepositItem,
  mapDepositList,
} from '../apiMapping/getDepositList';
import {
  IApiPrePaymentResponse,
  IPrePaymentItem,
  mapPaymentList,
} from '../apiMapping/getPaymentList';
import {
  IApiPrepareExtendOrderData,
  mapExtendPlan,
} from '../apiMapping/extendApp';
import {
  IApiAppPaymentData,
  IAppPaymentData,
  mapAppPaymentData,
  PaymentWay,
} from '../apiMapping/appPaymentData';

export interface IEnableNewBankCardData {
  cardId?: string;
}

export const getCurrentPaymentMethodBalance = (state: IPayState) => {
  return state.balances.find(
    item => item.payName === state.currentPaymentMethod,
  )?.data;
};

export function getDeposit(state: IPayState, appId: string) {
  return state.depositList?.find(item => item.appId === appId);
}

export function getAppRemainingDays(state: IPayState, appId: string) {
  const deposit = getDeposit(state, appId);
  if (
    typeof deposit?.appTotalTime === 'number' &&
    typeof deposit?.appRunTime === 'number'
  ) {
    return (deposit?.appTotalTime - deposit.appRunTime) as Days;
  }
  return undefined;
}

export const getCurrentCard = (cards: ICard[]): ICard | undefined => {
  return cards.find((item: ICard) => item.enabled);
};

export function getDepositMode(state: IPayState): PaymentMode | undefined {
  if (state.paymentDeployStep === DeployStep.INIT) {
    return undefined;
  }

  if (
    state.paymentDeployStep === DeployStep.DEPOSIT_CHOOSE_METHOD ||
    state.paymentDeployStep === DeployStep.DEPOSIT
  ) {
    return 'deposit';
  }

  return 'daily';
}

export function getDailyPrice(monthlyPrice: BigNumber): BigNumber {
  // TODO Change to a more accurate way
  return monthlyPrice.div(30);
}

const updatePaymentMethods = (
  methods: IPaymentMethod[],
  newMethod: IPaymentMethod,
) => {
  const fetchedMethod = methods.findIndex(
    method => method.name === newMethod.name,
  );

  const payMethodList: IPaymentMethod[] = [...methods];

  if (fetchedMethod !== -1) {
    payMethodList[fetchedMethod] = newMethod;
  } else {
    payMethodList.push(newMethod);
  }

  return payMethodList;
};

export const getPaymentMethodAvailability = (
  paymentMethodsAvailability: IPaymentMethodAvailability[],
  paymentMethodName?: PaymentMethod,
) => {
  return paymentMethodsAvailability.find(
    item => item.payName === paymentMethodName,
  );
};

export const getPaymentMethod = (
  paymentMethods: IPaymentMethod[],
  paymentMethodName?: PaymentMethod,
) => paymentMethods.find(item => item.name === paymentMethodName);

function isPaymentConfigurationDone(paymentMethod?: IPaymentMethod) {
  return (
    paymentMethod?.status === PayStatus.READY ||
    paymentMethod?.status === PayStatus.ENABLED
  );
}

export function isEscrowEnabled(state: IPayState) {
  return state.paymentMethods.some(
    item => item.type === EscrowType.ETH || item.type === EscrowType.ANKR,
  );
}

export const MAX_CARDS_COUNT = 2;

export function isAddCardDisabled(state: IPayState) {
  return state.cards.length >= MAX_CARDS_COUNT;
}

// TODO Remove
const DISABLE_DETECTING = true;

export function isDepositPaymentMethod(state: IPayState) {
  if (DISABLE_DETECTING) {
    return true;
  }
  return state.appPaymentInfo?.payWay === PaymentWay.Deposit;
}

export interface IPayState {
  paymentMethods: IPaymentMethod[];
  fetchPaymentMethodsStatus: RequestStatus;

  stripePublicKey?: string;
  getStripePublicKeyStatus: RequestStatus;

  enableNewBankCardStatus: RequestStatus;

  currentPaymentMethod?: PaymentMethod;
  fetchPaymentMethodStatus: RequestStatus;

  setupPaymentMethodStatus: RequestStatus;

  listCardsStatus: RequestStatus;
  cards: ICard[];
  currentCard: ICard | undefined;

  deleteBankCardStatus: RequestStatus;

  fetchPaymentMethodAvailabilityStatus: RequestStatus;

  selectPaymentMethodStatus: RequestStatus;

  paymentMethodsAvailability: IPaymentMethodAvailability[];
  fetchPaymentMethodsAvailabilityStatus: RequestStatus;

  escrowList: IEscrow[];
  fetchEscrowListStatus: RequestStatus;

  escrow?: IEscrow;
  fetchEscrowStatus: RequestStatus;

  balances: IBalance[];
  fetchBalancesStatus: RequestStatus;

  withdrawalList: IWithdrawal[];
  fetchWithdrawalListStatus: RequestStatus;

  withdrawAvailableBalanceStatus: RequestStatus;
  withdrawTransactionId: string;

  paymentStep: PaymentStep;
  prePaymentPeriod?: IPrePaymentPeriod;
  justCreatedCardId?: string;
  paymentDeployStep: DeployStep;

  candidatePaymentMethod?: PaymentMethod;

  paymentConfigurationStep: PaymentConfigurationStep;

  fetchOverviewStatus: RequestStatus;
  overview: IOverviewItem[];

  invoiceDetail?: IInvoiceDetails;
  fetchInvoiceDetailStatus: RequestStatus;

  checkPriceResult?: IPriceResult;
  checkPriceResultStatus: RequestStatus;

  getDailyPriceStatus: RequestStatus;
  dailyPrice?: BigNumber;
  depositUsdMonthlyAmount?: BigNumber;

  getDepositAmountStatus: RequestStatus;
  depositUsdtAmount?: BigNumber;
  depositAnkrAmount?: BigNumber;
  depositUsdAmount?: BigNumber;
  depositSaveUsdAmount?: BigNumber;
  depositAmountMethod?: PaymentMethod;

  ethPaymentStatusStatus: RequestStatus;
  ethPaymentAddress?: string;
  ethPaymentAmount?: BigNumber;
  ethPaymentUsdAmount?: BigNumber;
  ethPaymentMethod?: PaymentMethod;

  getDepositListStatus: RequestStatus;
  depositList?: IDepositItem[];

  extendAppStatus: RequestStatus;
  extendAmount?: BigNumber;
  extendPaymentMethod?: PaymentMethod;

  getPrePaymentDepositListStatus: RequestStatus;
  prePaymentDepositList?: IPrePaymentItem[];

  getAppPaymentInfoStatus: RequestStatus;
  appPaymentInfo?: IAppPaymentData;

  getTrialAmountStatus: RequestStatus;
  trialAmount: BigNumber;
}

const initialState: IPayState = {
  extendAppStatus: requestInactive(),

  currentPaymentMethod: undefined,

  getStripePublicKeyStatus: requestInactive(),

  enableNewBankCardStatus: requestInactive(),

  fetchPaymentMethodsStatus: requestInactive(),
  paymentMethods: [],

  fetchPaymentMethodStatus: requestInactive(),

  setupPaymentMethodStatus: requestInactive(),

  getDepositListStatus: requestInactive(),
  getPrePaymentDepositListStatus: requestInactive(),

  listCardsStatus: requestInactive(),
  cards: [],
  currentCard: undefined,

  deleteBankCardStatus: requestInactive(),

  fetchPaymentMethodAvailabilityStatus: requestInactive(),

  selectPaymentMethodStatus: requestInactive(),

  fetchPaymentMethodsAvailabilityStatus: requestInactive(),
  paymentMethodsAvailability: [],

  fetchEscrowListStatus: requestInactive(),
  escrowList: [],

  fetchEscrowStatus: requestInactive(),
  escrow: undefined,

  fetchBalancesStatus: requestInactive(),
  balances: [],

  fetchWithdrawalListStatus: requestInactive(),
  withdrawalList: [],

  withdrawAvailableBalanceStatus: requestInactive(),
  withdrawTransactionId: '',

  paymentStep: PaymentStep.NONE,
  // TODO Merge `paymentDeployStep` to form data
  paymentDeployStep: DeployStep.DEPOSIT,

  candidatePaymentMethod: PaymentMethod.ANKR,

  paymentConfigurationStep: PaymentConfigurationStep.INIT,

  fetchOverviewStatus: requestInactive(),
  overview: [],

  invoiceDetail: undefined,
  fetchInvoiceDetailStatus: requestInactive(),

  checkPriceResultStatus: requestInactive(),

  getDailyPriceStatus: requestInactive(),

  getDepositAmountStatus: requestInactive(),

  ethPaymentStatusStatus: requestInactive(),

  getAppPaymentInfoStatus: requestInactive(),

  getTrialAmountStatus: requestInactive(),
  trialAmount: new BigNumber(0),
};

const payReducer = createReducer(initialState, {
  ...createAPIReducer<IPayState, { data: { key: string } }>(
    PayActionTypes.GET_STRIPE_PUBLIC_KEY,
    'getStripePublicKeyStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          stripePublicKey: action.data.key,
        };
      },
    },
  ),
  ...createAPIReducer<IPayState, { data: IEnableNewBankCardData }>(
    PayActionTypes.CREATE_NEW_BANK_CARD,
    'enableNewBankCardStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          justCreatedCardId: action.data.cardId,
          paymentStep: PaymentStep.NONE,
        };
      },
    },
  ),
  ...createAPIReducer<IPayState, { data: IApiPayMethodListResponse }>(
    PayActionTypes.FETCH_PAYMENT_METHODS,
    'fetchPaymentMethodsStatus',
    {
      onSuccess: (state, { data }) => {
        const paymentMethods: IPaymentMethod[] = normalizePayMethodList(data);
        const selectedPaymentMethod = paymentMethods.find(
          item => item.selected,
        );

        return {
          ...state,
          paymentMethods,
          currentPaymentMethod: selectedPaymentMethod?.name,
          paymentConfigurationStep: isPaymentConfigurationDone(
            selectedPaymentMethod,
          )
            ? PaymentConfigurationStep.DONE
            : state.paymentConfigurationStep,
        };
      },
    },
  ),
  ...createAPIReducer<IPayState, { data: IApiPayMethod }>(
    PayActionTypes.FETCH_PAYMENT_METHOD,
    'fetchPaymentMethodStatus',
    {
      onSuccess: (state, { data }) => {
        const paymentMethod = normalizePayMethod(data);
        return {
          ...state,
          paymentMethods: updatePaymentMethods(
            state.paymentMethods,
            normalizePayMethod(data),
          ),
          paymentConfigurationStep: isPaymentConfigurationDone(paymentMethod)
            ? PaymentConfigurationStep.DONE
            : state.paymentConfigurationStep,
        };
      },
    },
  ),
  ...createAPIReducer<IPayState, { data: IApiPayMethod }>(
    PayActionTypes.SETUP_PAYMENT_METHOD,
    'setupPaymentMethodStatus',
    {
      onSuccess: (state, { data }) => {
        const paymentMethod: IPaymentMethod = normalizePayMethod(data);
        return {
          ...state,
          paymentMethods: updatePaymentMethods(
            state.paymentMethods,
            paymentMethod,
          ),
          currentPaymentMethod:
            paymentMethod.name === PaymentMethod.CREDIT_CARD
              ? paymentMethod.name
              : state.currentPaymentMethod,
        };
      },
    },
  ),
  ...createAPIReducer<IPayState, { data: IApiCardList }>(
    PayActionTypes.FETCH_BANK_CARDS,
    'listCardsStatus',
    {
      onSuccess: (state, { data }) => {
        const cards = normalizeCards(data.cards);
        return {
          ...state,
          cards: cards,
          currentCard: getCurrentCard(cards),
        };
      },
    },
  ),
  ...createAPIReducer<IPayState, { data: { cards: ICard[] } }>(
    PayActionTypes.DELETE_BANK_CARD,
    'deleteBankCardStatus',
    {
      onSuccess: (state, { data: { cards: removedCards } }) => {
        const cards = state.cards.filter(card =>
          removedCards.find(removedCard => removedCard.id === card.id),
        );

        return {
          ...state,
          cards,
          paymentStep: PaymentStep.SELECT_PAYMENT_METHOD,
        };
      },
    },
  ),
  [PayActionTypes.SETUP_PAYMENT_METHOD_RESET]: (
    state: IPayState,
  ): IPayState => {
    return {
      ...state,
      setupPaymentMethodStatus: requestInactive(),
    };
  },
  ...createAPIReducer<
    IPayState,
    {
      data: IApiCheckPayMethodSelectableResponse;
      meta: IExtendedRequestActionMeta;
    }
  >(
    PayActionTypes.CHECK_PAYMENT_METHOD,
    'fetchPaymentMethodAvailabilityStatus',
    {
      onSuccess: (state, action) => {
        const paymentMethodsAvailability = [
          ...state.paymentMethodsAvailability,
        ];
        const request = action.meta.requestAction.request;
        const payName = request.payName;
        const data = normalizePaymentMethodAvailability(action.data);
        const index = paymentMethodsAvailability.findIndex(
          (item: IPaymentMethodAvailability) => item.payName === payName,
        );
        if (index === -1) {
          paymentMethodsAvailability.push({
            payName,
            data: data,
          });
        } else {
          paymentMethodsAvailability[index] = {
            payName,
            data: data,
          };
        }
        return {
          ...state,
          paymentMethodsAvailability,
        };
      },
    },
  ),
  [PayActionTypes.CHECK_PAYMENT_METHOD_RESET]: (
    state: IPayState,
  ): IPayState => {
    return {
      ...state,
      fetchPaymentMethodAvailabilityStatus: requestInactive(),
    };
  },
  ...createAPIReducer<
    IPayState,
    {
      data: IApiCheckPayMethodSelectableResponse[];
      meta: IExtendedRequestActionMeta;
    }
  >(
    PayActionTypes.CHECK_PAYMENT_METHODS_LIST,
    'fetchPaymentMethodsAvailabilityStatus',
    {
      onSuccess: (state, action) => {
        const request = action.meta.requestAction.request;
        const data = action.data;
        const selectablePaymentMethods = [...state.paymentMethodsAvailability];
        data.forEach(
          (item: IApiCheckPayMethodSelectableResponse, index: number) => {
            const selectableItem = normalizePaymentMethodAvailability(item);
            const currentItem = {
              payName: request[index].payName,
              data: selectableItem ? selectableItem : undefined,
            };
            const currentIndex = selectablePaymentMethods.findIndex(
              (item: IPaymentMethodAvailability) =>
                item.payName === currentItem.payName,
            );
            if (currentIndex === -1) {
              selectablePaymentMethods.push(currentItem);
            } else {
              selectablePaymentMethods[currentIndex] = currentItem;
            }
          },
        );
        return {
          ...state,
          paymentMethodsAvailability: selectablePaymentMethods,
        };
      },
    },
  ),
  ...createAPIReducer<IPayState, { data: IApiPayMethodListResponse }>(
    PayActionTypes.SELECT_PAYMENT_METHOD,
    'selectPaymentMethodStatus',
    {
      onSuccess: (state, { data }) => {
        const paymentMethods: IPaymentMethod[] = normalizePayMethodList(data);
        const selectedPaymentMethod = paymentMethods.find(
          item => item.selected,
        );

        return {
          ...state,
          currentPaymentMethod: selectedPaymentMethod?.name,
          paymentMethods,
          paymentStep: PaymentStep.NONE,
          paymentConfigurationStep: isPaymentConfigurationDone(
            selectedPaymentMethod,
          )
            ? PaymentConfigurationStep.DONE
            : state.paymentConfigurationStep,
        };
      },
    },
  ),
  [PayActionTypes.SELECT_PAYMENT_METHOD_RESET]: (
    state: IPayState,
  ): IPayState => {
    return {
      ...state,
      selectPaymentMethodStatus: requestInactive(),
    };
  },
  ...createAPIReducer<IPayState, { data: IApiEscrow[] }>(
    PayActionTypes.FETCH_ESCROW_LIST,
    'fetchEscrowListStatus',
    {
      onSuccess: (state, action) => {
        const escrowList = [...state.escrowList];
        const list = action.data.map((item: IApiEscrow) =>
          normalizeEscrow(item),
        );
        list.forEach((item: IEscrow) => {
          if (
            !escrowList.find((escrow: IEscrow) => item.type === escrow.type)
          ) {
            escrowList.push(item);
          }
        });
        return {
          ...state,
          escrowList,
        };
      },
    },
  ),
  [PayActionTypes.SET_PRE_PAYMENT_PERIOD]: (
    state: IPayState,
    { payload },
  ): IPayState => {
    return {
      ...state,
      prePaymentPeriod: payload,
    };
  },
  ...createAPIReducer<
    IPayState,
    { data: IApiBalance[]; meta: IExtendedRequestActionMeta }
  >(PayActionTypes.FETCH_BALANCES_LIST, 'fetchBalancesStatus', {
    onSuccess: (state, action) => {
      const request = action.meta.requestAction.request;
      const data = action.data;
      const balances = [...state.balances];

      data.forEach((item: IApiBalance, index: number) => {
        const currentItem = {
          payName: request[index].payName,
          data: item,
        };
        const balanceIndex = balances.findIndex(
          (item: IBalance) => item.payName === currentItem.payName,
        );
        if (balanceIndex === -1) {
          balances.push(currentItem);
        } else {
          balances[balanceIndex] = currentItem;
        }
      });
      return {
        ...state,
        balances,
      };
    },
  }),
  ...createAPIReducer<IPayState, { data: IAipWithdrawalListResponse }>(
    PayActionTypes.FETCH_WITHDRAWAL_LIST,
    'fetchWithdrawalListStatus',
    {
      onSuccess: (state, { data }) => {
        return {
          ...state,
          withdrawalList: normalizeEscrowWithdrawalList(
            data.escrow_withdrawals,
          ),
        };
      },
    },
  ),
  ...createAPIReducer<IPayState, { data: ICheckPriceResponse }>(
    PayActionTypes.CHECK_PRICE,
    'checkPriceResultStatus',
    {
      onSuccess: (state: IPayState, action) => {
        const { data } = action;
        const balances = [...state.balances];

        balances.forEach((item, index) => {
          if (item.payName === data.pay_method_name) {
            balances[index].data.available = data.balance;
            balances[index].data.total = data.total;
            return;
          }
        });

        return {
          ...state,
          checkPriceResult: normalizePriceResult(data),
          balances,
        };
      },
    },
  ),
  ...createAPIReducer<IPayState>(
    PayActionTypes.WITHDRAW_AVAILABLE_BALANCE,
    'withdrawAvailableBalanceStatus',
    {
      onSuccess: (state: IPayState, action) => {
        const { data } = action;
        return {
          ...state,
          withdrawTransactionId: data.id,
        };
      },
    },
  ),

  [PayActionTypes.WITHDRAW_AVAILABLE_BALANCE_RESET]: (
    state: IPayState,
  ): IPayState => {
    return {
      ...state,
      withdrawAvailableBalanceStatus: requestInactive(),
      withdrawTransactionId: '',
    };
  },

  [PayActionTypes.SET_STEP]: (
    state: IPayState,
    { payload: { step } }: Action<{ step: PaymentStep }>,
  ): IPayState => {
    return {
      ...state,
      paymentStep: step,
      candidatePaymentMethod:
        step === PaymentStep.SELECT_PAYMENT_METHOD
          ? state.currentPaymentMethod
          : state.candidatePaymentMethod,
    };
  },

  [PayActionTypes.SET_PAYMENT_DEPLOY_STEP]: (
    state: IPayState,
    { payload: { step } }: Action<{ step: DeployStep }>,
  ): IPayState => {
    return {
      ...state,
      paymentDeployStep: step,
    };
  },

  [PayActionTypes.SET_CANDIDATE_PAYMENT_METHOD]: (
    state: IPayState,
    { payload: { paymentMethod } }: Action<{ paymentMethod: PaymentMethod }>,
  ): IPayState => {
    return {
      ...state,
      candidatePaymentMethod: paymentMethod,
    };
  },

  [PayActionTypes.SET_PAYMENT_CONFIGURATION_STEP]: (
    state: IPayState,
    { payload: { step } }: Action<{ step: PaymentConfigurationStep }>,
  ): IPayState => {
    return {
      ...state,
      paymentConfigurationStep: step,
    };
  },

  [UserActionTypes.SIGNOUT]: (): IPayState => ({
    ...initialState,
  }),
  [UserActionTypes.SIGNIN]: (): IPayState => ({
    ...initialState,
  }),

  ...createAPIReducer<
    IPayState,
    { data: IApiDepositAmount } & IExtendedRequestAction
  >(PayActionTypes.GET_DAILY_AMOUNT, 'getDailyPriceStatus', {
    onSuccess: (state, action) => {
      const data = mapDepositAmount(action.data);
      return {
        ...state,
        dailyPrice: getDailyPrice(data.usdAmount),
        depositUsdMonthlyAmount: data.usdAmount,
      };
    },
  }),

  ...createAPIReducer<
    IPayState,
    { data: IApiDepositAmount } & IExtendedRequestAction
  >(PayActionTypes.GET_DEPOSIT_AMOUNT, 'getDepositAmountStatus', {
    onSuccess: (state, action) => {
      const data = mapDepositAmount(action.data);
      return {
        ...state,
        depositUsdtAmount: data.usdtAmount,
        depositAnkrAmount: data.ankrAmount,
        depositUsdAmount: data.usdAmount,
        depositSaveUsdAmount: data.saveUsd,
        depositAmountMethod: action.meta?.id as PaymentMethod,
      };
    },
  }),

  ...createAPIReducer<
    IPayState,
    { data: IApiEthPaymentStatus | undefined } & IExtendedRequestAction
  >(PayActionTypes.GET_ETH_PAYMENT_STATUS, 'ethPaymentStatusStatus', {
    onSuccess: (state, action) => {
      const data = action.data ? mapEthPaymentStatus(action.data) : undefined;

      return {
        ...state,
        ethPaymentAddress: data?.address,
        ethPaymentAmount: data?.amount,
        ethPaymentUsdAmount: data?.usd,
        ethPaymentMethod: data?.paymentMethod,
      };
    },
  }),

  ...createAPIReducer<
    IPayState,
    { data: IApiDepositList } & IExtendedRequestAction
  >(PayActionTypes.GET_DEPOSIT_LIST, 'getDepositListStatus', {
    onSuccess: (state, action) => {
      return {
        ...state,
        depositList: mapDepositList(action.data),
      };
    },
  }),

  ...createAPIReducer<
    IPayState,
    IExtendedAxiosResponse<IApiPrepareExtendOrderData>
  >(PayActionTypes.EXTEND_APP, 'extendAppStatus', {
    onSuccess: (state, action) => {
      const { amount: extendAmount, paymentMethod } = mapExtendPlan(
        action.data,
      );

      return {
        ...state,
        extendAmount,
        extendPaymentMethod: paymentMethod,
      };
    },
  }),

  ...createAPIReducer<
    IPayState,
    IExtendedAxiosResponse<IApiPrePaymentResponse>
  >(PayActionTypes.GET_PRE_PAYMENT_LIST, 'getPrePaymentDepositListStatus', {
    onSuccess: (state, action) => {
      return {
        ...state,
        prePaymentDepositList: mapPaymentList(action.data),
      };
    },
  }),

  ...createAPIReducer<IPayState, IExtendedAxiosResponse<IApiAppPaymentData>>(
    PayActionTypes.GET_APP_PAYMENT_INFO,
    'getAppPaymentInfoStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          appPaymentInfo: mapAppPaymentData(action.data),
        };
      },
    },
  ),

  ...createAPIReducer<IPayState, IExtendedAxiosResponse<{ balance: string }>>(
    PayActionTypes.GET_TRIAL_AMOUNT,
    'getTrialAmountStatus',
    {
      onSuccess: (state, action) => {
        return {
          ...state,
          trialAmount: new BigNumber(action.data.balance),
        };
      },
    },
  ),
  [PayActionTypes.RESET_TRIAL_AMOUNT]: (state: IPayState) => ({
    ...state,
    trialAmount: new BigNumber(0),
  }),
});

export { payReducer };
