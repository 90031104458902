export enum PaymentStep {
  NONE,
  SELECT_PAYMENT_METHOD,
  CHANGING_PAYMENT_METHOD_CONFIRMATION,
  CREATING_ESCROW,
  DEPOSIT,
  CREATE_CARD,
  SELECT_CARD,
  DELETE_CARD,
}

export enum PaymentConfigurationStep {
  INIT = 'init',
  CONFIGURING = 'configuring',
  DONE = 'done',
}

export enum PaymentMethod {
  DEPOSIT_CREDIT_CARD = 'USD',
  ANKR_CREDIT = 'ANKR_CREDIT',
  ANKR_ERC20 = 'ANKR_ERC20',
  CREDIT_CARD = 'CREDIT_CARD',

  // deprecated
  USDT_ERC20 = 'USDT_ERC20',
  ANKR = 'ANKR',
  USDT = 'USDT',
}

export type PaymentMode = 'deposit' | 'daily';

export const PRECISION = 2;

export enum DeployStep {
  INIT = 'INIT',
  DEPOSIT = 'DEPOSIT',
  DEPOSIT_CHOOSE_METHOD = 'DEPOSIT_CHOOSE_METHOD',
  DEPOSIT_ADD_CREDIT_CARD = 'DEPOSIT_ADD_CREDIT_CARD',
  DEPOSIT_ADD_CREDIT_CARD_ADDRESS = 'DEPOSIT_ADD_CREDIT_CARD_ADDRESS',
  DAILY = 'DAILY',
  DAILY_NO_CREDIT_CARD = 'DAILY_NO_CREDIT_CARD',
  DAILY_NO_CREDIT_CARD_ADDRESS = 'DAILY_NO_CREDIT_CARD_ADDRESS',
  DAILY_ADD_CREDIT_CARD = 'DAILY_ADD_CREDIT_CARD',
  DAILY_ADD_CREDIT_CARD_ADDRESS = 'DAILY_ADD_CREDIT_CARD_ADDRESS',
  DAILY_CHANGE_CREDIT_CARD = 'DAILY_CHANGE_CREDIT_CARD',
}

export enum DailyStep {}

export const DEPOSIT_PERIOD_FIELD_NAME = 'period';
export const EXTEND_PERIOD_FIELD_NAME = 'period';
export const DEPOSIT_PERIOD_INIT_VALUE = 1;
export const PAYMENT_METHOD_FIELD_NAME = 'depositPaymentMethod';
