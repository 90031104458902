import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const UnauthenticatedButtonStyles = (theme: Theme): StyleRules => ({
  button: {
    width: 100,
    height: 48,
    borderRadius: 54,
    border: `1px solid rgba(0, 0, 0, 0.2)`,
  },
  text: {
    color: 'black',
    fontSize: 16,
    fontWeight: 500,
    marginLeft: theme.spacing(1),
  },
});

export { UnauthenticatedButtonStyles };
