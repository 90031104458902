import { CPU, Megabytes } from 'common/types';
import { IApiAppHook } from 'common/modules/apps/types';

export type ChartRepo = 'stable' | 'user';

export type ChartStatus = 'coming' | 'beta' | 'alpha' | 'released';

export enum ChartCategory {
  Staking = 'Staking',
  Developer = 'Developer',
  Other = 'Other',
}

export interface IApiResources {
  cpu_recommend: string;
  mem_recommend: string;
  storage_recommend: string;
  cpu_min: string;
  mem_min: string;
  storage_min: string;
  cpu_max: string;
  mem_max: string;
  storage_max: string;
}

interface IResources {
  recommendedCPU: CPU;
  recommendedMemory: Megabytes;
  recommendedStorage: Megabytes;
  cpuMin: CPU;
  memMin: Megabytes;
  storageMin: Megabytes;
  cpuMax: CPU;
  memMax: Megabytes;
  storageMax: Megabytes;
}

interface IApiMetaDataChart {
  description: string;
  category: string;
  nodeFamily: string;
  status: ChartStatus;
}

export interface INodeLinks {
  Documentation: string;
  'Official website': string;
  Telegram: string;
  Twitter: string;
  Discord: string;
  'Community website': string;
  'Volunteer Computing': string;
  Gitter: string;
  Medium: string;
  Github: string;
  'Keep network website': string;
  'tBTC website': string;
  Chat: string;
  Slack: string;
  'Telegram ANN Channel': string;
  'Telegram EN Group': string;
}

export interface IApiNodeInformation {
  faq: Record<number, { answer: string; question: string }>;
  overview: {
    benefits: string;
    cpu: number;
    deploy_desc: string;
    description: string;
    links: INodeLinks;
    mem: number;
    requirement: string;
    storage: number;
  };
}

export interface IOverviewChart {
  description?: string;
  benefits?: string;
  specifications?: string;
  requirement?: string;
  cpu: number;
  mem: number;
  storage: number;
}

export interface IChart extends IResources {
  id: string;
  name: string;
  nodeFamily?: string;
  repository: ChartRepo;
  description: string;
  iconUrl: string;
  iconOnlyUrl: string;
  version: string;
  appVersion: string;
  valuesYaml: string;
  pricing: number;
  metaCategory: ChartCategory;
  metaDescription?: string;
  metaStatus?: ChartStatus;
  faq?: Record<number, { answer: string; question: string }>;
  overview?: IOverviewChart;
  links?: INodeLinks;
}

export interface IApiChart extends IApiResources {
  id: string;
  name: string;
  description: string;
  repository: ChartRepo;
  icon_url: string;
  icononly_url: string;
  version: string;
  app_version: string;
  app_hooks: IApiAppHook[];
  values_yaml: string;
  pricing: number;
  ankr_meta_data?: IApiMetaDataChart;
  node_info?: IApiNodeInformation;
}

export interface IChartID {
  repository: string;
  name: string;
  version: string;
}

export interface IApiCustomValue {
  key: string;
  value: string;
}

export interface ICustomValue extends IApiCustomValue {
  label: string;
  title?: string;
  description?: string;
  placeholder?: string;
  overrideValue?: string;
}

export interface IApiChartDetails extends IApiResources {
  name: string;
  repository: ChartRepo;
  description: string;
  version: string;
  versions?: IChartVersion[];
  app_version: string;
  icon_url: string;
  readme_md: string;
  values_yaml: string;
  custom_values: IApiCustomValue[];
}

export interface IApiChartSaveAs {
  src_repo: string;
  src_ver: string;
  src_name: string;
  dst_repo: ChartRepo;
  dst_ver: string;
  dst_name: string;
  values_yaml: string;
  team_id: string;
}

export interface IApiDownloadChart {
  chart_file: string;
}

export interface IChartVersion {
  chart: string;
  app: string;
}

export interface IChartDetails extends IResources {
  name: string;
  repository: string;
  versions: IChartVersion[];
  readme: string;
  values: string;
  customValues: ICustomValue[];
  valuesYaml: string;
}

/***
 * Request types
 */

export interface IFetchChartsParams {
  repository: ChartRepo[];
  teamId: string;
  isEnterprise: boolean;
  level: string;
}

/***
 * Response types
 */

export interface IApiChartsFetchResponse {
  charts: IApiChart[];
  total_count: number;
}
