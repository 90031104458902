/* eslint-disable @typescript-eslint/camelcase */
import { AnyAction } from 'redux';
import * as Sentry from '@sentry/browser';
import {
  extractRequestError,
  requestFailed,
  requestInactive,
  requestInProgress,
  RequestStatus,
  requestSuccessful,
} from 'common/utils/requestStatus';
import {
  ISignInRequestParams,
  UserActionTypes,
} from 'auth/store/actions/UserActions';
import { createAPIReducer, createReducer } from 'common/utils/reduxUtils';
import {
  IApiUserResponse,
  IUserAvatar,
  mapApiResponseToUserInfo,
} from 'auth/store/mappings/userMappings';
import { TokenActionTypes } from '../actions/TokensActions';
import { parseJWTToken } from '../../utils';
import {
  IApiErrorAction,
  IExtendedAxiosResponse,
  IExtendedRequestAction,
} from 'common/types';

export type TwoFactorStatus = 'login' | 'validation' | 'success';

export interface ISigninCodeInvalidResponse {
  error: 'Invalid_code';
  error_description: 'code is invalid';
}

export interface ISigninEncryptedCodeResponse {
  encrypt_token: string;
}

export interface ISignInResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: 'Bearer';
}

export interface IUserState {
  loginStatus: RequestStatus;
  verifyStatus: RequestStatus;
  refreshStatus: RequestStatus;
  signUpStatus: RequestStatus;
  confirmRegisterStatus: RequestStatus;
  recoverPasswordStatus: RequestStatus; // Step 1 password recovery
  resetPasswordStatus: RequestStatus; // Step 2 password recovery
  changePasswordStatus: RequestStatus;
  twoFactorChangePasswordStataus: RequestStatus;
  setAvatarStatus: RequestStatus;

  // Properties:
  id: string;
  email: string;
  confirmEmail: string;
  waitingEmail: string;
  phone: string;
  name: string;
  depositAddress: string;
  avatar: IUserAvatar;
  loginCount: number;
  signinTime: Date;
  updateCredentialsStatus: RequestStatus;
  nameRequestStatus: RequestStatus;
  addressRequestStatus: RequestStatus;
  emailRequestStatus: RequestStatus;
  emailChangeVerifyStatus: RequestStatus;
  twoFactorStatus: TwoFactorStatus;
  encryptToken: string;
}

const userReducerInitialStateUnstored = {
  loginStatus: requestInactive(),
  verifyStatus: requestInactive(),
  refreshStatus: requestInactive(),
  signUpStatus: requestInactive(),
  confirmRegisterStatus: requestInactive(),
  recoverPasswordStatus: requestInactive(),
  resetPasswordStatus: requestInactive(),
  changePasswordStatus: requestInactive(),
  twoFactorChangePasswordStataus: requestInactive(),
  setAvatarStatus: requestInactive(),
  updateCredentialsStatus: requestInactive(),
  nameRequestStatus: requestInactive(),
  addressRequestStatus: requestInactive(),

  emailRequestStatus: requestInactive(),
  emailChangeVerifyStatus: requestInactive(),
};

export const userReducerStorageBlacklist = Object.keys(
  userReducerInitialStateUnstored,
);

const initialState: IUserState = {
  ...userReducerInitialStateUnstored,
  id: '',
  email: '',
  confirmEmail: '',
  waitingEmail: '',
  phone: '',
  name: '',
  loginCount: 0,
  avatar: { url: '', bgColor: '#ffffff' },
  signinTime: new Date(0),
  twoFactorStatus: 'login',
  encryptToken: '',
  depositAddress: '',
};

export const userReducer = createReducer(initialState, {
  ...createAPIReducer<
    IUserState,
    IExtendedAxiosResponse<
      | ISigninCodeInvalidResponse
      | ISignInResponse
      | ISigninEncryptedCodeResponse
    >,
    IExtendedRequestAction<ISignInRequestParams>
  >(UserActionTypes.SIGNIN, 'loginStatus', {
    onRequest: (state: IUserState, action) => ({
      ...state,
      signinTime: new Date(0),
      twoFactorStatus:
        state.twoFactorStatus === 'login' && action?.request?.data?.code === ''
          ? 'validation'
          : state.twoFactorStatus,
    }),
    onSuccess: (state, action) => {
      if (action.response.status === 202) {
        if (
          state.twoFactorStatus === 'validation' &&
          (action.response.data as ISigninCodeInvalidResponse).error ===
            'Invalid_code'
        ) {
          return {
            ...state,
            twoFactorStatus: 'validation',
            loginStatus: requestFailed(
              (action.response.data as ISigninCodeInvalidResponse)
                .error_description,
            ),
          };
        }

        return {
          ...state,
          twoFactorStatus: 'validation',
          encryptToken:
            (action.response.data as ISigninEncryptedCodeResponse)
              .encrypt_token ?? '',
          loginStatus: requestInProgress(),
        };
      }
      return {
        ...state,
        loginStatus: requestSuccessful(),
        signinTime: new Date(),
        loginCount: state.loginCount + 1,
        email: action.meta?.email ?? '',
        twoFactorStatus: 'success',
        encryptToken: '',
      };
    },
    onError: state => ({
      ...state,
      signinTime: new Date(0),
    }),
    onReset: state => ({
      ...state,
      loginStatus: requestInactive(),
      twoFactorStatus: 'login',
    }),
  }),
  ...createAPIReducer<IUserState, IExtendedAxiosResponse<any>>(
    UserActionTypes.VERIFY_USER,
    'verifyStatus',
    {
      onRequest: (state: IUserState) => ({
        ...state,
        signinTime: new Date(0),
      }),
      onSuccess: (state: IUserState) => ({
        ...state,
        signinTime: new Date(),
      }),
      onError: (state: IUserState) => ({
        ...state,
        signinTime: new Date(0),
      }),
    },
  ),
  [UserActionTypes.SIGNIN_RESET_STATUS]: (state: IUserState): IUserState => ({
    ...state,
    loginStatus: requestInactive(),
  }),
  [UserActionTypes.USER_REFRESH]: (state: IUserState): IUserState => ({
    ...state,
    refreshStatus: requestInProgress(),
  }),
  [UserActionTypes.USER_REFRESH_SUCCESS]: (
    state: IUserState,
    action: { data: IApiUserResponse },
  ): IUserState => ({
    ...state,
    refreshStatus: requestSuccessful(),
    ...mapApiResponseToUserInfo(action.data),
  }),
  [UserActionTypes.USER_REFRESH_ERROR]: (
    state: IUserState,
    action: IApiErrorAction,
  ): IUserState => ({
    ...state,
    refreshStatus: requestFailed(extractRequestError(action)),
  }),

  // Logout in any case, eliminate all data
  [UserActionTypes.SIGNOUT]: (state: IUserState) => {
    Sentry.configureScope((scope: Sentry.Scope) => {
      scope.setUser({ email: undefined, id: undefined });
    });
    return {
      ...initialState,
      loginCount: state.loginCount,
      // TODO Research is it accceptible to store private data after logout
      // Store old phone to show in the login form
      email: state.email,
    };
  },
  ...createAPIReducer<
    IUserState,
    {
      meta: {
        email: string;
      };
    }
  >(UserActionTypes.SIGNUP, 'signUpStatus', {
    onRequest: (state: IUserState) => ({
      ...state,
      loginStatus: requestInactive(),
    }),
    onSuccess: (state, { meta: { email } }) => ({
      ...state,
      waitingEmail: email,
    }),
    onError: state => ({
      ...state,
      signinTime: new Date(0),
    }),
  }),

  [UserActionTypes.ACCOUNT_VERIFY]: (state: IUserState): IUserState => ({
    ...state,
    confirmRegisterStatus: requestInProgress(),
  }),
  [UserActionTypes.ACCOUNT_VERIFY_SUCCESS]: (
    state: IUserState,
    action: AnyAction,
  ): IUserState => ({
    ...state,
    confirmRegisterStatus: requestInactive(),
    confirmEmail: action.data.email,
  }),
  [UserActionTypes.ACCOUNT_VERIFY_ERROR]: (
    state: IUserState,
    action: IApiErrorAction,
  ): IUserState => ({
    ...state,
    confirmRegisterStatus: requestFailed(extractRequestError(action)),
  }),

  ...createAPIReducer<IUserState>(
    UserActionTypes.RECOVER_PASSWORD,
    'recoverPasswordStatus',
    {
      onSuccess: (state, action) => {
        if (action.meta.requestAction.request instanceof Array) {
          return { ...state };
        }

        return {
          ...state,
          waitingEmail: action.meta.requestAction.request?.data?.email,
        };
      },
    },
  ),

  ...createAPIReducer<IUserState>(
    UserActionTypes.RESET_PASSWORD,
    'resetPasswordStatus',
    {
      onSuccess: (store, action) => ({
        ...store,
        confirmEmail: action.data.email,
      }),
    },
  ),
  ...createAPIReducer<IUserState>(
    UserActionTypes.CHANGE_PASSWORD,
    'changePasswordStatus',
    {},
  ),
  [UserActionTypes.SET_AVATAR]: (state: IUserState): IUserState => ({
    ...state,
    setAvatarStatus: requestInProgress(),
  }),
  [UserActionTypes.SET_AVATAR_SUCCESS]: (
    state: IUserState,
    action: { data: IApiUserResponse },
  ): IUserState => ({
    ...state,
    setAvatarStatus: requestInactive(),
    ...mapApiResponseToUserInfo(action.data),
  }),
  [UserActionTypes.SET_AVATAR_ERROR]: (
    state: IUserState,
    action: IApiErrorAction,
  ): IUserState => ({
    ...state,
    setAvatarStatus: requestFailed(extractRequestError(action)),
  }),

  [UserActionTypes.UPDATE_USERNAME_SUCCESS]: (
    state: IUserState,
  ): IUserState => {
    return {
      ...state,
      nameRequestStatus: requestInactive(),
    };
  },
  [UserActionTypes.UPDATE_USERNAME]: (state: IUserState): IUserState => ({
    ...state,
    nameRequestStatus: requestInProgress(),
  }),
  [UserActionTypes.UPDATE_USERNAME_ERROR]: (
    state: IUserState,
    action: IApiErrorAction,
  ): IUserState => ({
    ...state,
    nameRequestStatus: requestFailed(extractRequestError(action)),
  }),

  [UserActionTypes.VERIFY_EMAIL_CHANGE_SUCCESS]: (
    state: IUserState,
    action: AnyAction,
  ): IUserState => {
    try {
      return {
        ...state,
        confirmEmail: action.data.email,
      };
    } catch (e) {
      return state;
    }
  },

  [TokenActionTypes.SSO_CHECK_ERROR]: (state: IUserState): IUserState => ({
    ...state,

    name: '',
    email: '',
  }),

  [TokenActionTypes.SSO_CHECK_SUCCESS]: (
    state: IUserState,
    {
      meta: {
        requestAction: {
          request: {
            params: { access_token },
          },
        },
      },
    }: {
      meta: {
        requestAction: {
          request: {
            params: {
              access_token: string;
            };
          };
        };
      };
    },
  ): IUserState => {
    const parsedToken = parseJWTToken(access_token);

    return {
      ...state,
      name: parsedToken.Name,
      email: parsedToken.Email,
    };
  },
  [UserActionTypes.UPDATE_EMAIL_RESET]: (state: IUserState): IUserState => ({
    ...state,
    emailRequestStatus: requestInactive(),
  }),
  ...createAPIReducer<IUserState, IExtendedAxiosResponse<any>>(
    UserActionTypes.UPDATE_EMAIL,
    'emailRequestStatus',
    {
      // onSuccess: (state, action): IUserState => ({
      //   ...state,
      //   email: action.meta.requestAction.request.data.new_email,
      // }),
    },
  ),
  ...createAPIReducer<IUserState, any>(
    UserActionTypes.VERIFY_EMAIL_CHANGE,
    'emailChangeVerifyStatus',
    {},
  ),
});
