import * as Sentry from '@sentry/browser';

import { IAPIZendeskUser, IZendeskUser } from '../types/zendeskUserTypes';

export const normalizeZendeskUser = (user: IAPIZendeskUser): IZendeskUser => {
  return {
    id: user.id,
    url: user.url,
    name: user.name,
    userId: user.external_id,
    alias: user.alias,
    createdAt: new Date(user.created_at),
    updatedAt: new Date(user.updated_at),
    active: user.active,
    verified: user.verified,
    shared: user.shared,
    sharedAgent: user.shared_agent,
    locale: user.locale,
    localeId: user.locale_id,
    timeZone: user.time_zone,
    lastLoginAt: user.last_login_at,
    email: user.email,
    phone: user.phone,
    signature: user.signature,
    details: user.details,
    notes: user.notes,
    organizationId: user.organization_id,
    role: user.role,
    moderator: user.moderator,
    ticketRestriction: user.ticket_restriction,
    tags: user.tags,
    restrictedAgent: user.restricted_agent,
    suspended: user.suspended,
    photo: user.photo?.content_url,
  };
};

export const normalizeZendeskUsers = (data: {
  users: IAPIZendeskUser[];
}): IZendeskUser[] => {
  try {
    return data?.users?.length > 0 ? data.users.map(normalizeZendeskUser) : [];
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export const normalizeSingleZendeskUser = (data: {
  users: IAPIZendeskUser[];
}): IZendeskUser | undefined => {
  try {
    const response = normalizeZendeskUsers(data);

    const firstResponse = response?.[0];
    return firstResponse;
  } catch (error) {
    Sentry.captureException(error);
    return undefined;
  }
};
