import { fork, takeEvery } from 'redux-saga/effects';
import { apiSaga } from 'common/store/effects/apiSaga';
import { setRedirects } from './redirectSagas';
import {
  notifyModifyInfoSuccessSaga,
  updateTeamNotificationSaga,
} from 'common/modules/apps/effects/appsSagas';
import { snackbarsSaga } from 'common/store/effects/snackbarsSaga';
import { startOfflineNotifications } from 'common/store/effects/offlineNotificationSaga';
import { userSaga } from 'auth/store/effects/userEffects';
import { createDriver } from 'redux-saga-requests-mock';
import { ServerStatusMock } from 'store/effects/mocks/ServerStatusMock';
import { Milliseconds } from 'common/types';
import { promiseActionSaga } from 'common/store/effects/promisesSaga';
import { teamSaga } from 'common/modules/teams/effects/teamSaga';
import { paySaga } from 'common/modules/billing/effects/paySaga';
import { cardPaySaga } from 'common/modules/billing/effects/cardPaySaga';
import { runExternalTokenSaga } from 'auth/store/effects/externalTokenSaga';
import { zendeskSaga } from 'common-web/modules/zendesk/effects/zendeskSaga';
import { APIServiceMock } from 'modules/APIMarketUI/store/effects/mock/APIServiceMock';
import {
  runCreateArchiveOrderSaga,
  runCreateProjectSaga,
  runDeleteAPISaga,
  runFetchAPIListSaga,
  runUpgradePlanSaga,
} from 'modules/APIMarketUI/store/effects/apiMarketSaga';
import { APIMarketActionTypes } from 'modules/APIMarketUI/store/APIMarketActions';
import { twoFactorSaga } from 'auth/store/effects/TwoFactorSaga';

function* subscribeSaga() {
  yield fork(zendeskSaga);

  yield fork(setRedirects);
  yield fork(userSaga);
  yield fork(runExternalTokenSaga);
  yield fork(twoFactorSaga);
  yield fork(teamSaga); //?
  yield fork(paySaga); //?
  yield fork(cardPaySaga); //?
  // yield fork(ankrNativePaySaga); //?
  // yield fork(ankrCreditPaySaga); //?

  yield fork(promiseActionSaga);
  yield takeEvery(
    APIMarketActionTypes.CREATE_API_PROJECT,
    runCreateProjectSaga,
  );
  yield takeEvery(
    APIMarketActionTypes.UPGRADE_API_MARKET_PLAN,
    runUpgradePlanSaga,
  );
  yield takeEvery(
    APIMarketActionTypes.CREATE_ARCHIVE_ORDER,
    runCreateArchiveOrderSaga,
  );
  yield takeEvery(
    APIMarketActionTypes.UPGRADE_API_MARKET_PLAN,
    runUpgradePlanSaga,
  );
  yield takeEvery(APIMarketActionTypes.DELETE_API, runDeleteAPISaga);
  yield takeEvery(APIMarketActionTypes.FETCH_API_LIST, runFetchAPIListSaga);
}

const MOCK_REQUEST_TIMEOUT: Milliseconds = 1000;

/**
 * Main saga
 */
const API_SAGA_CONFIG = {
  baseURL: (process.env.REACT_APP_API_BASE as string) || '',
  drivers: {
    mock: createDriver(
      {
        ...ServerStatusMock,
        ...APIServiceMock,
      },
      {
        timeout: MOCK_REQUEST_TIMEOUT,
      },
    ),
  },
  responseInterceptors: [],
  requestInterceptors: [],
};

function* rootSaga() {
  yield fork(apiSaga, API_SAGA_CONFIG, subscribeSaga);

  yield fork(notifyModifyInfoSuccessSaga);
  yield fork(updateTeamNotificationSaga);

  yield fork(snackbarsSaga);
  yield fork(startOfflineNotifications);
}

export { rootSaga };
