import React from 'react';
import { QRCode as QRCodeSvg } from 'react-qr-svg';
import withStyles from '@material-ui/core/styles/withStyles';
import { QRCodeStyles } from 'common-web/components/QRCode/QRCodeStyles';
import { Classes } from 'jss';
import classNames from 'classnames';

interface IQRCodeProps {
  value: string;
  classes?: Classes;
  className?: string;
}
function QRCodeComponent({ value, classes = {}, className }: IQRCodeProps) {
  return (
    <QRCodeSvg
      bgColor="#FFFFFF"
      fgColor="#000000"
      level="H"
      className={classNames(className, classes.root)}
      value={value}
    />
  );
}

const QRCode = withStyles(QRCodeStyles)(QRCodeComponent);

export { QRCode };
