import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Classes } from 'jss';
import classNames from 'classnames';
import { useTimeout } from 'common/utils/hooks/useTimeout';
import { useState } from 'react';
import { loadingEllipsisStyles } from './LoadingEllipsisStyles';
import { Milliseconds } from 'common/types';

const MIN_NOT_DISPLAY_TIME = 300; // 100 msec

type ProgressSize = 'small' | 'default';

interface IProgressProps {
  classes: Classes;
  size?: ProgressSize;
  displaySince?: Milliseconds;
}

const LoadingEllipsisComponent = ({
  classes,
  size = 'default',
  displaySince = MIN_NOT_DISPLAY_TIME,
}: IProgressProps) => {
  const [visible, setVisible] = useState(false);
  useTimeout(() => {
    setVisible(true);
  }, displaySince);
  if (!visible) {
    return null;
  }
  return (
    <div className={classNames(classes.root, size && classes[size])}>
      <div className={classNames(classes.progress, size && classes[size])}>
        <div>
          <div />
        </div>
        <div>
          <div />
        </div>
        <div>
          <div />
        </div>
        <div>
          <div />
        </div>
        <div>
          <div />
        </div>
      </div>
    </div>
  );
};

const LoadingEllipsis = withStyles(loadingEllipsisStyles)(
  LoadingEllipsisComponent,
);

export { LoadingEllipsis };
