import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useProgressBarStyles = makeStyles<Theme, { widthBar: number }>(
  theme => ({
    component: {
      position: 'relative',

      paddingBottom: 14,
      boxSizing: 'border-box',

      '&::before': {
        position: 'absolute',
        bottom: 0,
        content: '""',

        display: 'block',
        width: '100%',
        height: 2,

        borderRadius: 10,

        backgroundColor: '#E9F1FD',
      },

      '&::after': {
        position: 'absolute',
        bottom: 0,
        content: '""',

        display: 'block',
        width: props => `${props.widthBar}%`,
        height: 2,

        borderRadius: 10,

        backgroundColor: theme.palette.primary.main,

        transitionTimingFunction: 'linear',
        transitionDuration: '200ms',
        transitionProperty: 'width',
      },
    },

    title: {
      color: theme.palette.primary.main,
      textAlign: 'center',
    },
  }),
);
