import { withErrorHandler } from 'common-web/HOC/withErrorHandler';
import { LoadableScreen } from 'common-web/components/LoadableScreen';
import React from 'react';
import {
  AUTH_PATH,
  EXPIRED_PATH,
  LOGIN_PATH,
  MODE_PATH,
  PASSWORD_RECOVERY_PATH,
  PASSWORD_RECOVERY_SENT_PATH,
  RESET_PASSWORD_PATH,
  RESET_PASSWORD_SUCCESS_PATH,
  SIGN_UP_PATH,
  SIGN_UP_SUCCESS_PATH,
  USER_CONFIRM_REGISTRATION_PATH,
  USER_REGISTRATION_SUCCESS_PATH,
} from 'auth-web/core/const';
import loadable, { LoadableComponent } from '@loadable/component';
import { createAuthRoutes } from 'auth-web/nav/createAuthRoutes';
import { authTheme } from '../themes/authTheme';
import { LoadableNotFoundPaper } from './CommonLoadables';
import { withApiLayout } from '../components/Layout';

const LoadableExpired = withApiLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('auth-web/screens/Expired').then(module => module.Expired),
      {
        fallback: <LoadableScreen />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadableModeSwitcher = withApiLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/ApiAuthUI/screens/Mode').then(module => module.ApiMode),
      {
        fallback: <LoadableScreen />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadableLogin = withApiLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/ApiAuthUI/screens/Login').then(
          module => module.ApiLogin,
        ),
      {
        fallback: <LoadableScreen />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadableSignup = withApiLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/ApiAuthUI/screens/SignUp').then(
          module => module.ApiSignUp,
        ),
      {
        fallback: <LoadableScreen />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadablePasswordRecovery = withApiLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/ApiAuthUI/screens/RecoverPassword').then(
          module => module.ApiRecoverPassword,
        ),
      {
        fallback: <LoadableScreen />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadablePasswordRecoverySent = withApiLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/ApiAuthUI/screens/PasswordRecoverySent').then(
          module => module.ApiPasswordRecoverySent,
        ),
      {
        fallback: <LoadableScreen />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadableConfirmRegister = withApiLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/ApiAuthUI/screens/ConfirmRegister').then(
          module => module.ApiConfirmRegister,
        ),
      {
        fallback: <LoadableScreen />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadableRegisterSuccess = withApiLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/ApiAuthUI/screens/RegisterSuccess').then(
          module => module.ApiRegisterSuccess,
        ),
      {
        fallback: <LoadableScreen />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadableResetPassword = withApiLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/ApiAuthUI/screens/ResetPassword').then(
          module => module.ApiResetPassword,
        ),
      {
        fallback: <LoadableScreen />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadableSignUpSuccess = withApiLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/ApiAuthUI/screens/CreateAccountSuccess').then(
          module => module.ApiCreateAccountSuccess,
        ),
      {
        fallback: <LoadableScreen />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadablePasswordResetSuccess = withApiLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('modules/ApiAuthUI/screens/PasswordResetSuccess').then(
          module => module.ApiPasswordResetSuccess,
        ),
      {
        fallback: <LoadableScreen />,
      },
    ) as LoadableComponent<any>,
  ),
);

export const ApiAuthRoutes = createAuthRoutes(authTheme, {
  components: {
    expiredToken: LoadableExpired,
    signIn: LoadableLogin,
    modeSwitcher: LoadableModeSwitcher,
    signUp: LoadableSignup,
    passwordRecovery: LoadablePasswordRecovery,
    passwordRecoverySent: LoadablePasswordRecoverySent,
    registerConfirmation: LoadableConfirmRegister,
    resetPassword: LoadableResetPassword,
    registerSuccess: LoadableRegisterSuccess,
    passwordResetSuccess: LoadablePasswordResetSuccess,
    signUpSuccess: LoadableSignUpSuccess,
    notFound: LoadableNotFoundPaper,
  },
  paths: {
    expiredTokenPath: EXPIRED_PATH,
    signInPath: LOGIN_PATH,
    modeSwitcherPath: MODE_PATH,
    signUpPath: ['/', `${AUTH_PATH}/`, SIGN_UP_PATH],
    passwordRecoveryPath: PASSWORD_RECOVERY_PATH,
    passwordRecoverySentPath: PASSWORD_RECOVERY_SENT_PATH,
    registerConfirmationPath: USER_CONFIRM_REGISTRATION_PATH,
    resetPasswordPath: RESET_PASSWORD_PATH,
    registerSuccessPath: USER_REGISTRATION_SUCCESS_PATH,
    passwordResetSuccessPath: RESET_PASSWORD_SUCCESS_PATH,
    signUpSuccessPath: SIGN_UP_SUCCESS_PATH,
  },
});
