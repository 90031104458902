import { withErrorHandler } from 'common-web/HOC/withErrorHandler';
import { LoadingIndicator } from 'common-web/components/LoadingIndicator';
import React from 'react';
import { Route, Switch } from 'react-router';
import { PrivateRoute } from 'common-web/nav/PrivateRoute';
import { SETTINGS_PREFIX } from 'common/core/webPaths';
import { t } from 'common/utils/intl';
import loadable, { LoadableComponent } from '@loadable/component';
import { CHANGE_EMAIL_PATH } from 'auth-web/core/const';
import { withApiLayout } from '../components/Layout';
import { LoadableNotFoundPaper } from './CommonLoadables';

const LoadableSettingsContainer = withApiLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('common-web/modules/SettingsUI/screens/Settings').then(
          module => module.Settings,
        ),
      {
        fallback: <LoadingIndicator />,
      },
    ) as LoadableComponent<any>,
  ),
);

const LoadableChangeEmailContainer = withApiLayout(
  withErrorHandler()(
    loadable(
      async () =>
        import('common-web/modules/SettingsUI/screens/ChangeEmail').then(
          module => module.ChangeEmail,
        ),
      {
        fallback: <LoadingIndicator />,
      },
    ) as LoadableComponent<any>,
  ),
);

export const SettingsRoutes = ({ isSignedIn }: { isSignedIn: boolean }) => {
  return (
    <Switch>
      <PrivateRoute
        isSignedIn={isSignedIn}
        exact={true}
        path={SETTINGS_PREFIX}
        component={LoadableSettingsContainer}
        breadcrumbs={[t('navigation.profile')]}
      />
      <Route
        exact={true}
        path={CHANGE_EMAIL_PATH}
        component={LoadableChangeEmailContainer}
        breadcrumbs={[t('navigation.profile')]}
      />
      <PrivateRoute
        isSignedIn={isSignedIn}
        component={LoadableNotFoundPaper}
        breadcrumbs={[t('navigation.404')]}
      />
    </Switch>
  );
};
