/* eslint-disable @typescript-eslint/camelcase */
import { createAction } from 'common/utils/reduxUtils';
import { ISingUp, mapApiSignUp } from '../mappings/userMappings';
import { IExtendedRequestAction } from 'common/types/requestTypes';
import queryString from 'query-string';
import { PhoneUserActionTypes } from './PhoneUserActions';
import { SETTINGS_PREFIX } from 'common/core/webPaths';

export type SigninType = 'user' | 'provider';

export interface ISigninParams {
  email: string;
  password: string;
  persist?: boolean;
  redirectOnSuccess?: string;
  type?: SigninType;
  code?: string;
}

export interface ISignInRequestParams {
  username: string;
  password: string;
  grant_type: 'password';
  code?: string;
}

export const UserActionTypes = {
  USER_REDIRECT_TO_LOGIN: 'USER_REDIRECT_TO_LOGIN',

  SIGNIN: 'SIGNIN',
  SIGNIN_ERROR: 'SIGNIN_ERROR',
  SIGNIN_SUCCESS: 'SIGNIN_SUCCESS',
  SIGNIN_RESET: 'SIGNIN_RESET',
  SIGNIN_RESET_STATUS: 'SIGNIN_RESET_STATUS',

  VERIFY_USER: 'VERIFY_USER',
  VERIFY_USER_ERROR: 'VERIFY_USER_ERROR',
  VERIFY_USER_SUCCESS: 'VERIFY_USER_SUCCESS',
  VERIFY_USER_RESET: 'VERIFY_USER_RESET',

  GET_TOKEN_AFTER_CHANGE_PASSWORD: 'GET_TOKEN_AFTER_CHANGE_PASSWORD',
  GET_TOKEN_AFTER_CHANGE_PASSWORD_ERROR:
    'GET_TOKEN_AFTER_CHANGE_PASSWORD_ERROR',
  GET_TOKEN_AFTER_CHANGE_PASSWORD_SUCCESS:
    'GET_TOKEN_AFTER_CHANGE_PASSWORD_SUCCESS',

  SIGNOUT: 'SIGNOUT',
  WANT_SIGNOUT: 'WANT_SIGNOUT',

  SIGNUP: 'SIGNUP',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_RESET: 'SIGNUP_RESET',

  ACCOUNT_VERIFY: 'ACCOUNT_VERIFY',
  ACCOUNT_VERIFY_SUCCESS: 'ACCOUNT_VERIFY_SUCCESS',
  ACCOUNT_VERIFY_ERROR: 'ACCOUNT_VERIFY_ERROR',

  RECOVER_PASSWORD: 'RECOVER_PASSWORD',
  RECOVER_PASSWORD_RESET: 'RECOVER_PASSWORD_RESET',

  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',
  CHANGE_PASSWORD_RESET: 'CHANGE_PASSWORD_RESET',

  RESET_PASSWORD: 'RESET_PASSWORD',

  USER_REFRESH: 'USER_REFRESH',
  USER_REFRESH_SUCCESS: 'USER_REFRESH_SUCCESS',
  USER_REFRESH_ERROR: 'USER_REFRESH_ERROR',

  SET_AVATAR: 'SET_AVATAR',
  SET_AVATAR_SUCCESS: 'SET_AVATAR_SUCCESS',
  SET_AVATAR_ERROR: 'SET_AVATAR_ERROR',

  UPDATE_USERNAME: 'UPDATE_USERNAME',
  UPDATE_USERNAME_SUCCESS: 'UPDATE_USERNAME_SUCCESS',
  UPDATE_USERNAME_ERROR: 'UPDATE_USERNAME_ERROR',

  VERIFY_EMAIL_CHANGE: 'VERIFY_EMAIL_CHANGE',
  VERIFY_EMAIL_CHANGE_SUCCESS: 'VERIFY_EMAIL_CHANGE_SUCCESS',
  VERIFY_EMAIL_CHANGE_ERROR: 'VERIFY_EMAIL_CHANGE_ERROR',

  UPDATE_EMAIL_RESET: 'UPDATE_EMAIL_RESET',
  UPDATE_EMAIL: 'UPDATE_EMAIL',
  UPDATE_EMAIL_SUCCESS: 'UPDATE_EMAIL_SUCCESS',
  UPDATE_EMAIL_ERROR: 'UPDATE_EMAIL_ERROR',
};

export const UserActions = {
  /**
   * Send redux request for user login
   * @param email
   * @param password
   * @param persist Should stay logged in in after app reload.
   * @param redirectOnSuccess
   * @param type
   * @param code
   */

  signIn: ({
    email,
    password,
    persist = false,
    redirectOnSuccess,
    type = 'user',
    code = '',
  }: ISigninParams) => ({
    type: UserActionTypes.SIGNIN,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/oauth2/v3alpha/token`,
      method: 'POST',
      data: queryString.stringify({
        username: email,
        password: password,
        grant_type: 'password',
        code,
      }),
    },
    meta: {
      persist,
      noAuth: true,
      redirectOnSuccess,
      type,
      email,
    },
  }),
  signInTOTP: ({
    encryptToken,
    code,
    persist = false,
    redirectOnSuccess,
  }: {
    encryptToken: string;
    code: string;
    persist?: boolean;
    redirectOnSuccess?: string;
  }) => ({
    type: UserActionTypes.SIGNIN,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/oauth2/v3alpha/totp/login`,
      method: 'POST',
      data: queryString.stringify({
        encrypt_token: encryptToken,
        code,
      }),
    },
    meta: {
      persist,
      noAuth: true,
      redirectOnSuccess,
    },
  }),
  signInRecover: ({
    encryptToken,
    code,
    persist = false,
    redirectOnSuccess,
  }: {
    encryptToken: string;
    code: string;
    persist?: boolean;
    redirectOnSuccess?: string;
  }) => ({
    type: UserActionTypes.SIGNIN,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/oauth2/v3alpha/recovery_code`,
      method: 'POST',
      data: queryString.stringify({
        encrypt_token: encryptToken,
        code,
      }),
    },
    meta: {
      persist,
      noAuth: true,
      redirectOnSuccess,
    },
  }),
  signInReset: () => ({
    type: UserActionTypes.SIGNIN_RESET,
  }),
  signInResetStatus: () => ({ type: UserActionTypes.SIGNIN_RESET_STATUS }),
  getTokenAfterChangePassword: (email: string, password: string) => ({
    type: UserActionTypes.GET_TOKEN_AFTER_CHANGE_PASSWORD,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/oauth2/v3alpha/token`,
      method: 'POST',
      data: queryString.stringify({
        username: email,
        password: password,
        grant_type: 'password',
      }),
    },
    meta: {
      noAuth: true,
    },
  }),

  verify: (email: string, password: string, redirectOnSuccess?: string) => ({
    type: UserActionTypes.VERIFY_USER,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/oauth2/v3alpha/token`,
      method: 'POST',
      data: queryString.stringify({
        username: email,
        password: password,
        grant_type: 'password',
      }),
    },
    meta: {
      redirectOnSuccess,
      noAuth: true,
    },
  }),
  resetVerify: () => ({
    type: UserActionTypes.VERIFY_USER_RESET,
  }),

  // TODO update from old API, blocked by backend 19th Jan 2020
  signUp: (payload: ISingUp, redirectOnSuccess?: string) => ({
    type: UserActionTypes.SIGNUP,
    request: {
      url: '/user/v1alpha/users:register',
      method: 'POST',
      data: mapApiSignUp(payload),
    },
    meta: {
      email: payload.email,
      noAuth: true,
      redirectOnSuccess,
    },
  }),
  signUpStatusReset: () => ({
    type: UserActionTypes.SIGNUP_RESET,
  }),

  recoverPassword: (payload: { email: string; token: string }) => ({
    type: UserActionTypes.RECOVER_PASSWORD,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/uaa/v1alpha/emailVerification:send`,
      method: 'POST',
      data: {
        ...payload,
        type: 'RESET_PASSWORD',
      },
    },
    meta: {
      noAuth: true,
    },
  }),

  resetPasswordRecoverStatus: () =>
    createAction(UserActionTypes.RECOVER_PASSWORD_RESET),

  signOut: (payload?: any) => ({
    type: UserActionTypes.WANT_SIGNOUT,
    payload,
  }),
  updateUsername: (name: string): IExtendedRequestAction => ({
    type: UserActionTypes.UPDATE_USERNAME,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/uaa/v1alpha/nickname`,
      method: 'PUT',
      data: { nickname: name },
    },
    meta: {
      redirectOnSuccess: SETTINGS_PREFIX,
    },
  }),

  setAvatar: (url: string, bg_color: string) => ({
    type: UserActionTypes.SET_AVATAR,
    request: {
      url: '/user/v1alpha/me/avatar',
      method: 'POST',
      data: {
        url,
        bg_color,
      },
    },
    meta: {
      avatar: url,
      avatarBackground: bg_color,
      redirectOnSuccess: SETTINGS_PREFIX,
    },
  }),

  confirmRegister: ({ code }: { code: string }): IExtendedRequestAction => ({
    type: UserActionTypes.ACCOUNT_VERIFY,
    request: {
      url: '/user/v1alpha/users:confirm',
      method: 'POST',
      data: {
        code,
      },
    },
    meta: {
      redirectOnSuccess: `/register-success`,
    },
  }),

  resetPassword: ({
    code,
    password,
  }: {
    code: string;
    password: string;
  }): IExtendedRequestAction => ({
    type: UserActionTypes.RESET_PASSWORD,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/uaa/v1alpha/password:reset`,
      method: 'POST',
      data: {
        code,
        new_password: password,
      },
    },
    meta: {
      redirectOnSuccess: `/auth/reset-password-success`,
    },
  }),

  changePassword: (
    oldPassword: string,
    newPassword: string,
    code: string,
  ): IExtendedRequestAction => ({
    type: UserActionTypes.CHANGE_PASSWORD,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/uaa/v2alpha/password:change`,
      method: 'POST',
      data: {
        old_password: oldPassword,
        new_password: newPassword,
        code,
      },
    },
    meta: {
      redirectOnSuccess: SETTINGS_PREFIX,
    },
  }),
  changePasswordReset: () => ({
    type: UserActionTypes.CHANGE_PASSWORD_RESET,
  }),
  refresh: (redirect?: string): IExtendedRequestAction => {
    return {
      type: UserActionTypes.USER_REFRESH,
      request: {
        url: '/user/v1alpha/me',
        method: 'GET',
      },
      meta: {
        redirectOnSuccess: redirect,
      },
    };
  },

  updateEmailReset: () => ({
    type: UserActionTypes.UPDATE_EMAIL_RESET,
  }),
  updateEmail: (email: string): IExtendedRequestAction => ({
    type: UserActionTypes.UPDATE_EMAIL,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/uaa/v1alpha/email:update`,
      method: 'POST',
      data: { new_email: email },
    },
  }),

  /**
   * Change phone number
   * @param new_phone
   * @param code
   * @param redirectOnSuccess
   */
  phoneChange: (
    new_phone: string,
    code: string,
    redirectOnSuccess?: string,
  ) => ({
    type: PhoneUserActionTypes.PHONE_CHANGE,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/uaa/v1alpha/phone`,
      method: 'POST',
      data: { phone: new_phone, code },
    },
    meta: {
      redirectOnSuccess,
    },
  }),

  confirmEmail: ({
    code,
    redirectOnSuccess = SETTINGS_PREFIX,
  }: {
    code: string;
    redirectOnSuccess?: string;
  }): IExtendedRequestAction => {
    return {
      type: UserActionTypes.VERIFY_EMAIL_CHANGE,
      request: {
        url: `${process.env.REACT_APP_API_BASE}/uaa/v1alpha/email:confirm`,
        method: 'POST',
        data: { code },
      },
      meta: {
        redirectOnSuccess,
      },
    };
  },
};
