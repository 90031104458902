import { createAPIReducer, createReducer } from 'common/utils/reduxUtils';
import { requestInactive, RequestStatus } from 'common/utils/requestStatus';
import {
  mapTeams,
  mapTeamMembers,
  mapTeamRoles,
  mapTeam,
  mapMembers,
} from '../apiMappings/teamMappings';
import {
  IApiTeam,
  IApiTeamMember,
  IApiTeamMemberResponse,
  IApiTeamResponse,
  ITeam,
  ITeamMember,
  ITeamRole,
} from '../teamTypes';
import { UserActionTypes } from 'auth/store/actions/UserActions';
import { TEAM_ROLE } from 'common/core/const';
import { TeamActionTypes } from '../actions/TeamActions';

interface ITeamDetails {
  members: ITeamMember[];
}

export interface ITeamsDetails {
  [key: string]: ITeamDetails;
}

export function getCurrentTeam(state: ITeamState) {
  return state.teams.find(team => team.id === state.currentTeamId);
}

export function isEnterpriseModeAvailable(state: ITeamState) {
  return state.teams.some(team => team.isEnterprise);
}

export function isPersonalModeAvailable(state: ITeamState) {
  return state.teams.some(team => !team.isEnterprise);
}

export function getTeamMembers(state: ITeamState) {
  const teamMembers =
    state.currentTeamId && state.teamMembers[state.currentTeamId];
  return teamMembers || [];
}

export function hasEditPermissions(state: ITeamState, email: string) {
  return getTeamMembers(state).some(
    teamMember =>
      teamMember.email === email &&
      (teamMember.role === TEAM_ROLE['owner'] ||
        teamMember.role === TEAM_ROLE['admin']),
  );
}
export function hasEditPermissionsByTeam(
  state: ITeamState,
  email: string,
  teamId: string,
  members: ITeamMember[],
) {
  return members.some(
    teamMember =>
      teamMember.email === email &&
      (teamMember.role === TEAM_ROLE['owner'] ||
        teamMember.role === TEAM_ROLE['admin']),
  );
}

export interface ITeamState {
  teams: ITeam[];
  teamsDetails?: ITeamsDetails;
  currentTeamId?: string;
  teamMembers: { [keys: string]: ITeamMember[] };
  teamRoles: ITeamRole[];
  fetchTeamsStatus: RequestStatus;
  assignTeamStatus: RequestStatus;
  fetchTeamsDetailsStatus: RequestStatus;
  fetchTeamRolesStatus: RequestStatus;
  createTeamStatus: RequestStatus;
  fetchTeamDetailStatus: RequestStatus;
  inviteTeamMemberStatus: RequestStatus;
  closeTeamStatus: RequestStatus;
  changeTeamNameStatus: RequestStatus;
  updateTeamMemberStatus: RequestStatus;
  deleteTeamMemberStatus: RequestStatus;
  confirmTeamMemberStatus: RequestStatus;
  resendInvitationEmailStatus: RequestStatus;
}

const teamReducerInitialStateUnstored: Omit<
  ITeamState,
  'teams' | 'teamRoles' | 'teamMembers'
> = {
  createTeamStatus: requestInactive(),
  fetchTeamsStatus: requestInactive(),
  assignTeamStatus: requestInactive(),
  fetchTeamsDetailsStatus: requestInactive(),
  fetchTeamRolesStatus: requestInactive(),
  fetchTeamDetailStatus: requestInactive(),
  inviteTeamMemberStatus: requestInactive(),
  closeTeamStatus: requestInactive(),
  changeTeamNameStatus: requestInactive(),
  updateTeamMemberStatus: requestInactive(),
  deleteTeamMemberStatus: requestInactive(),
  confirmTeamMemberStatus: requestInactive(),
  resendInvitationEmailStatus: requestInactive(),
};
const initialState: ITeamState = {
  ...teamReducerInitialStateUnstored,
  teams: [],
  teamMembers: {},
  teamRoles: [],
};

export const teamReducerStorageBlacklist = Object.keys(
  teamReducerInitialStateUnstored,
);

const resetRoles = (state: ITeamState): ITeamState => {
  return {
    ...state,
    teamRoles: [],
  };
};

const teamReducer = createReducer(initialState, {
  ...createAPIReducer<ITeamState, { data: IApiTeamResponse }>(
    TeamActionTypes.FETCH_TEAMS,
    'fetchTeamsStatus',
    {
      onRequest: resetRoles,
      onSuccess: (state, action) => {
        const teams = mapTeams(action.data);
        return {
          ...state,
          teams,
          currentTeamId:
            state.currentTeamId &&
            teams.find(team => team.id === state.currentTeamId)
              ? state.currentTeamId
              : teams[0] && teams[0].id,
        };
      },
    },
  ),
  ...createAPIReducer<ITeamState, { data: IApiTeamResponse }>(
    TeamActionTypes.ASSIGN_TEAM,
    'assignTeamStatus',
    {
      onRequest: resetRoles,
    },
  ),
  ...createAPIReducer<
    ITeamState,
    {
      data: IApiTeamMemberResponse[];
      response: { [key: number]: { config: { meta: { teamId: string } } } };
    }
  >(TeamActionTypes.FETCH_ALL_TEAMS_DETAIL, 'fetchTeamsDetailsStatus', {
    onSuccess: (state, action) => {
      const teamsDetails = action.data
        .map(({ members }) => ({ members: mapMembers(members) }))
        .reduce((prev, next, index) => {
          return {
            ...prev,
            [action.response[index].config.meta.teamId]: next,
          };
        }, {});
      return {
        ...state,
        teamsDetails,
      };
    },
  }),
  ...createAPIReducer<ITeamState, { data: IApiTeam }>(
    TeamActionTypes.TEAM_CREATE,
    'createTeamStatus',
    {
      onRequest: resetRoles,
      onSuccess: (state, action) => {
        const teams = [...state.teams, mapTeam(action.data)];
        return {
          ...state,
          teams,
          currentTeamId: action.data.id,
        };
      },
    },
  ),
  ...createAPIReducer<
    ITeamState,
    {
      data: { members: IApiTeamMember[] };
      meta: { requestAction: { request: { meta: { teamId: string } } } };
    }
  >(TeamActionTypes.TEAM_DETAIL_FETCH, 'fetchTeamDetailStatus', {
    onSuccess: (state, action) => {
      return {
        ...state,
        teamMembers: {
          ...state.teamMembers,
          [action.meta.requestAction.request.meta.teamId]: mapTeamMembers(
            action.data.members,
          ),
        },
      };
    },
  }),

  ...createAPIReducer<
    ITeamState,
    { meta: { requestAction: { request: { meta: { teamId: string } } } } }
  >(TeamActionTypes.CLOSE_TEAM, 'closeTeamStatus', {
    onRequest: resetRoles,
    onSuccess: (state, action) => {
      const closedTeamId = action.meta.requestAction.request.meta.teamId;
      const nextTeamId = state.teams[0].id;

      delete state.teamMembers[closedTeamId];

      return {
        ...state,
        teams: state.teams.filter(team => team.id !== closedTeamId),
        currentTeamId: state.teams[0].id ? nextTeamId : undefined,
      };
    },
  }),

  ...createAPIReducer<ITeamState>(
    TeamActionTypes.INVITE_TEAM_MEMBER,
    'inviteTeamMemberStatus',
  ),

  ...createAPIReducer<ITeamState>(
    TeamActionTypes.CHANGE_TEAM_NAME,
    'changeTeamNameStatus',
  ),

  ...createAPIReducer<ITeamState>(
    TeamActionTypes.UPDATE_TEAM_MEMBER,
    'updateTeamMemberStatus',
  ),

  ...createAPIReducer<ITeamState>(
    TeamActionTypes.DELETE_TEAM_MEMBER,
    'deleteTeamMemberStatus',
  ),

  ...createAPIReducer<ITeamState>(
    TeamActionTypes.CONFIRM_TEAM_MEMBER,
    'confirmTeamMemberStatus',
  ),

  ...createAPIReducer<ITeamState>(
    TeamActionTypes.TEAM_ROLES_FETCH,
    'fetchTeamRolesStatus',
    {
      onSuccess: (state, action) => {
        const teamRoles = mapTeamRoles(action.data);
        return {
          ...state,
          teamRoles,
        };
      },
    },
  ),

  ...createAPIReducer<ITeamState>(
    TeamActionTypes.RESEND_INVITATION_EMAIL,
    'resendInvitationEmailStatus',
  ),

  [TeamActionTypes.SET_CURRENT_TEAM]: (
    state: ITeamState,
    action: { payload: { teamId: string } },
  ) => {
    return {
      ...state,
      teamMembers: [],
      teamRoles: {},
      currentTeamId: action.payload.teamId,
    };
  },

  [UserActionTypes.SIGNOUT]: (): ITeamState => {
    return initialState;
  },
});

export { teamReducer };
