import { clearRequestsCache } from 'redux-saga-requests';
import { AnyAction } from 'redux';
import { put, select, takeEvery, spawn, call } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import { UserActionTypes } from '../actions/UserActions';
import { UserActions } from 'auth/store/actions/UserActions';
import { IUserState } from '../reducers/userReducer';
import { ITokensState } from 'auth/store/reducers/tokensReducer';
import {
  IExtendedAxiosResponse,
  IExtendedRequestAction,
} from 'common/types/requestTypes';
import { IApiLoginResponse } from '../mappings/userMappings';

function* doApiSignout(accessToken: string) {
  yield call(fetch, `${process.env.REACT_APP_API_BASE}/uaa/v1alpha/logout`, {
    method: 'POST',
    headers: { Authorization: `Bearer ${accessToken}` },
  });
}
/**
 * User logout. It made with sagas because we need some state inside.
 */
function* signOutUser({ payload }: AnyAction) {
  const accessToken = yield select((state: { tokens: ITokensState }) => {
    return state.tokens.accessToken;
  });
  if (accessToken) {
    yield spawn(doApiSignout, accessToken);
  }
  try {
    localStorage.clear();
    // Logout whatever happened
    yield put({ type: UserActionTypes.SIGNOUT });
    yield put(clearRequestsCache());
    yield put({ type: UserActionTypes.USER_REDIRECT_TO_LOGIN, payload });
  } catch (e) {
    Sentry.captureException(e);
  }
}

function* handleLogin({ response }: IExtendedAxiosResponse<IApiLoginResponse>) {
  if (response.status === 202) return;
  yield put(UserActions.refresh());
}

function* handleChangeUsername() {
  yield put(UserActions.refresh());
}

function* handleChangePassword(action: IExtendedRequestAction) {
  const email = yield select((state: { user: IUserState }) => state.user.email);
  const password = action.meta?.requestAction.request.data.new_password;
  yield put(UserActions.getTokenAfterChangePassword(email, password));
}

function* userSaga() {
  yield takeEvery(
    UserActionTypes.CHANGE_PASSWORD_SUCCESS,
    handleChangePassword,
  );
  yield takeEvery(UserActionTypes.SIGNIN_SUCCESS, handleLogin);
  yield takeEvery(UserActionTypes.VERIFY_USER_SUCCESS, handleLogin);
  yield takeEvery(
    UserActionTypes.UPDATE_USERNAME_SUCCESS,
    handleChangeUsername,
  );
  yield takeEvery(UserActionTypes.WANT_SIGNOUT, signOutUser);
}

export { userSaga, signOutUser };
