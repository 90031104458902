import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentTeam,
  isEnterpriseModeAvailable,
  isPersonalModeAvailable,
  ITeamState,
} from '../reducers/teamReducer';
import { useCallback, useEffect, useRef } from 'react';
import { TeamActions } from '../actions/TeamActions';
import { RequestStatus } from '../../../utils/requestStatus';
import { useHistory } from 'react-router';
import { DASHBOARD_PATH } from 'common/core/webPaths';

const REDIRECTION_PARAMS = { navigateOnModeSwitch: true };

export const useEnterprise = (params?: { navigateOnModeSwitch?: boolean }) => {
  const { replace } = useHistory();
  const inited = useRef<boolean>(false);

  const {
    isEnterprise,
    isEnterpriseAvailable,
    isPersonalAvailable,
    enterpriseTeamId,
    personalTeamId,
    changeModeStatus,
  } = useSelector<
    { team: ITeamState },
    {
      isEnterprise: boolean | undefined;
      isEnterpriseAvailable: boolean;
      isPersonalAvailable: boolean;
      enterpriseTeamId?: string;
      personalTeamId?: string;
      changeModeStatus: RequestStatus;
    }
  >(state => {
    const enterpriseTeamId = state.team.teams.find(team => team.isEnterprise)
      ?.id;
    const personalTeamId = state.team.teams.find(team => !team.isEnterprise)
      ?.id;

    return {
      isEnterprise: getCurrentTeam(state.team)?.isEnterprise,
      isEnterpriseAvailable: isEnterpriseModeAvailable(state.team),
      isPersonalAvailable: isPersonalModeAvailable(state.team),
      enterpriseTeamId,
      personalTeamId,
      changeModeStatus: state.team.fetchTeamDetailStatus,
    };
  });

  const dispatch = useDispatch();

  const enableEnterpriseMode = useCallback(() => {
    if (enterpriseTeamId) {
      dispatch(TeamActions.setCurrentTeam(enterpriseTeamId));
    } else {
      throw new Error("Enterprise mode isn't available");
    }
  }, [dispatch, enterpriseTeamId]);

  const enablePersonalMode = useCallback(() => {
    if (personalTeamId) {
      dispatch(TeamActions.setCurrentTeam(personalTeamId));
    } else {
      throw new Error("Personal mode isn't available");
    }
  }, [dispatch, personalTeamId]);

  useEffect(() => {
    if (isEnterprise && params?.navigateOnModeSwitch && inited.current) {
      replace(DASHBOARD_PATH);
    }
    inited.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnterprise]);

  return {
    isEnterprise,
    isEnterpriseAvailable,
    isPersonalAvailable,
    enableEnterpriseMode,
    enablePersonalMode,
    changeModeStatus,
  };
};

export const useEnterpriseRedirection = () => {
  return useEnterprise(REDIRECTION_PARAMS);
};
