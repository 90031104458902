import React from 'react';

import { LoadingIndicator } from 'common-web/components/LoadingIndicator';

const LoadableScreenComponent = () => {
  return <LoadingIndicator fullPage={true} />;
};

const LoadableScreen = LoadableScreenComponent;

export { LoadableScreen };
