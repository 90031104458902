import { History } from 'history';
import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
// Auth reducers
import { IUserState, userReducer } from 'auth/store/reducers/userReducer';
import { ITokensState, tokensReducer } from 'auth/store/reducers/tokensReducer';
import { IUIState, uiReducer } from './uiReducer';
import { ankrReducer, IAnkrState } from 'common/store/reducers/ankrReducer';
import {
  ISnackbarsState,
  snackbarsReducer,
} from 'common/store/reducers/snackbarsReducer';
import {
  IOfflineState,
  offlineReducer,
} from 'common/store/reducers/offlineReducer';
import {
  IRedirectState,
  redirectReducer,
} from 'common/modules/Redirect/reducers/redirectReducer';
import {
  ILocationState,
  locationReducer,
} from 'common/store/reducers/locationReducer';
import {
  APIMarketReducer,
  IAPIMarketState,
} from '../../modules/APIMarketUI/store/APIMarketReducer';
import {
  teamPersistConfig,
  tokensPersistConfig,
  userPersistConfig,
} from 'auth/store/reducers/webStorageConfigs';
import {
  IZendeskState,
  zendeskReducer,
} from 'common-web/modules/zendesk/reducers/zendeskReducer';
import {
  ITeamState,
  teamReducer,
} from 'common/modules/teams/reducers/teamReducer';
import { payReducer } from 'common/modules/billing';
import { IPayState } from 'common/modules/billing/reducers/payReducer';
import {
  ITwoFactorState,
  TwoFactorReducer,
} from 'auth/store/reducers/TwoFactorReducer';

export interface IApiStoreState {
  router: RouterState;
  ui: IUIState;
  user: IUserState;
  tokens: ITokensState;
  redirect: IRedirectState;
  pay: IPayState;
  // shared code reducers
  ankr: IAnkrState;
  snackbars: ISnackbarsState;
  offline: IOfflineState;
  team: ITeamState;
  apiMarket: IAPIMarketState;
  twoFactor: ITwoFactorState;

  location: ILocationState;

  zendesk: IZendeskState;
}

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    ui: uiReducer,
    user: persistReducer(userPersistConfig, userReducer),
    tokens: persistReducer(tokensPersistConfig, tokensReducer),
    team: persistReducer(teamPersistConfig, teamReducer),
    redirect: redirectReducer,
    apiMarket: APIMarketReducer,
    twoFactor: TwoFactorReducer,

    // shared code reducers
    ankr: ankrReducer,
    snackbars: snackbarsReducer,
    offline: offlineReducer,
    location: locationReducer,
    pay: payReducer,
    zendesk: zendeskReducer,
  });

export { createRootReducer };
