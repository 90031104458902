import { generatePath } from 'react-router';

export const API_MARKET_LIST_PATH = '/api';
export const API_MARKET_DETAILS_PATH = '/api/details';
export const API_MARKET_PATH = '/api/market';
export const API_MARKET_PLANS = '/api/plans/:apiId';

export function getMarketPlansPath(apiId: string) {
  return generatePath(API_MARKET_PLANS, { apiId });
}

export const API_MARKET_ADMIN =
  'https://chain-api-admin.cls-593f3f46-316e-4f00-8ebd-9a45cfde390d.ankr.com';

export const API_NAME_MAX_LENGTH = 64;

export const API_MARKET_CREATE_PATH = `${API_MARKET_LIST_PATH}/create`;
export const API_MARKET_DETAILS_API_ID_PATH = `${API_MARKET_DETAILS_PATH}/:apiId`;
export const API_MARKET_DETAILS_OVERVIEW_PATH = `${API_MARKET_DETAILS_API_ID_PATH}/overview`;
export const API_MARKET_DETAILS_SETTINGS_PATH = `${API_MARKET_DETAILS_API_ID_PATH}/settings`;

export const API_MARKET_DETAILS_PATHS = [
  API_MARKET_DETAILS_API_ID_PATH,
  API_MARKET_DETAILS_OVERVIEW_PATH,
  API_MARKET_DETAILS_SETTINGS_PATH,
];

export const DEFAULT_PLAN_TYPE = 'intro';

export const POST_UPGRADE_DELAY = 1000;

export const CARD_WIDTH = 256;

const API_MAX_LENGTH = 32;
export const API_NAME_INPUT_ATTRS = {
  inputProps: { maxLength: API_MAX_LENGTH },
};
