import * as Sentry from '@sentry/browser';
import { safeDump as safeYamlDump } from 'js-yaml';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const JSZip = require('jszip');

export function downloadFile(blob: Blob, filename: string) {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const e = document.createEvent('MouseEvents');
    const a = document.createElement('a');
    a.download = filename;
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = ['data/plain', a.download, a.href].join(':');
    e.initEvent('click', true, false);
    a.dispatchEvent(e);
  }
}

function downloadBinaryFile(binary: string, filename: string) {
  const array = new Uint8Array(binary.length);
  for (let i = 0; i < binary.length; i++) {
    array[i] = binary.charCodeAt(i);
  }

  const blob = new Blob([array], { type: 'octet/stream' });

  downloadFile(blob, filename);
}

export function downloadTextFile(data: string, filename: string) {
  const blob = new Blob([data], { type: 'data/plain' });
  downloadFile(blob, filename);
}

export function downloadJSONFile(data: {}, filename: string) {
  const blob = new Blob([JSON.stringify(data)], { type: 'data/plain' });
  downloadFile(blob, filename);
}
function downloadYamlFile(data: {}, filename: string) {
  try {
    const yamlDocument = safeYamlDump(data);
    downloadTextFile(yamlDocument, filename);
  } catch (error) {
    Sentry.captureException(error);
  }
}

export const exportObjArrayAsJSON = async (
  array: {
    object?: any;
    name?: string;
  }[],
  fileName = '',
) => {
  const zip = new JSZip();

  array.forEach(item => {
    const itemObject = item?.object ?? {};
    const json = JSON.stringify(itemObject);
    const blob = new Blob([json], { type: 'application/json' });
    zip.file(item.name + '.json', blob);
  });

  zip.generateAsync({ type: 'base64' }).then(function(base64: any) {
    const link = document.createElement('a');
    link.href = 'data:application/zip;base64,' + base64;
    link.download = fileName + '.zip';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

export function exportStringAsTxt(text: string, fileName: string) {
  const blob = new Blob([text], { type: 'txt' });

  const a = document.createElement('a');
  a.download = fileName;
  a.href = URL.createObjectURL(blob);
  a.dataset.downloadurl = ['txt', a.download, a.href].join(':');
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(function() {
    URL.revokeObjectURL(a.href);
  }, 1500);
}

export { downloadBinaryFile, downloadYamlFile };
