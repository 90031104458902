import { createReducer } from 'common/utils/reduxUtils';
import {
  AnkrActionTypes,
  IFetchSymbolPrice,
  PriceHistoryRange,
} from 'common/store/actions/AnkrActions';
import BigNumber from 'bignumber.js';
import {
  extractRequestError,
  requestFailed,
  requestInactive,
  requestInProgress,
  RequestStatus,
  requestSuccessful,
} from 'common/utils/requestStatus';
import * as Sentry from '@sentry/browser';
import { IApiErrorAction } from '../../types';
import { ZERO } from 'common/core/const';

interface IPriceInfo {
  close: number;
  high: number;
  low: number;
  open: number;
  time: number;
  volumefrom: number;
  volumeto: number;
}

export interface IPriceChartData {
  x: number;
  y: number;
}

const mapPriceChartData = ({
  data,
}: {
  data: IPriceInfo[];
}): IPriceChartData[] => {
  try {
    return (
      data.map(priceInfo => ({
        x: priceInfo.time * 1000,
        y: priceInfo.high,
      })) || []
    );
  } catch (error) {
    Sentry.captureException(error);
  }

  return [];
};

export interface IAnkrState {
  price: BigNumber;
  usdtPrice: BigNumber;
  oldPrice: BigNumber;
  priceChartDataRequestStatus: RequestStatus;
  priceHistoryRange: PriceHistoryRange;
  priceChartData: IPriceChartData[] | null;
  priceStatus: RequestStatus;
}

const initialState: IAnkrState = {
  price: ZERO,
  usdtPrice: ZERO,
  oldPrice: ZERO,
  priceChartDataRequestStatus: requestInactive(),
  priceHistoryRange: '1h',
  priceChartData: null,
  priceStatus: requestInactive(),
};

const ankrReducer = createReducer(initialState, {
  /**
   * Set price chart range
   */
  [AnkrActionTypes.SET_PRICE_CHART_RANGE]: (
    state: IAnkrState,
    action: { range: PriceHistoryRange },
  ): IAnkrState => ({
    ...state,
    priceHistoryRange: action.range,
  }),
  /**
   * Fetch chart data
   */
  [AnkrActionTypes.PRICE_CHART_DATA_FETCH]: (
    state: IAnkrState,
  ): IAnkrState => ({
    ...state,
    priceChartDataRequestStatus: requestInProgress(),
    priceChartData: null,
  }),
  [AnkrActionTypes.PRICE_CHART_DATA_FETCH_SUCCESS]: (
    state: IAnkrState,
    action: { data: any },
  ): IAnkrState => ({
    ...state,
    priceChartDataRequestStatus: requestSuccessful(),
    priceChartData: mapPriceChartData(action.data),
  }),
  [AnkrActionTypes.PRICE_CHART_DATA_FETCH_ERROR]: (
    state: IAnkrState,
    action: IApiErrorAction,
  ): IAnkrState => ({
    ...state,
    priceChartDataRequestStatus: requestFailed(extractRequestError(action)),
    priceChartData: null,
  }),
  /**
   * Fetch price
   */
  [AnkrActionTypes.ANKR_FETCH_PRICE]: (state: IAnkrState): IAnkrState => ({
    ...state,
    priceStatus: requestInProgress(),
  }),
  [AnkrActionTypes.ANKR_FETCH_PRICE_SUCCESS]: (
    state: IAnkrState,
    action: { data: { NowPrice: string; P24hPrice: string } },
  ): IAnkrState => ({
    ...state,
    priceStatus: requestSuccessful(),
    price: new BigNumber(action.data.NowPrice),
    oldPrice: new BigNumber(action.data.P24hPrice),
  }),
  [AnkrActionTypes.ANKR_FETCH_PRICE_ERROR]: (
    state: IAnkrState,
    action: IApiErrorAction,
  ): IAnkrState => ({
    ...state,
    priceStatus: requestFailed(extractRequestError(action)),
  }),

  /**
   * Fetch price v2
   */
  [AnkrActionTypes.ANKR_FETCH_SYMBOL_PRICE]: (
    state: IAnkrState,
  ): IAnkrState => ({
    ...state,
    priceStatus: requestInProgress(),
  }),
  [AnkrActionTypes.ANKR_FETCH_SYMBOL_PRICE_SUCCESS]: (
    state: IAnkrState,
    action: { meta: { symbol: IFetchSymbolPrice }; data: { price: string } },
  ): IAnkrState => {
    const symbol = action.meta.symbol;

    const priceObject =
      symbol === IFetchSymbolPrice.ANKR
        ? {
            price: new BigNumber(action.data.price),
            oldPrice: new BigNumber(action.data.price),
          }
        : {
            usdtPrice: new BigNumber(action.data.price),
          };

    return {
      ...state,
      priceStatus: requestSuccessful(),
      ...priceObject,
    };
  },
  [AnkrActionTypes.ANKR_FETCH_SYMBOL_PRICE_ERROR]: (
    state: IAnkrState,
    action: IApiErrorAction,
  ): IAnkrState => ({
    ...state,
    priceStatus: requestFailed(extractRequestError(action)),
  }),
});

export { ankrReducer };
