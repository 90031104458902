import { withErrorHandler } from 'common-web/HOC/withErrorHandler';
import { LoadableScreen } from 'common-web/components/LoadableScreen';
import React from 'react';

import loadable, { LoadableComponent } from '@loadable/component';
import { withApiLayout } from '../components/Layout/ApiLayout';

export const LoadableNotFoundPaper = withApiLayout(
  withErrorHandler()(
    loadable(
      async () => import('screens/NotFound').then(module => module.NotFound),
      {
        fallback: <LoadableScreen />,
      },
    ) as LoadableComponent<any>,
  ),
);
