import React, { ReactNode, useCallback } from 'react';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import {
  AppBar,
  Container,
  StyledComponentProps,
  Toolbar as MuiToolbar,
  withStyles,
} from '@material-ui/core';
import { useIsSMDown } from 'common-web/utils/hooks/themeHooks';
import { apiLayoutStyles } from './ApiLayoutStyles';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as Logo } from './assets/textLogo.svg';
import { PageTitle } from './components/PageTitle';
import { ToolBar } from './components/Toolbar/ToolBar';
import { EnableTwoFactorAuth } from 'auth-web/screens/EnableTwoFactorAuth';
import { connect } from 'react-redux';
import {
  isUserAuthenticated,
  ITokensState,
} from 'auth/store/reducers/tokensReducer';
import { ITwoFactorState } from 'auth/store/reducers/TwoFactorReducer';
import { TwoFactorActions } from 'auth/store/actions/TwoFactorActions';

const SLIDE_TIMEOUT = 500;

export interface IApiLayoutStoreProps {
  isUserAuthenticated: boolean;
  showTwoFAReminder: boolean;
}

interface IApiLayoutProps extends IApiLayoutStoreProps, StyledComponentProps {
  children: ReactNode;
  className?: string;
  whiteXSBg?: boolean;
  resetTwoFAReminder: typeof TwoFactorActions.getReminderReset;
}

export const ApiLayout = withStyles(apiLayoutStyles)(
  ({
    children,
    classes = {},
    isUserAuthenticated,
    showTwoFAReminder,
    resetTwoFAReminder,
    className,
    whiteXSBg,
  }: IApiLayoutProps) => {
    const isSMDown = useIsSMDown();

    const handleCloseReminder = useCallback(() => {
      resetTwoFAReminder();
    }, [resetTwoFAReminder]);

    const triggerDetached = useScrollTrigger({
      threshold: 10,
      disableHysteresis: true,
    });

    const triggerHide = useScrollTrigger({ threshold: 150 });

    return (
      <div className={classNames(classes.root, whiteXSBg && classes.whiteXSBg)}>
        <Slide
          appear={false}
          direction="down"
          in={!triggerHide || isSMDown}
          timeout={isSMDown ? 0 : SLIDE_TIMEOUT}
        >
          <AppBar
            position="fixed"
            classes={{
              root: classNames(
                !isSMDown && triggerDetached && classes.detached,
              ),
            }}
          >
            <MuiToolbar
              className={classNames(classes.toolbar)}
              disableGutters={true}
            >
              <Container maxWidth="xl" className={classes.container}>
                <RouterLink to="/">
                  <Logo className={classes.icon} />
                </RouterLink>
                <PageTitle />
                <ToolBar />
              </Container>
            </MuiToolbar>
          </AppBar>
        </Slide>
        <div className={classes.content}>
          <div className={classNames(classes.paper, className)}>{children}</div>
        </div>
        {showTwoFAReminder && (
          <EnableTwoFactorAuth
            open={showTwoFAReminder}
            onClose={handleCloseReminder}
          />
        )}
      </div>
    );
  },
);

const ApiLayoutContainer = connect(
  (state: { tokens: ITokensState; twoFactor: ITwoFactorState }) => {
    return {
      isUserAuthenticated: isUserAuthenticated(state.tokens),
      showTwoFAReminder: state.twoFactor.showReminder,
    };
  },
  {
    resetTwoFAReminder: TwoFactorActions.getReminderReset,
  },
)(ApiLayout);

export const withApiLayout = <T extends object>(
  Component: React.ComponentType<T>,
) => (props: T) => (
  <ApiLayoutContainer>
    <Component {...props} />
  </ApiLayoutContainer>
);
