function pad(num: number, size: number) {
  let s = num + '';
  while (s.length < size) {
    s = '0' + s;
  }
  return s;
}

function truncateMiddle(str: string, length: number, separator: string) {
  if (str.length <= length) return str;

  separator = separator || '...';

  const sepLen = separator.length,
    charsToShow = length - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return (
    str.substr(0, frontChars) + separator + str.substr(str.length - backChars)
  );
}

export const hasText = (str: string) => {
  return Boolean(str.replace(' ', ''));
};

export function safeParseJSON(s: string) {
  try {
    return JSON.parse(s);
  } catch (e) {
    try {
      return JSON.parse(s.replace(/\n/gi, '').replace(/'/g, '"'));
    } catch (e) {
      return s;
    }
  }
}

export const add0xPrefix = (string: string) => {
  try {
    return string.startsWith('0x') ? string : `0x${string}`;
  } catch {
    return '';
  }
};

export const capitalizeFirstLetter = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const fuzzySearch = (
  search: string,
  text: string | undefined | null,
) => {
  if (!search) {
    return true;
  }
  if (!text) {
    return false;
  }
  // Build Regex String
  let matchTerm = '.*';

  // Split all the search terms
  const terms = search.split(' ');

  for (let i = 0; i < terms.length; i++) {
    matchTerm += '(?=.*' + terms[i] + '.*)';
  }

  matchTerm += '.*';

  // Convert to Regex
  // => /.*(?=.*TERM1.*)(?=.*TERM2.*).*/
  const matchRegex = new RegExp(matchTerm.toUpperCase());

  return text.toUpperCase().match(matchRegex);
};

export { pad, truncateMiddle };
