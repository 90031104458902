import { useIsXXSDown } from 'common-web/utils/hooks/themeHooks';
import classNames from 'classnames';
import { Grid, Typography } from '@material-ui/core';
import { t } from 'common/utils/intl';
import {
  Message,
  MessageType,
  MessageVariant,
} from 'common-web/components/Message';
import Button from '@material-ui/core/Button';
import React from 'react';
import { useRecoveryCodesStyles } from './RecoveryCodesStyles';
import { LoadingIndicator } from 'common-web/components/LoadingIndicator';

interface IRecoveryCodesProps {
  className?: string;
  codes: string[];
  onDownload: () => void;
  onPrint: () => void;
  onCopy: () => void;
}

export const RecoveryCodesComponent = ({
  className,
  codes,
  onCopy,
  onDownload,
  onPrint,
}: IRecoveryCodesProps) => {
  const classes = useRecoveryCodesStyles();

  const isXXSDown = useIsXXSDown();

  return (
    <div className={classNames(className, classes.component)}>
      <Typography
        className={classes.title}
        variant={isXXSDown ? 'h3' : 'h2'}
        component="p"
      >
        {t('two-factor-auth.captions.enable.fifth-screen')}
      </Typography>
      <p className={classes.subtitle}>
        {t('two-factor-auth.captions.enable.codes-subtitle')}
      </p>
      <Message
        message={t('two-factor-auth.captions.enable.codes-memo')}
        bodyVariant={'body1'}
        type={MessageType.info}
        variant={MessageVariant.quote}
        classes={{ root: classes.message }}
      />
      <div className={classes.codesBorder}>
        <Grid container className={classes.codes} spacing={4}>
          {codes.length ? (
            codes.map(code => (
              <Grid item xs={6} sm={6} md={3} key={code}>
                {code}
              </Grid>
            ))
          ) : (
            <LoadingIndicator />
          )}
        </Grid>
      </div>
      <div className={classes.buttons}>
        <Button
          variant="outlined"
          onClick={onDownload}
          size="large"
          className={classes.button}
        >
          {t('two-factor-auth.captions.enable.download')}
        </Button>
        <Button
          variant="outlined"
          onClick={onPrint}
          size="large"
          className={classNames(classes.button, classes.printButton)}
        >
          {t('two-factor-auth.captions.enable.print')}
        </Button>
        <Button
          variant="outlined"
          onClick={onCopy}
          size="large"
          className={classes.button}
        >
          {t('two-factor-auth.captions.enable.copy')}
        </Button>
      </div>
    </div>
  );
};
