import { call, put, select } from 'redux-saga/effects';
import { sendRequest } from 'redux-saga-requests';
import {
  APIMarketActions,
  IArchiveOrderPayload,
  ICreateAPIPayload,
  IUpgradePlanPayload,
} from '../APIMarketActions';
import { Action } from 'redux-actions';
import { throwIfContainsError } from 'common/utils/throwIfContainsError';
import { ITeamState } from 'common/modules/teams/reducers/teamReducer';
import {
  IAPIMarketInstanceResponse,
  IAPIMarketItemResponse,
  PayWay,
  PaymentMethod,
} from '../APIMarketTypes';

export function* runCreateProjectSaga(action: Action<ICreateAPIPayload>) {
  const { payload } = action;

  const response = yield call(
    sendRequest,
    {
      type: 'EMPTY',
      request: {
        url: `${process.env.REACT_APP_API_BASE}/apis/apiorder/v2alpha/api/project`,
        method: 'POST',
        data: {
          service_id: payload.serviceId,
          project_name: payload.projectName,
          node_code: payload.nodeCode,
          team_id: payload.teamId,
          subscribe_status: payload.subscribeStatus,
          auth_info: {
            auth_type: payload.authType,
            auth_username: payload.authUsername,
            auth_password: payload.authPassword,
            auth_token: payload.authToken,
          },
          payment_info: {
            payment_methods: payload.paymentMethods,
            credit_card_id: payload.creditCardId,
          },
        },
      },
    },
    { dispatchRequestAction: false, silent: true },
  );

  if ('error' in response) {
    return yield put({ type: action.type + '_ERROR', error: response.error });
  }

  yield call(sendRequest, APIMarketActions.fetchInfuraAPIList(payload.teamId), {
    dispatchRequestAction: true,
  });

  yield put({ type: action.type + '_SUCCESS' });

  return response;
}

export function* runUpgradePlanSaga(action: Action<IUpgradePlanPayload>) {
  const { payload } = action;

  const response = throwIfContainsError(
    yield call(
      sendRequest,
      {
        type: 'EMPTY',
        request: {
          url: `/apis/apiorder/v2alpha/api/order/upgrade/apiService`,
          method: 'PUT',
          data: {
            pay_way: payload.payment.payway,
            credit_card_id: payload.payment.cardId ?? '',
            plan_id: payload.planId,
            order_id: payload.orderId,
          },
        },
      },
      { dispatchRequestAction: false, silent: true },
    ),
  );

  yield put({ type: action.type + '_SUCCESS' });

  return response;
}

export function* runDeleteAPISaga(action: Action<string>) {
  const { currentTeamId: teamId } = yield select(
    (state: { team: ITeamState }) => {
      return {
        currentTeamId: state.team.currentTeamId,
      };
    },
  );

  throwIfContainsError(
    yield call(
      sendRequest,
      {
        type: 'EMPTY',
        request: {
          url: `/apis/apiorder/v2alpha/api/project/${action.payload}`,
          method: 'DELETE',
        },
      },
      { dispatchRequestAction: false, silent: true },
    ),
  );

  yield call(sendRequest, APIMarketActions.fetchInfuraAPIList(teamId), {
    dispatchRequestAction: true,
    silent: true,
  });

  yield put({
    type: action.type + '_SUCCESS',
  });
}

export function* runFetchAPIListSaga(action: Action<ICreateAPIPayload>) {
  const { payload: teamId }: any = action;

  const apisResponse = yield call(
    sendRequest,
    APIMarketActions.fetchInfuraAPIList(teamId),
    {
      dispatchRequestAction: false,
      silent: true,
    },
  );

  const orderResponse = yield call(
    sendRequest,
    APIMarketActions.fetchInfuraMarketList(),
    {
      dispatchRequestAction: false,
    },
  );

  if ('error' in apisResponse || 'error' in orderResponse) {
    return yield put({ type: action.type + '_ERROR' });
  }

  const {
    response: {
      data: { project_list: apis },
    },
  } = apisResponse;

  const {
    response: {
      data: { data: markets },
    },
  } = orderResponse;

  apis.forEach((api: IAPIMarketInstanceResponse) => {
    const matchingMarket = markets.find(
      (market: IAPIMarketItemResponse) => market.code === api.node_code,
    );
    if (!matchingMarket) return;
    api.node_type = matchingMarket.node_family;
    api.node_name = matchingMarket.name;
    api.iconUrl = matchingMarket.icon_url;
    api.iconOnlyUrl = matchingMarket.icon_only_url;
    api.network = matchingMarket.network;
  });

  yield put({ type: action.type + '_SUCCESS', data: { data: apis } });
}

export function* runCreateArchiveOrderSaga(
  action: Action<{ order: IArchiveOrderPayload; api: ICreateAPIPayload }>,
) {
  const {
    payload: { api, order },
  } = action;

  const response = yield call(
    sendRequest,
    {
      type: 'EMPTY',
      request: {
        url: `${process.env.REACT_APP_API_BASE}/apis/apiorder/v2alpha/api/project`,
        method: 'POST',
        data: {
          service_id: api.serviceId,
          project_name: api.projectName,
          node_code: api.nodeCode,
          team_id: api.teamId,
          subscribe_status: api.subscribeStatus,
          auth_info: {
            auth_type: api.authType,
            auth_username: api.authUsername,
            auth_password: api.authPassword,
            auth_token: api.authToken,
          },
          payment_info: {
            payment_methods:
              order.payment.payway === PayWay.AnkrCredit // TODO: temporary. Needs normalization
                ? PaymentMethod.AnkrCredit
                : PaymentMethod.CreditCard,
            credit_card_id: order.payment.cardId,
          },
        },
      },
    },
    { dispatchRequestAction: false, silent: true },
  );

  if ('error' in response) {
    return yield put({ type: action.type + '_ERROR', error: response.error });
  }

  yield call(sendRequest, APIMarketActions.fetchInfuraAPIList(api.teamId), {
    dispatchRequestAction: true,
  });

  yield put({ type: action.type + '_SUCCESS' });

  return response;
}
