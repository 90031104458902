import BigNumber from 'bignumber.js';

export interface IAPIMarketItemResponse {
  id: string;
  code: string;
  node_family: string;
  name: string;
  network: string;
  icon_url: string;
  icon_only_url: string;
  price: string;
}

export interface IAPIMarketItem
  extends Omit<
    IAPIMarketItemResponse,
    'node_family' | 'icon_url' | 'icon_only_url' | 'price'
  > {
  nodeType: string;
  nodeName: string;
  iconUrl: string;
  iconOnlyUrl: string;
  price: BigNumber;
}

export interface IAPIMarketNodePreview {
  name: string;
  disableReason?: string;
}

export interface IAPIMarketItemPreview {
  nodeFamily: string;
  displayName: string;
  iconUrl: string;
  nodes: IAPIMarketNodePreview[];
}

export interface IAPIMarketInstanceResponse {
  id: string;
  node_code: string;
  project_name: string;
  network: string;
  total_requests: number;
  today_request: number;
  create_time: string;
  status: string;
  endpoint: string;
  iconUrl: string;
  iconOnlyUrl: string;
  node_type: string;
  node_name: string;
  icon_url: string;
  icon_only_url: string;
  auth_username: string;
  auth_password: string;
  plan: string;
  order_id: string;
  ws_endpoint: string;
  maximum_limit: string;
}

interface IAPIMarketPlanResponse {
  id: string;
  type: string;
  type_desc: string;
  ratet_limit_sec: number;
  max_req_daily: number;
  max_req_mo: number;
  price: number;
  node_codes: string[];
  create_time: string;
  status: string;
  support: number;
}

export interface IAPIOrderResponse {
  order_id: string;
  original_order_id: string;
  api_type: string;
  api_id: string;
  plan_id: string;
  plan_duration_month: number;
  server_price: string;
  auth_type: string;
  auth_username: string;
  auth_password: string;
  auth_token: string;
  create_time: string;
  delete_time: string;
  order_status: string;
  api_level_type: string;
}

export interface IAPIDetailsResponse {
  id: string;
  node_code: string;
  project_name: string;
  network: string;
  total_requests: number;
  today_request: number;
  status: string;
  endpoint: string;
  ws_endpoint: string;
  auth_username: string;
  auth_password: string;
  plan: string;
  order_id: string;
}

export interface IArchiveModePaymentStatusResponse {
  payment_status: string;
}

export interface IAPIMarketInstance
  extends Pick<
      IAPIMarketInstanceResponse,
      'id' | 'network' | 'endpoint' | 'iconUrl' | 'iconOnlyUrl'
    >,
    Omit<IAPIMarketItem, 'name' | 'price'> {
  projectName: string;
  nodeName: string;
  totalRequests: number;
  todaysRequests: number;
  maximumLimit: number;
  nodeCode: string;
  plan: string;
  authUsername: string;
  authPassword: string;
  wsEndpoint: string;
  isActive: boolean;
  orderId: string;
  createTime: string;
}

export interface IAPIMarketPlan {
  id: string;
  type: string;
  limitDaily: BigNumber;
  limitMonthly: BigNumber;
  limitSecondly: BigNumber;
  price: BigNumber;
  nodeCodes: string[];
  dateCreated: Date;
  support: number;
}

export interface IAPIDetails {
  id: string;
  projectName: string;
  username: string;
  password: string;
  network: string;
  endpoint: string;
  wsEndpoint: string;
  nodeCode: string;
  isActive: boolean;
  plan: string;
  orderId: string;
}

export interface IAPIOrder {
  id: string;
  apiType: APIType;
  planId: string;
  duration: number;
  price: BigNumber;
  authType: APIAuthenticationType;
  username: string;
  password: string;
  token: string;
  dateCreated: Date;
  status: APIOrderStatus;
}

export interface IAPIMarketAPIResponse {
  data: IAPIMarketInstanceResponse[];
}

export interface IAPIMarketResponse {
  data: IAPIMarketItemResponse[];
}

export interface IAPIMarketPlansResponse {
  data: IAPIMarketPlanResponse[];
}

export interface IAPIMarketInstanceStats {
  key: string;
  date: Date;
  value: number;
}
interface IAPIAPIMarketInstanceStats {
  key: string;
  date: string;
  value: number;
}
export interface IAPIMarketInstanceStatsResponse {
  data: IAPIAPIMarketInstanceStats[];
}

export interface IAPIUpgradePlanAmountResponse {
  amounts_payable: string;
  special_api_amounts_payable: string;
  amounts_all_payable: string;
}

export interface IAPIProjectLogResponse {
  total_calls: object;
}

export interface IAPIDailyQuotaResponse {
  plan_max_req_daily: string;
}

export interface IAPIMarketItemDocumentation {
  overview: string;
  availableNodes: string[];
  explorerURL: string;
  documentationHTML: string;
}

export interface IArchiveModePaymentStatus {
  status: ArchiveModePaymentStatus;
}
export interface IAPIMarketItemDocumentationResponse {
  documentation: IAPIMarketItemDocumentation;
}

export interface IAPIPayment {
  payway: PayWay;
  cardId?: string;
}

export enum APIMarketStatsFilter {
  allTime = 'AllTime',
  sevenDays = 'SevenDays',
  oneMonth = 'OneMonth',
}

export enum APICreateStep {
  INIT = 'INIT',
  AUTHENTICATION = 'AUTHENTICATION',
  PAYMENT = 'PAYMENT',
  ADD_CARD = 'ADD_CARD',
}

export enum APIPlanUpgradeStep {
  INIT = 'INIT',
  SELECT_CARD = 'SELECT_CARD',
  ADD_CARD = 'ADD_CARD',
  UPGRADING = 'UPGRADING',
}

export enum PayWay {
  CreditCard = 'pay_way_credit_card',
  AnkrCredit = 'pay_way_ankr_credit',
}

export enum PaymentMethod {
  CreditCard = 'payment_credit_card',
  AnkrCredit = 'payment_ankr_credit',
}

export enum APIType {
  Unknown = 'api_type_unknown',
  Unshared = 'api_type_unshared',
  Shared = 'api_type_shared',
}

export enum APIAuthenticationType {
  Basic = 'api_auth_type_basic_auth',
  Token = 'api_auth_type_token',
}

export enum APIOrderStatus {
  Success = 'order_status_pay_success',
}

export enum ArchiveModePaymentStatus {
  Unknown = 'special_api_status_unknown',
  Unpaid = 'special_api_status_unpaid',
  Paid = 'special_api_status_paid',
}

export enum SubscribeStatus {
  Enable = 'subscribe_enable',
  Disable = 'subscribe_disable',
}
