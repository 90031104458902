import { AxiosResponse } from 'axios';
import { createDriver } from 'redux-saga-requests-axios';
import {
  call,
  cancel,
  fork,
  put,
  select,
  take,
  delay,
} from 'redux-saga/effects';
import {
  createRequestInstance,
  RequestInstanceConfig,
  sendRequest,
  watchRequests,
} from 'redux-saga-requests';

import {
  TokenActions,
  TokenActionTypes,
} from 'auth/store/actions/TokensActions';
import { UserActions, UserActionTypes } from 'auth/store/actions/UserActions';
import {
  IExtendedRequestAction,
  IRequestConfig,
} from 'common/types/requestTypes';
import { delayedRedirect } from './utilitySagas';
import {
  createApiTransportInstance,
  isAuthError,
  ITransportConfig,
  makeRequestsWithAuthHeader,
  shouldSkipAuth,
  SUCCESS_REDIRECT_DELAY,
} from './apiSagaUtils';
import {
  accessTokenSelector,
  refreshTokenSelector,
} from '../selectors/tokenSelectors';

// Just a hack to make sure new tokens are in the store,
// before resetting temporary newToken
const DELAY_TO_UPDATE_TOKENS_IN_STORE = 20000;

// This is flag indicating that we already running refresh token procedure
let isRefreshingToken = false;
let newToken: null | string = null;

export function* onRequestSaga(
  request: IRequestConfig<any> | IRequestConfig<any>[],
  action: IExtendedRequestAction,
) {
  // Get access token from the store
  // If noAuth set don't add authorization header to the request
  let accessToken = yield select(accessTokenSelector);

  // This is to avoid a race condition

  if (accessToken === newToken) {
    newToken = null;
  } else if (newToken) {
    accessToken = newToken;
  }

  if (!action?.meta?.noAuth && accessToken) {
    return makeRequestsWithAuthHeader(request, accessToken);
  }
  return request;
}

export function* sendRequestWithToken(
  action: IExtendedRequestAction,
  token: string,
) {
  const newAction = {
    ...action,
    request: makeRequestsWithAuthHeader(action.request, token),
  };

  // we fire the same request again:
  // - with silent: true not to dispatch duplicated actions
  return yield call(sendRequest, newAction, { silent: true });
}

/**
 * Reset flags after delay
 */
export function* delayedResetRefreshToken() {
  yield delay(DELAY_TO_UPDATE_TOKENS_IN_STORE);

  newToken = null;
  isRefreshingToken = false;
}

/**
 *
 * @param action
 * @param updateFlag
 */
export function* waitTokenRefreshAndSendRequest(
  action: IExtendedRequestAction,
  updateFlag?: boolean,
) {
  const waitAction = yield take([
    TokenActionTypes.REFRESH_TOKEN_SUCCESS,
    TokenActionTypes.REFRESH_TOKEN_ERROR,
  ]);
  if (waitAction.type === TokenActionTypes.REFRESH_TOKEN_ERROR) {
    throw new Error('Refresh token error');
  }

  if (updateFlag) {
    newToken = waitAction.data.access_token;
    yield fork(delayedResetRefreshToken);
  }

  return yield sendRequestWithToken(action, waitAction.data.access_token);
}

export function* tokenRefreshAndSendRequest(action: IExtendedRequestAction) {
  // Trying to get a new token
  const refreshToken = yield select(refreshTokenSelector);

  if (refreshToken) {
    yield put(TokenActions.refreshToken(refreshToken)); // actual refresh

    return yield waitTokenRefreshAndSendRequest(action, true);
  } else {
    throw new Error('No tokens - signing out');
  }
}

/**
 * Error interceptor.
 * @param error
 * @param action
 */
export function* onErrorSaga(error: any, action: IExtendedRequestAction) {
  if (!shouldSkipAuth(action as IExtendedRequestAction) && isAuthError(error)) {
    try {
      if (newToken) {
        // Moment after new token fetched but it is not in the store yet.
        return yield sendRequestWithToken(action, newToken);
      } else if (isRefreshingToken) {
        // Token fetch in process.
        return yield waitTokenRefreshAndSendRequest(action);
      }
    } catch (e) {
      return { error: e };
    }

    try {
      // Fetching new token
      isRefreshingToken = true;
      return yield tokenRefreshAndSendRequest(action);
    } catch (e) {
      isRefreshingToken = false;
      newToken = null;
      // We didnt manage to get a new token
      // Forcing user to sign out.
      yield put(UserActions.signOut({ tokenFailed: true }));
      return { error: e };
    }
  }

  // not related token error, we pass it like nothing happened
  return { error };
}

export function* onSuccessSaga(
  response: AxiosResponse,
  action: IExtendedRequestAction,
) {
  if (action.meta?.redirectOnSuccess) {
    yield fork(
      delayedRedirect,
      action.meta.redirectOnSuccess,
      SUCCESS_REDIRECT_DELAY,
    );
  }
  return response;
}

/**
 * Main API saga
 */
function* apiSaga(sagaConfig: ITransportConfig, init: () => any) {
  // Get axios instance and do the setup
  const driver = createApiTransportInstance(sagaConfig);

  const config = {
    driver: {
      default: createDriver(driver),
      ...(sagaConfig.drivers ?? {}),
    },
    onRequest: onRequestSaga,
    onError: onErrorSaga,
    onSuccess: onSuccessSaga,
  };

  yield createRequestInstance(config as RequestInstanceConfig);

  if (typeof init === 'function') {
    yield fork(init);
  }

  let watchTask;
  while (true) {
    try {
      newToken = null;
      isRefreshingToken = false;
      // Run the request listener
      watchTask = yield fork(watchRequests);
      // Restart on signout.
      yield take(UserActionTypes.SIGNOUT);
      // Kill old listener
      yield cancel(watchTask);
    } catch (e) {
      // Something bad happened if you are here! :)
      // Sentry.captureException(e);
    }
  }
}

export { apiSaga };
