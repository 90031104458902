import { Classes } from 'jss';
import React from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar/Snackbar';
import { SnackbarContent } from 'common-web/components/SnackbarContent';
import {
  ISnackbar,
  SnackbarAction,
  SnackbarKey,
} from 'common/store/reducers/snackbarsReducer';
import { SnackbarOrigin } from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core';
import { snackbarStyles } from './SnackbarStyles';

interface ISnackbarProps extends Omit<ISnackbar, 'onClose'> {
  classes?: Classes;
  onClose?: (key: SnackbarKey) => void;
  autoHideDuration?: number;
  snackbarKey: SnackbarKey;
}

const ANCHOR_ORIGIN: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const TOP_CENTER_ANCHOR_ORIGN: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

const SnackbarComponent = ({
  variant,
  message,
  actions = [],
  onActionClick,
  onClose,
  autoHideDuration,
  snackbarKey,
  intlMessage,
  anchorOrigin = variant === 'success' || variant === 'error'
    ? TOP_CENTER_ANCHOR_ORIGN
    : ANCHOR_ORIGIN,
  classes = {},
}: ISnackbarProps) => {
  const handleClose = () => {
    if (onClose) {
      onClose(snackbarKey);
    }
  };
  const handleAction = (action: SnackbarAction) => {
    if (onActionClick) {
      onActionClick(snackbarKey, action);
    }
    if (action === 'close' && onClose) {
      onClose(snackbarKey);
    }
  };
  return (
    <MuiSnackbar
      onClose={handleClose}
      open={true}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      classes={{
        anchorOriginTopCenter: classes.anchorOriginTopCenter,
      }}
    >
      <SnackbarContent
        onActionClick={handleAction}
        actions={actions}
        variant={variant}
        message={message}
        intlMessage={intlMessage}
      />
    </MuiSnackbar>
  );
};

const Snackbar = withStyles(snackbarStyles)(SnackbarComponent);

export { Snackbar };
