import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useCheckFormStyles = makeStyles<Theme>(theme => ({
  component: {
    position: 'relative',
  },

  fieldWrapper: {
    maxWidth: 310,
    marginTop: theme.spacing(2.5),

    '&:first-child': {
      marginTop: 0,
    },
  },

  field: {
    marginTop: theme.spacing(1.5),
  },

  error: {
    position: 'absolute',
    bottom: `-${theme.spacing(4)}px`,

    color: theme.palette.error.main,
  },
}));
