import { takeEvery, takeLatest, select, call, put } from 'redux-saga/effects';
import { sendRequest } from 'redux-saga-requests';

import { UserActionTypes } from 'auth/store/actions/UserActions';

import {
  isUserAuthenticated,
  ITokensState,
} from 'auth/store/reducers/tokensReducer';
import { REHYDRATE } from 'redux-persist';

import { Action } from 'redux-actions';
import { TeamActionTypes } from 'common/modules/teams/actions/TeamActions';
import { ZendeskActions, ZendeskActionTypes } from '../actions/ZendeskActions';
import { IUserState } from 'auth/store/reducers/userReducer';
import { IZendeskState } from '../reducers/zendeskReducer';
import { IAPIZendeskMembership } from '../types/membershipTypes';
import { TokenActionTypes } from 'auth/store/actions/TokensActions';
import {
  getCurrentTeam,
  ITeamState,
} from 'common/modules/teams/reducers/teamReducer';

function* fetchZendeskMemebershipSaga(action: Action<{ teamId: string }>) {
  const isEnterprise = yield select((state: { team: ITeamState }) => {
    return getCurrentTeam(state.team)?.isEnterprise ?? false;
  });
  if (isEnterprise) {
    const teamId = yield select((state: { team: ITeamState }) => {
      return state.team.currentTeamId;
    });
    const zendeskUserId = yield select((state: { zendesk: IZendeskState }) => {
      return state.zendesk.user?.id;
    });
    const zendeskOrganizationId = yield select(
      (state: { zendesk: IZendeskState }) => {
        return state.zendesk.organizations?.[teamId]?.id;
      },
    );

    if (zendeskOrganizationId && zendeskUserId) {
      const zendeskMembership = yield select(
        (state: { zendesk: IZendeskState }) => {
          return state.zendesk.memberships[zendeskOrganizationId];
        },
      );
      if (!Boolean(zendeskMembership)) {
        const { response } = yield call(
          sendRequest,
          ZendeskActions.fetchZendeskUserMemberships({ zendeskUserId }),
        );

        // prefetch users of this organization
        yield put(ZendeskActions.fetchZendeskUsers({ zendeskOrganizationId }));

        const memberships = response?.data?.organization_memberships ?? [];

        const getMembership = memberships.find((x: IAPIZendeskMembership) => {
          return (
            x.user_id === zendeskUserId &&
            x.organization_id === zendeskOrganizationId
          );
        });

        const hasMembership = Boolean(getMembership);

        // If user has no membership to this organization, assign to current organization
        if (!hasMembership) {
          yield call(
            sendRequest,
            ZendeskActions.createOrUpdateZendeskUserMembership({
              zendeskUserId,
              zendeskOrganizationId,
            }),
          );
        }
      }
    }
  }
}
function* fetchZendeskOrganizationSaga(action: Action<{ teamId: string }>) {
  const isEnterprise = yield select((state: { team: ITeamState }) => {
    return getCurrentTeam(state.team)?.isEnterprise ?? false;
  });
  if (isEnterprise) {
    const teamId = yield select((state: { team: ITeamState }) => {
      return state.team.currentTeamId;
    });

    if (teamId) {
      const zendeskOrganization = yield select(
        (state: { zendesk: IZendeskState }) => {
          return state.zendesk.organizations[teamId];
        },
      );

      if (!Boolean(zendeskOrganization)) {
        const { response } = yield call(
          sendRequest,
          ZendeskActions.fetchZendeskOrganization({ teamId }),
        );

        const organizations = response?.data?.organizations ?? [];

        // If organization does not exist, create organization
        if (organizations.length < 1) {
          yield call(
            sendRequest,
            ZendeskActions.createZendeskOrganization({ teamId }),
          );
        }
      }
    }
  }
}

function* fetchZendeskUserSaga() {
  const isEnterprise = yield select((state: { team: ITeamState }) => {
    return getCurrentTeam(state.team)?.isEnterprise ?? false;
  });
  if (isEnterprise) {
    const isAuthenticated = yield select((state: { tokens: ITokensState }) => {
      return isUserAuthenticated(state.tokens);
    });

    if (isAuthenticated) {
      const userId = yield select((state: { user: IUserState }) => {
        return state.user.id;
      });
      const zendeskUser = yield select((state: { zendesk: IZendeskState }) => {
        return state.zendesk.user;
      });

      const loggedInAndZendeskNotLoaded = userId && !Boolean(zendeskUser);

      if (loggedInAndZendeskNotLoaded) {
        const { response } = yield call(
          sendRequest,
          ZendeskActions.fetchZendeskUser({ userId }),
        );

        const users = response?.data?.users ?? [];

        const user = users[0];

        const zendeskName = user?.name;
        const zendeskEmail = user?.email;
        const { email, name } = yield select((state: { user: IUserState }) => {
          return state.user;
        });

        if (zendeskEmail !== email || zendeskName !== name) {
          // If user does not exist, create user
          // if user email or name has changed, update data
          yield call(
            sendRequest,
            ZendeskActions.createOrUpdateZendeskUser({ userId, email, name }),
          );
        }
      }
    }
  }
}

/* TODO this saga should only run if user is inside an Enterprise Team / Account */
function* zendeskSaga() {
  yield takeEvery(
    [TeamActionTypes.FETCH_TEAMS_SUCCESS, TeamActionTypes.SET_CURRENT_TEAM],
    fetchZendeskOrganizationSaga,
  );

  yield takeLatest(
    [
      REHYDRATE,
      UserActionTypes.USER_REFRESH_SUCCESS,
      TokenActionTypes.REFRESH_TOKEN_SUCCESS,
      TeamActionTypes.FETCH_TEAMS_SUCCESS,
      TeamActionTypes.SET_CURRENT_TEAM,
    ],
    fetchZendeskUserSaga,
  );
  yield takeLatest(
    [
      REHYDRATE,
      ZendeskActionTypes.FETCH_ZENDESK_ORGANIZATION_SUCCESS,
      ZendeskActionTypes.FETCH_ZENDESK_USER_SUCCESS,
      ZendeskActionTypes.CREATE_ZENDESK_ORGANIZATION_SUCCESS,
      ZendeskActionTypes.CREATE_ZENDESK_USER_SUCCESS,
      TokenActionTypes.REFRESH_TOKEN_SUCCESS,
      TeamActionTypes.SET_CURRENT_TEAM,
    ],
    fetchZendeskMemebershipSaga,
  );
}

export { zendeskSaga };
