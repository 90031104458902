import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { t } from 'common/utils/intl';
import { useIntroductionStyles } from './IntroductionStyles';
import { useIsXSDown, useIsXXSDown } from 'common-web/utils/hooks/themeHooks';
import classNames from 'classnames';
import { IStepProps } from '../types';
import { defineFlowStep } from '../../../components/Flow/definition';
import { useFlowControl } from '../../../components/Flow/hooks';

interface IIntroductionProps {
  className?: string;

  onClose(): void;

  nextStep(x: any): void;
}

export const IntroductionComponent = ({
  className,
  onClose,
  nextStep,
}: IIntroductionProps) => {
  const classes = useIntroductionStyles();

  const isXSDown = useIsXSDown();
  const isXXSDown = useIsXXSDown();

  return (
    <div className={classNames(className, classes.component)}>
      <Typography
        className={classes.title}
        variant={isXXSDown ? 'h3' : 'h2'}
        component="p"
      >
        {t('two-factor-auth.captions.enable.first-screen')}
      </Typography>
      <Typography
        className={classes.note}
        variant={isXSDown ? 'h6' : 'h5'}
        component="span"
      >
        {t('two-factor-auth.captions.enable.google-note')}
      </Typography>
      <Button className={classes.next} onClick={nextStep}>
        {t('two-factor-auth.nav.enable')}
      </Button>
      <Button
        className={classes.skip}
        onClick={onClose}
        variant="text"
        color="secondary"
      >
        {t('two-factor-auth.nav.skip')}
      </Button>
    </div>
  );
};

const IntroductionImp = ({ className, onClose }: IStepProps) => {
  const { moveForward } = useFlowControl();

  return (
    <IntroductionComponent
      className={className}
      onClose={onClose}
      nextStep={moveForward}
    />
  );
};

export const Introduction = defineFlowStep<{}, {}, IStepProps>({
  Body: IntroductionImp,
});
