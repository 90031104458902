import React from 'react';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { Classes } from 'jss';
import classNames from 'classnames';
import { useTimeout } from 'common/utils/hooks/useTimeout';
import { useState } from 'react';
import Box from '@material-ui/core/Box';
import { Milliseconds } from 'common/types/unitsTypes';
import { Pixels } from 'common/types/unitsTypes';

const MIN_NOT_DISPLAY_TIME: Milliseconds = 300;
const DEFAULT_SIZE: Pixels = 40;

const styles = (): StyleRules => ({
  root: {
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&$large': {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '40px',
    },
    '&$fullPage': {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '40px',
      height: '100vh',
      width: '100vw',
    },
  },
  large: {},
  fullPage: {},
});

interface IProgressProps {
  className?: string;
  classes: Classes;
  size?: number;
  displaySince?: Milliseconds;
  fullPage?: boolean;
}

const LoadingIndicatorComponent = ({
  className,
  classes,
  size = DEFAULT_SIZE,
  displaySince = MIN_NOT_DISPLAY_TIME,
  fullPage = false,
}: IProgressProps) => {
  const [visible, setVisible] = useState(false);
  useTimeout(() => {
    setVisible(true);
  }, displaySince);
  if (!visible) {
    return null;
  }
  return (
    <Box
      className={classNames(
        classes.root,
        size >= DEFAULT_SIZE && classes.large,
        fullPage && classes.fullPage,
        className,
      )}
      fontSize={size}
    >
      <CircularProgress className={classNames(classes.progress)} size={size} />
    </Box>
  );
};

const LoadingIndicator = withStyles(styles)(LoadingIndicatorComponent);

export { LoadingIndicator };
