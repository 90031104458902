import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStep2Styles = makeStyles<Theme>(theme => ({
  component: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    boxSizing: 'border-box',
  },

  title: {
    textAlign: 'center',
  },

  content: {
    display: 'grid',
    gridTemplateColumns: '100px 410px',
    gridTemplateAreas: '"qr text" "qr code"',
    gridColumnGap: theme.spacing(3.75),
    gridRowGap: theme.spacing(2),

    width: '100%',
    margin: 'auto',
    marginTop: theme.spacing(5.625),
  },

  text: {
    gridArea: 'text',

    lineHeight: 1.3,
  },

  code: {
    gridArea: 'code',
    width: '100%',
  },

  qr: {
    gridArea: 'qr',

    '&&': {
      width: '100%',
      height: 'auto',
    },
  },
}));
