import { StyleRules, Theme } from '@material-ui/core/styles';
import { MAIN_LAYOUT_SIDEBAR_WIDTH } from 'common/core/const';
import { MOBILE_TOOLBAR_HEIGHT } from '../../themes/createCustomTheme';

export const apiLayoutStyles = (theme: Theme): StyleRules => ({
  root: {
    background: theme.palette.grey['100'],
    minHeight: '100vh',
    paddingTop: theme.extra.toolbarHeight,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.down('xs')]: {
      paddingTop: MOBILE_TOOLBAR_HEIGHT,
    },
  },

  whiteXSBg: {
    [theme.breakpoints.down('xs')]: {
      background: theme.palette.common.white,
    },
  },

  content: {
    justifyContent: 'center',
    display: 'flex',
    flex: 1,
    '$authenticated &': {
      marginLeft: MAIN_LAYOUT_SIDEBAR_WIDTH,
    },
    [theme.breakpoints.down('sm')]: {
      '$authenticated &': {
        marginLeft: 0,
      },
    },
  },

  authenticated: {},

  paper: {
    flex: 1,
    width: '100%',
    margin: '0 auto',
    borderRadius: 4,
    background: theme.palette.common.white,

    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(8),
    },
  },

  logo: {
    marginLeft: theme.spacing(4.5),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  navBar: {
    zIndex: theme.zIndex.appBar + 25,
  },

  // App Bar

  toolbar: {
    width: '100%',
    height: 74,
    padding: '0 24px',
    borderBottom: `1px solid ${theme.palette.grey['200']}`,
    boxSizing: 'border-box',

    [theme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },

    '$authenticated &': {
      width: `calc(100% - ${MAIN_LAYOUT_SIDEBAR_WIDTH}px)`,
      marginLeft: MAIN_LAYOUT_SIDEBAR_WIDTH,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginLeft: 0,
      },
    },
  },

  withNavBar: {
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    [theme.breakpoints.down('sm')]: {
      borderLeft: 'unset',
    },
  },
  detached: {
    boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.15)',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    padding: 0,
    flexDirection: 'row',
    maxWidth: '100%',
  },
  icon: {
    width: 58,
  },
});
