export function isProd() {
  return !process.env.DEBUG && process.env.NODE_ENV === 'production';
}

const ANKR_PROD_DOMAIN = 'https://api.ankr.com';

export function isProdAPI() {
  return REACT_APP_API_BASE === ANKR_PROD_DOMAIN;
}

const REACT_APP_API_BASE = process.env.REACT_APP_API_BASE;
export const REACT_APP_INTERCOM_APP_ID = process.env
  .REACT_APP_INTERCOM_APP_ID as string;

export const ANKR_EXPLORER_URL = isProdAPI()
  ? 'http://explorer.ankr.com/tx/'
  : 'http://explorer-stage.ankr.com/tx/';

export const ETHERSCAN_TX_URL = isProdAPI()
  ? 'https://etherscan.io/tx/'
  : 'https://ropsten.etherscan.io/tx/';
