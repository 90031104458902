export default [
  {
    id: '1',
    code: '0011',
    node_family: 'eth',
    name: 'eth-fast',
    network: 'main',
  },
  {
    id: '2',
    code: '0012',
    node_family: 'eth',
    name: 'eth-fast',
    network: 'ropsten',
  },
  {
    id: '3',
    code: '0013',
    node_family: 'eth',
    name: 'eth-fast',
    network: 'einkeby',
  },
  {
    id: '4',
    code: '0014',
    node_family: 'eth',
    name: 'eth-fast',
    network: 'gorli',
  },
  {
    id: '5',
    code: '0015',
    node_family: 'eth',
    name: 'eth-achive',
    network: 'main',
  },
  {
    id: '6',
    code: '0021',
    node_family: 'binance',
    name: 'binance-full',
    network: 'main',
  },
  {
    id: '7',
    code: '0022',
    node_family: 'binance',
    name: 'binance-fast',
    network: 'main',
  },
  {
    id: '8',
    code: '0023',
    node_family: 'binance',
    name: 'binance-full',
    network: 'test',
  },
  {
    id: '9',
    code: '0024',
    node_family: 'binance',
    name: 'binance-fast',
    network: 'test',
  },
];
