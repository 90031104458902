import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { Theme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { PublicOnlyRoute } from 'common-web/nav/PublicOnlyRoute';
import { t } from 'common/utils/intl';
import { API_PATH } from '../core/const';

export interface IAuthRoutesProps {
  components: {
    expiredToken:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>;
    signIn:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>;
    modeSwitcher:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>;
    signUp:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>;
    passwordRecovery:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>;
    passwordRecoverySent:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>;
    registerConfirmation:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>;
    resetPassword:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>;
    registerSuccess:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>;
    passwordResetSuccess:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>;
    signUpSuccess:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>;
    notFound:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>;
  };
  paths: {
    expiredTokenPath: string | string[];
    signInPath: string | string[];
    modeSwitcherPath: string | string[];
    signUpPath: string | string[];
    passwordRecoveryPath: string | string[];
    passwordRecoverySentPath: string | string[];
    registerConfirmationPath: string | string[];
    resetPasswordPath: string | string[];
    registerSuccessPath: string | string[];
    passwordResetSuccessPath: string | string[];
    signUpSuccessPath: string | string[];
  };
}

export const createAuthRoutes = (
  theme: Theme,
  {
    components: {
      expiredToken,
      signIn,
      modeSwitcher,
      signUp,
      signUpSuccess,
      passwordRecovery,
      passwordRecoverySent,
      registerConfirmation,
      resetPassword,
      registerSuccess,
      passwordResetSuccess,
      notFound,
    },
    paths: {
      expiredTokenPath,
      signInPath,
      modeSwitcherPath,
      signUpPath,
      passwordRecoveryPath,
      passwordRecoverySentPath,
      registerConfirmationPath,
      resetPasswordPath,
      registerSuccessPath,
      passwordResetSuccessPath,
      signUpSuccessPath,
    },
  }: IAuthRoutesProps,
) => {
  return ({ isSignedIn }: { isSignedIn: boolean }) => {
    return (
      <ThemeProvider theme={theme}>
        <Switch>
          <Route
            exact={true}
            path={signUpPath}
            component={signUp}
            isSignedIn={isSignedIn}
          />

          <Route
            exact={true}
            path={expiredTokenPath}
            component={expiredToken}
            isSignedIn={isSignedIn}
          />

          <PublicOnlyRoute
            exact={true}
            path={signInPath}
            component={signIn}
            isSignedIn={isSignedIn}
            pathname={API_PATH}
          />

          <PublicOnlyRoute
            exact={true}
            path={modeSwitcherPath}
            component={modeSwitcher}
            isSignedIn={isSignedIn}
          />

          <PublicOnlyRoute
            exact={true}
            path={passwordRecoveryPath}
            component={passwordRecovery}
            isSignedIn={isSignedIn}
          />
          <PublicOnlyRoute
            exact={true}
            path={passwordRecoverySentPath}
            component={passwordRecoverySent}
            isSignedIn={isSignedIn}
          />
          <PublicOnlyRoute
            exact={true}
            path={registerConfirmationPath}
            component={registerConfirmation}
            isSignedIn={isSignedIn}
          />
          <PublicOnlyRoute
            exact={true}
            path={resetPasswordPath}
            component={resetPassword}
            isSignedIn={isSignedIn}
          />
          <PublicOnlyRoute
            exact={true}
            path={registerSuccessPath}
            component={registerSuccess}
            isSignedIn={isSignedIn}
          />
          <PublicOnlyRoute
            exact={true}
            path={passwordResetSuccessPath}
            component={passwordResetSuccess}
            isSignedIn={isSignedIn}
          />
          <PublicOnlyRoute
            exact={true}
            path={signUpSuccessPath}
            component={signUpSuccess}
            isSignedIn={isSignedIn}
          />
          <Route
            isSignedIn={isSignedIn}
            component={notFound}
            breadcrumbs={[t('navigation.404')]}
          />
        </Switch>
      </ThemeProvider>
    );
  };
};
