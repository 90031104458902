/* eslint-disable @typescript-eslint/camelcase */
import { IExtendedRequestAction } from 'common/types/requestTypes';
import { createAction } from 'common/utils/reduxUtils';

export const PhoneUserActionTypes = {
  PHONE_VERIFY: 'PHONE_VERIFY',
  PHONE_VERIFY_SUCCESS: 'PHONE_VERIFY_SUCCESS',
  PHONE_VERIFY_ERROR: 'PHONE_VERIFY_ERROR',
  PHONE_VERIFY_RESET: 'PHONE_VERIFY_RESET',

  PHONE_VERIFY_CHECK: 'PHONE_VERIFY_CHECK',
  PHONE_VERIFY_CHECK_SUCCESS: 'PHONE_VERIFY_CHECK_SUCCESS',
  PHONE_VERIFY_CHECK_ERROR: 'PHONE_VERIFY_CHECK_ERROR',
  PHONE_VERIFY_CHECK_PURGE: 'PHONE_VERIFY_CHECK_PURGE',

  PHONE_SIGNUP: 'PHONE_SIGNUP',
  PHONE_SIGNUP_SUCCESS: 'PHONE_SIGNUP_SUCCESS',
  PHONE_SIGNUP_ERROR: 'PHONE_SIGNUP_ERROR',

  PHONE_CHANGE: 'PHONE_CHANGE',
  PHONE_CHANGE_SUCCESS: 'PHONE_CHANGE_SUCCESS',
  PHONE_CHANGE_ERROR: 'PHONE_CHANGE_ERROR',
  PHONE_CHANGE_RESET: 'PHONE_CHANGE_RESET',

  PHONE_RESET_PASSWORD: 'PHONE_RESET_PASSWORD',
  PHONE_RESET_PASSWORD_SUCCESS: 'PHONE_RESET_PASSWORD_SUCCESS',
  PHONE_RESET_PASSWORD_ERROR: 'PHONE_RESET_PASSWORD_ERROR',
  PHONE_RESET_PASSWORD_PURGE: 'PHONE_RESET_PASSWORD_PURGE',
};

export interface IPhoneSignUpParams {
  password: string;
  name: string;
  phone: string;
  code: string;
}

export interface IPhoneSignInParams {
  phone: string;
  password: string;
  mfa_type?: 'TOTP' | 'SMS'; // if totp enabled, must provide, TOTP or SMS
  code?: string; // if totp enabled, must provide, SMS verification code or TOTP code (from Google Authenticator)
}

export const PhoneUserActions = {
  /**
   * Verify Users phone
   * @param phone
   */
  phoneVerify: (phone: string) => ({
    type: PhoneUserActionTypes.PHONE_VERIFY,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/uaa/v1alpha/smsVerification:send`,
      method: 'POST',
      data: { phone },
    },
    meta: {
      noAuth: true,
    },
  }),
  resetPhoneVerifyStatus: () =>
    createAction(PhoneUserActionTypes.PHONE_VERIFY_RESET),
  phoneVerifyCheck: (phone: string, code: string) => ({
    type: PhoneUserActionTypes.PHONE_VERIFY_CHECK,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/uaa/v1alpha/smsVerification:check`,
      method: 'POST',
      data: { phone, code },
    },
    meta: {
      noAuth: true,
    },
  }),
  phoneVerifyCheckPurge: () =>
    createAction(PhoneUserActionTypes.PHONE_VERIFY_CHECK_PURGE),
  phoneSignUp: (
    { password, name, phone, code }: IPhoneSignUpParams,
    redirectOnSuccess?: string,
  ): IExtendedRequestAction => ({
    type: PhoneUserActionTypes.PHONE_SIGNUP,
    request: {
      url: '/phone_signup',
      method: 'POST',
      data: { password, name, phone, code },
    },
    meta: {
      noAuth: true,
      redirectOnSuccess,
    },
  }),
  /**
   * Phone version of login
   * @param data Query data
   * @param persist Should stay logged in in after app reload.
   * @param redirectOnSuccess : page to redirect after a successful login
   */
  // phoneSignIn: (
  //   data: IPhoneSignInParams,
  //   persist = false,
  //   redirectOnSuccess?: string,
  // ) => ({
  //   type: UserActionTypes.SIGNIN,
  //   request: {
  //     url: '/phone_login',
  //     method: 'POST',
  //     data,
  //   },
  //   meta: {
  //     persist,
  //     noAuth: true,
  //     redirectOnSuccess,
  //   },
  // }),
  /**
   * Change phone number
   * @param new_phone
   * @param code
   * @param redirectOnSuccess
   */
  // TODO: To remove - there is
  phoneChange: (
    // eslint-disable-next-line @typescript-eslint/camelcase
    new_phone: string,
    code: string,
    redirectOnSuccess?: string,
  ) => ({
    type: PhoneUserActionTypes.PHONE_CHANGE,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/uaa/v1alpha/phone`,
      method: 'POST',
      // eslint-disable-next-line @typescript-eslint/camelcase
      data: { phone: new_phone, code },
    },
    meta: {
      redirectOnSuccess,
    },
  }),

  phoneChangeStatusReset: () => ({
    type: PhoneUserActionTypes.PHONE_CHANGE_RESET,
  }),

  phoneResetPassword: (
    phone: string,
    code: string,
    password: string,
    redirectOnSuccess?: string,
  ) => ({
    type: PhoneUserActionTypes.PHONE_RESET_PASSWORD,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/uaa/v1alpha/password:reset`,
      method: 'POST',
      data: {
        phone,
        code,
        new_password: password,
      },
    },
    meta: {
      redirectOnSuccess,
    },
  }),
  phoneResetPasswordPurge: () =>
    createAction(PhoneUserActionTypes.PHONE_RESET_PASSWORD_PURGE),
};
