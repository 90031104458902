import React, { useCallback, useState } from 'react';
import { useEnterCodeStyles } from './EnterCodeStyles';
import classNames from 'classnames';

interface ISingleFieldProps {
  value?: string;
  name: string;
  large?: boolean;
  onValueChange: (value: string) => void;
}

export const SingleField = React.forwardRef<
  HTMLInputElement,
  ISingleFieldProps
>(({ value, onValueChange, name, large }, ref) => {
  const [rand] = useState(`off-${Math.random() * 10000}`);

  const onChange = useCallback(
    e => {
      onValueChange && onValueChange(e.target.value);
    },
    [onValueChange],
  );

  const classes = useEnterCodeStyles();

  return (
    <div className={classes.field}>
      <input
        ref={ref}
        className={classNames(classes.input, large && classes.inputLarge)}
        onChange={onChange}
        value={value}
        type="number"
        inputMode="number"
        autoComplete={rand}
        max={9}
        min={0}
        pattern="\d*"
        name={name}
      />
    </div>
  );
});
