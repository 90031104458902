import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useRecoveryCodesStyles = makeStyles<Theme>(theme => ({
  component: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    boxSizing: 'border-box',
    // padding: theme.spacing(4, 2),

    [theme.breakpoints.up('sm')]: {
      minHeight: 300,
      maxWidth: 576,
      alignSelf: 'center',
      margin: '0 auto',
    },
  },

  title: {
    textAlign: 'center',
    '@media print': {
      display: 'none',
    },
  },

  subtitle: {
    fontSize: 16,
    lineHeight: '130%',
    marginBottom: theme.spacing(3.5),
  },

  codesBorder: {
    border: `1px solid ${theme.palette.grey['200']}`,
    borderRadius: 4,
    padding: theme.spacing(4, 2),
    marginTop: theme.spacing(5),
    width: '100%',
    '@media print': {
      border: 'unset',
      marginBottom: theme.spacing(5),
    },
  },

  codes: {
    '& div': {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '120%',
      textAlign: 'center',
      color: theme.palette.primary,
    },
  },

  buttons: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(3, -1.5, 3.5, -1.5),
    width: 'calc(100% + 24px)',
    '@media print': {
      display: 'none',
    },
  },

  button: {
    flexBasis: '100%',
    margin: theme.spacing(0, 1.5),
  },

  printButton: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  message: {
    alignSelf: 'flex-start',
  },
}));
