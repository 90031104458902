import { SnackbarOrigin } from '@material-ui/core/Snackbar';
import { Milliseconds, Seconds } from '../types';
import { TeamRole } from '../modules/teams/teamTypes';
import BigNumber from 'bignumber.js';

export const CHAIN_API_BASE: string = (function(): string {
  if (!process.env.REACT_APP_CHAIN_API_BASE) {
    return '';
  }
  const CHAIN_API_LIST = process.env.REACT_APP_CHAIN_API_BASE.split(',');
  return CHAIN_API_LIST[Math.ceil(Math.random() * CHAIN_API_LIST.length) - 1];
})();

export const GTM_ID = process.env.REACT_APP_GTM_ID || '';
export const MIXPANEL_TOKEN =
  process.env.REACT_APP_MIXPANEL_TOKEN || 'NO_TOKEN';
export const GA_ID = process.env.REACT_APP_GA_ID || 'NO_TOKEN';

export const MONTH_IN_MS = 86400000 * 30; // 24 * 60 * 60 * 1000
export const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
export const HOUR_IN_MS = 3600000; // 60 * 60 * 1000
export const MINUTES_IN_MS = 60000; // 60 * 1000
export const MONEY_PRECISION = 2;
export const USD_PRICE_PRECISION = 2;

export const TOOLTIP_DELAY = 300;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY!;

export const TOKENS_PERSIST_KEY = 'tokens';
export const ROOT_PERSIST_KEY = 'root';

export const MAIN_LAYOUT_TOOLBAR_HEIGHT = 60;
export const MAIN_LAYOUT_SIDEBAR_WIDTH = 80;
export const SETTINGS_MENU_HEIGHT = 40;

export const MAX_USERNAME_LENGTH = 64;

export const TOKENS_DIVIDER_MAP = {
  ERC20: 1e18,
  BEP2: 1e8,
  MAINNET: 1e18,
};

export const CPU_DIVIDER = 1000;
export const MEM_DIVIDER = 1000;
export const STORAGE_DIVIDER = 1000;

export const DEFAULT_CACHE_SIZE = 10;

export const DEFAULT_CACHE_TIME: Seconds = 60 * 5;
export const DASHBOARD_CACHE_TIME: Seconds = 60 * 5;
export const NAMESPACES_CACHE_TIME: Seconds = 30;
export const DATACENTERS_CACHE_TIME: Seconds = 30;
export const APP_UUID_NAMESPACE = 'ad46a09b-32ab-46b6-9a2c-9b0c8d8371ca';

export const ROWS_PER_PAGE = 10;
export const APP_ROWS_PER_PAGE = 18;

export const GB = 1024; // Megabytes in Gigabyte

export const SNACKBAR_ORIGIN_TOP: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

export const ENTRY_LAYOUT_TOOLBAR_HEIGHT = 104;

export const SNACKBAR_AUTO_HIDE_DURATION: Milliseconds = 3000;

export const ENABLE_DESCRIPTION = false;

export const AVAILABLE_STATUS = 'AVAILABLE';

export const DEBOUNCE = 300;

export const renderServerParam = (name: string, value: number) => {
  let result: number;
  switch (name) {
    case 'memory':
      result = value / MEM_DIVIDER;
      break;
    case 'storage':
      result = value / STORAGE_DIVIDER;
      break;
    case 'cpu':
      result = value / CPU_DIVIDER;
      break;
    default:
      result = value;
      break;
  }
  return Math.round(result);
};

export const TEAM_ROLE: { [key: string]: TeamRole } = {
  owner: 'system:owner',
  admin: 'system:admin',
  member: 'system:member',
  clusterManager: 'system:cluster-manager',
};

export const TEAM_ROLE_MAP_LIST = [
  { value: TEAM_ROLE['member'], label: 'Member' },
  { value: TEAM_ROLE['clusterManager'], label: 'ClusterManager' },
  { value: TEAM_ROLE['admin'], label: 'Admin' },
];

export const CARD_WIDTH = 248;
export const CARD_GAP = 15;
export const CARD_HEIGHT = 264;
export const CARD_HEIGHT_HOVERED = 284;

// for the Explorer
export const SOCIAL_LINKS = {
  medium: 'https://medium.com/ankr-network',
  telegram: 'https://t.me/ankrnetwork',
  twitter: 'https://twitter.com/ankr',
  github: 'https://github.com/Ankr-network/',
  kakao: 'https://open.kakao.com/o/g7Knr09',
  ankrMain: 'https://app.ankr.com',
  youTube: 'https://www.youtube.com/channel/UCr6z1C2Ti0DOS_zNqh62U-A',
  instagram: 'https://www.instagram.com/ankrnetwork/',
  linkedin: 'https://www.linkedin.com/company/ankr/',
};

export const APPLICATION_NAV_REGEXP = /^#{1} (.+)$/gm;

export const EXTERNAL_TOKEN = 'external_token';

// from ProviderUI
export const PROVIDER_SELECT_TEAM_PATH = '/provider/select-team';

export const ZERO = new BigNumber(0);

export const BREAKPOINT_XXS = 359;

export const EMPTY_FUNCTION = () => null;

export const EMPTY_VALUE = ' ';
