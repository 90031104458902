// Determine the auth error
import {
  IApiErrorResponse,
  IExtendedRequestAction,
  IRequestConfig,
  Milliseconds,
} from '../../types';
import { Driver } from 'redux-saga-requests';
import axios, { AxiosInstance } from 'axios';

export const SUCCESS_REDIRECT_DELAY: Milliseconds = 100;

/**
 * Check error is auth error
 * @param error
 */
export const isAuthError = (error: IApiErrorResponse) => {
  return (
    error?.response?.status === 401 ||
    // Workaround for the wrong status code.
    (error?.response?.status === 400 &&
      error?.response?.data?.Code === 'AuthError')
  );
};

/**
 * Returns true if we don't need to refresh token.
 * @param action
 */
export function shouldSkipAuth(action: IExtendedRequestAction) {
  return Boolean(action?.meta?.noAuth || action?.meta?.noRefresh);
}

/**
 *
 */

export interface ITransportConfig {
  baseURL: string;
  drivers?: {
    [key: string]: Driver;
  };
  responseInterceptors?: {
    onFulfilled?: (value: any) => any | Promise<any>;
    onRejected?: (error: any) => any;
  }[];
  requestInterceptors?: {
    onFulfilled?: (value: any) => any | Promise<any>;
    onRejected?: (error: any) => any;
  }[];
}

/**
 * Just creating axios instance here
 */
export const createApiTransportInstance = (
  config: ITransportConfig,
): AxiosInstance => {
  const instance = axios.create({
    baseURL: config.baseURL,
  });

  if (config.responseInterceptors instanceof Array) {
    config.responseInterceptors.forEach(interceptor => {
      instance.interceptors.response.use(
        interceptor.onFulfilled,
        interceptor.onRejected,
      );
    });
  }

  if (config.requestInterceptors instanceof Array) {
    config.requestInterceptors.forEach(interceptor => {
      instance.interceptors.request.use(
        interceptor.onFulfilled,
        interceptor.onRejected,
      );
    });
  }

  return instance;
};

/**
 * This is request actions interceptor
 * It adds access token
 */
export const makeRequestWithAuthHeader = (
  request: any,
  accessToken: string,
) => ({
  ...request,
  headers: {
    ...(request?.headers ?? {}),
    Authorization: `Bearer ${accessToken}`,
  },
});

/**
 * Add request headers to the request(s)
 * @param request
 * @param accessToken
 */
export function makeRequestsWithAuthHeader(
  request: IRequestConfig<any> | IRequestConfig<any>[],
  accessToken: string,
) {
  return request instanceof Array
    ? request.map(item => makeRequestWithAuthHeader(item, accessToken))
    : makeRequestWithAuthHeader(request, accessToken);
}
