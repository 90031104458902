import React, { useCallback, useEffect, useRef } from 'react';

import { t } from 'common/utils/intl';

import {
  getRequestError,
  isRequestFailed,
  RequestStatus,
} from 'common/utils/requestStatus';
import { connect } from 'react-redux';
import { TwoFactorActions } from 'auth/store/actions/TwoFactorActions';
import { IStepProps } from '../types';
import { defineFlowStep } from '../../../components/Flow/definition';
import { useFlowControl } from '../../../components/Flow/hooks';
import { ICheckFormProps } from '../../../components/CheckForm/CheckForm';
import { useInitEffect } from 'common/utils/hooks';
import copy from 'copy-to-clipboard';
import { exportStringAsTxt } from 'common/utils/files';

import { RecoveryCodesComponent } from '../../../components/RecoveryCodes/RecoveryCodes';
import { codesToString } from '../../../core/utils';
import { useSnackbar } from 'common/utils/hooks/useSnackbar';
import { useStep4Styles } from './Step4Styles';
import { ITwoFactorState } from 'auth/store/reducers/TwoFactorReducer';

interface IStep4Props extends ICheckFormProps {
  className?: string;
  codes: string[];
  onDownload: () => void;
  onPrint: () => void;
  onCopy: () => void;
}

export const Step4Component = ({
  codes,
  onCopy,
  onDownload,
  onPrint,
}: IStep4Props) => {
  const classes = useStep4Styles();
  return (
    <div className={classes.root}>
      <RecoveryCodesComponent
        codes={codes}
        onDownload={onDownload}
        onPrint={onPrint}
        onCopy={onCopy}
      />
    </div>
  );
};

interface IConfirmationData {
  twoFactorConfirmed: boolean;
}

const Step4Imp = ({
  innerClassName,
  generateRecoveryCodes,
  generateStatus,
  codes,
}: IStepProps & {
  generateStatus: RequestStatus;
  codes: string[];
  generateRecoveryCodes: typeof TwoFactorActions.generateRecoveryCodes;
}) => {
  const updateData = useRef<() => void>(null as any);
  const { addData, data } = useFlowControl<IConfirmationData>();
  const { showSnackbar } = useSnackbar();

  useInitEffect(() => {
    generateRecoveryCodes(data.password, data.code);
  });

  const handleSubmit = useCallback(() => {
    //
  }, []);

  const requestFailed = isRequestFailed(generateStatus);

  useEffect(() => {
    if (requestFailed) {
      showSnackbar(getRequestError(generateStatus), {
        key: 'two-factor-auth.error',
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    }
  }, [addData, requestFailed, generateStatus, showSnackbar]);

  const handleDownload = () => {
    exportStringAsTxt(codesToString(codes), 'ankr-2fa-codes.txt');
  };

  const handleCopy = () => {
    showSnackbar(t('two-factor-auth.copied'), {
      key: 'two-factor-auth.copied',
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
    copy(codesToString(codes));
  };

  const handlePrint = () => {
    window.print();
  };

  const error = requestFailed
    ? t(`two-factor-auth.errors.${getRequestError(generateStatus)}`)
    : undefined;

  return (
    <Step4Component
      className={innerClassName}
      onSubmit={handleSubmit}
      codes={codes}
      updateDataRef={updateData}
      error={error}
      onCopy={handleCopy}
      onDownload={handleDownload}
      onPrint={handlePrint}
    />
  );
};

const Step4Wrapper = connect(
  (state: { twoFactor: ITwoFactorState }) => ({
    generateStatus: state.twoFactor.generateRecoveryCodesStatus,
    codes: state.twoFactor.recoveryCodes,
  }),
  dispatch => ({
    generateRecoveryCodes: (password: string, code: string) =>
      dispatch(TwoFactorActions.generateRecoveryCodes(password, code)),
  }),
)(Step4Imp);

export const Step4 = defineFlowStep<{}, IConfirmationData, IStepProps>({
  Body: Step4Wrapper,
  validate: ({ twoFactorConfirmed }) => twoFactorConfirmed,
});
