import { IExtendedRequestAction } from '../../types/requestTypes';

const AnkrActionTypes = {
  SET_ANKR_PRICE: 'SET_ANKR_PRICE',

  SET_PRICE_CHART_RANGE: 'SET_PRICE_CHART_RANGE',
  PRICE_CHART_DATA_FETCH: 'PRICE_CHART_DATA_FETCH',
  PRICE_CHART_DATA_FETCH_SUCCESS: 'PRICE_CHART_DATA_FETCH_SUCCESS',
  PRICE_CHART_DATA_FETCH_ERROR: 'PRICE_CHART_DATA_FETCH_ERROR',

  ANKR_FETCH_PRICE: 'ANKR_FETCH_PRICE',
  ANKR_FETCH_PRICE_SUCCESS: 'ANKR_FETCH_PRICE_SUCCESS',
  ANKR_FETCH_PRICE_ERROR: 'ANKR_FETCH_PRICE_ERROR',

  ANKR_FETCH_SYMBOL_PRICE: 'ANKR_FETCH_SYMBOL_PRICE',
  ANKR_FETCH_SYMBOL_PRICE_SUCCESS: 'ANKR_FETCH_SYMBOL_PRICE_SUCCESS',
  ANKR_FETCH_SYMBOL_PRICE_ERROR: 'ANKR_FETCH_SYMBOL_PRICE_ERROR',
};

export type PriceHistoryRange = '1h' | '1d' | '7d' | '1m' | '3m';

export enum IFetchSymbolPrice {
  ANKR = 'ANKR',
  USDT = 'USDT',
}

const AnkrActions = {
  fetchAnkrPrice: (): IExtendedRequestAction => ({
    type: AnkrActionTypes.ANKR_FETCH_PRICE,
    request: {
      url: `/price`,
      method: 'GET',
    },
  }),
  fetchSymbolPrice: (symbol: IFetchSymbolPrice): IExtendedRequestAction => ({
    type: AnkrActionTypes.ANKR_FETCH_SYMBOL_PRICE,
    request: {
      url: `${process.env.REACT_APP_API_BASE}/price/v1alpha/quotes/${symbol}`,
      method: 'GET',
    },
    meta: {
      symbol,
    },
  }),

  setPriceChartRange: (range: PriceHistoryRange = '1h') => ({
    type: AnkrActionTypes.SET_PRICE_CHART_RANGE,
    range,
  }),

  priceChartDataFetch: (
    range: PriceHistoryRange = '1h',
  ): IExtendedRequestAction => ({
    type: AnkrActionTypes.PRICE_CHART_DATA_FETCH,
    request: {
      url: `/price/history/${range}`,
      method: 'GET',
    },
  }),
};

export { AnkrActionTypes, AnkrActions };
