import React from 'react';
import { connect } from 'react-redux';
import { IUserState } from 'auth/store/reducers/userReducer';

interface IUsernameProps {
  username: string;
}

const UsernameComponent = ({ username }: IUsernameProps) => <>{username}</>;

const mapStateToProps = (state: { user: IUserState }) => {
  return {
    username: state.user.name,
  };
};

const Username = connect(mapStateToProps)(UsernameComponent);

export { Username };
