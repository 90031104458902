import { APIMarketActionTypes } from '../../APIMarketActions';
import MarketList from './data/market_list';
// import MarketInstanceList from './data/market_instance_list';
// import CurrentPlan from './data/current_plan';

export const APIServiceMock = {
  [APIMarketActionTypes.FETCH_API_MARKET_MARKET_LIST]: () => {
    return {
      data: {
        data: MarketList,
      },
    };
  },
  [APIMarketActionTypes.FETCH_API_MARKET_API_LIST]: (args: {
    data: { key: string; period: number };
  }) => {
    return {
      data: {
        data: [],
      },
    };
  },
  [APIMarketActionTypes.FETCH_API_MARKET_DOCUMENTATION]: () => {
    const documentation = {
      overview: `Binance Chain is a blockchain optimized for fast and scalable trading
        and asset exchange. It allows non-custodial trading without middlemen
        via the decentralized exchange Binance DEX build on top of Binance
        chain.`,
      availableNodes: ['Full Archive Node'],
      explorerURL: 'www.binance.org',
      documentationHTML: `<a href="https://docs.binance.org/api-reference/node-rpc.html">https://docs.binance.org/api-reference/node-rpc.html</a>
        <br /><br /><br />In the following subchapters, we will describe how to obtain the
        endpoint from the Ankr platform and we will show you how to make RPC
        calls to it;`,
    };

    return {
      data: {
        documentation,
      },
    };
  },

  [APIMarketActionTypes.FETCH_API_MARKET_API_STATS]: (args: {
    data: { key: string; period: number };
  }) => {
    const minValue = 0;
    const maxValue = 50;

    const {
      data: { key = '5673e6fb-cb8d-4bcb-bf59-cf212d5807bb' },
    } = args;

    if (args.data.period === 1) {
      args.data.period = 100;
    }

    const now = new Date();
    const getDateValue = (distance: number) =>
      new Date().setDate(now.getDate() - distance);
    const getRandomValue = (min: number, max: number) =>
      Math.floor(Math.random() * (max - min) + min);

    const data = Array.from(Array(args.data.period).keys())
      .reverse()
      .map(distance => ({
        key,
        date: getDateValue(distance),
        value: getRandomValue(minValue, maxValue),
      }));

    return {
      data: {
        data: data,
      },
    };
  },

  [APIMarketActionTypes.DELETE_API_MARKET_API]: () => {
    return {
      data: [],
    };
  },
};
